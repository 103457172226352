import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import {
    PBox,
    PH2,
    PH3,
    PText,
    PUl,
    PLi,
    PH4,
    PSpan,
} from "../../theme/BaseTheme";
import { Button, Card, Empty, List, Space, Tag, Popconfirm } from "antd";
import {
    fetchSubscription,
    cancelSubscription,
    editBilling,
    updateSubscription,
} from "../../../actions/subscription";
import ChangePlanModal from "../../components/app/ChangePlanModal";
import {
    FreePlanFeatures,
    StarterPlanFeatures,
    StandardPlanFeatures,
    ProPlanFeatures,
} from "../../components/app/PlanFeatures";
import PaymentConfirmModal from "../../components/app/PaymentConfirmModal";

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isChangePlanModalOpen: false,
            isPaymentConfirmModalOpen: false,
            newPlan: "",
        };
    }
    async componentDidMount() {
        await this.props.fetchSubscription();
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>User Subscription</title>
                </Helmet>
                <PBox css={styles}>
                    <PH2>Manage Subscription</PH2>
                    <PBox className="manage-subscription-title">
                        <PH4>
                            Your plan:{" "}
                            {this.props.userAccount?.plan.includes("starter")
                                ? "starter"
                                : this.props.userAccount?.plan.includes(
                                      "standard"
                                  )
                                ? "standard"
                                : this.props.userAccount?.plan.includes("pro")
                                ? "pro"
                                : "free"}{" "}
                            plan
                        </PH4>
                        {this.props.userAccount?.plan !== "free" && (
                            <PBox>
                                {!this.props.subscription
                                    ?.cancel_at_period_end && (
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                isChangePlanModalOpen: true,
                                            });
                                        }}
                                    >
                                        Change Plan
                                    </Button>
                                )}
                                {!this.props.subscription
                                    ?.cancel_at_period_end && (
                                    <Popconfirm
                                        title="Cancel Subscription"
                                        description="Are you sure to cancel your subscription?"
                                        onConfirm={() => {
                                            this.props.cancelSubscription();
                                        }}
                                        onCancel={() => {}}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button>Cancel Plan</Button>
                                    </Popconfirm>
                                )}
                            </PBox>
                        )}
                        {this.props.userAccount?.plan === "free" && (
                            <PBox>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        this.setState({
                                            isChangePlanModalOpen: true,
                                        });
                                    }}
                                >
                                    Subscribe
                                </Button>
                            </PBox>
                        )}
                    </PBox>
                    <PBox className="manage-subscription">
                        <Card hoverable={true}>
                            <PH3>Plan Features</PH3>
                            {this.props.userAccount?.plan === "free" && (
                                <FreePlanFeatures highlight={false} />
                            )}
                            {this.props.userAccount?.plan.includes(
                                "starter"
                            ) && <StarterPlanFeatures highlight={false} />}
                            {this.props.userAccount?.plan.includes(
                                "standard"
                            ) && <StandardPlanFeatures highlight={false} />}
                            {this.props.userAccount?.plan.includes("pro") && (
                                <ProPlanFeatures highlight={false} />
                            )}
                        </Card>
                        <Card hoverable={true}>
                            <PH3>Billing & Payment</PH3>
                            <PBox className="manage-subscription-card-content">
                                <PText>Price</PText>
                                {this.props.userAccount?.plan === "free" && (
                                    <PText>$0</PText>
                                )}
                                {this.props.userAccount?.plan ===
                                    "starter_monthly" && (
                                    <PText>$10 / month</PText>
                                )}
                                {this.props.userAccount?.plan ===
                                    "standard_monthly" && (
                                    <PText>$20 / month</PText>
                                )}
                                {this.props.userAccount?.plan ===
                                    "pro_monthly" && <PText>$40 / month</PText>}
                                {this.props.userAccount?.plan ===
                                    "starter_annually" && (
                                    <PText>$110 / year</PText>
                                )}
                                {this.props.userAccount?.plan ===
                                    "standard_annually" && (
                                    <PText>$220 / year</PText>
                                )}
                                {this.props.userAccount?.plan ===
                                    "pro_annually" && (
                                    <PText>$440 / year</PText>
                                )}
                            </PBox>
                            <PBox className="manage-subscription-card-content">
                                <PText>Credits</PText>
                                <PText>{this.props.userAccount?.credits}</PText>
                            </PBox>
                            {this.props.userAccount?.plan !== "free" && (
                                <PBox className="manage-subscription-card-content">
                                    <PText>Billing Period</PText>
                                    {this.props.userAccount?.plan.includes(
                                        "monthly"
                                    ) && <PText>Monthly</PText>}
                                    {this.props.userAccount?.plan.includes(
                                        "annually"
                                    ) && <PText>Annually</PText>}
                                </PBox>
                            )}
                            {this.props.userAccount?.plan !== "free" && (
                                <PBox className="manage-subscription-card-content">
                                    {!this.props.subscription
                                        ?.cancel_at_period_end && (
                                        <PText>Renewal Date</PText>
                                    )}
                                    {this.props.subscription
                                        ?.cancel_at_period_end && (
                                        <PText>Subscription Ends</PText>
                                    )}
                                    <PText>
                                        {
                                            this.props.subscription
                                                ?.current_period_end
                                        }
                                    </PText>
                                </PBox>
                            )}
                            {this.props.userAccount?.plan !== "free" && (
                                <PBox className="manage-subscription-buttons">
                                    {!this.props.subscription
                                        ?.cancel_at_period_end && (
                                        <Button
                                            type="primary"
                                            onClick={async () => {
                                                await this.props.editBilling();
                                            }}
                                        >
                                            Edit Billing
                                        </Button>
                                    )}
                                </PBox>
                            )}
                        </Card>
                    </PBox>
                    <PText>
                        <br />
                    </PText>
                    <Card hoverable={true}>
                        <PH3 style={{ margin: "0 0 1rem 0" }}>Invoices</PH3>
                        {this.props.subscription?.invoices?.length !== 0 && (
                            <List>
                                {this.props.subscription?.invoices
                                    ?.toReversed()
                                    .map((invoice) => {
                                        return (
                                            <List.Item
                                                actions={[
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            invoice.invoice_url
                                                        }
                                                    >
                                                        View
                                                    </a>,
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            invoice.invoice_pdf
                                                        }
                                                    >
                                                        Download
                                                    </a>,
                                                ]}
                                            >
                                                <Space size="large">
                                                    {
                                                        invoice.invoice_create_date
                                                    }{" "}
                                                    <PSpan>
                                                        $
                                                        {invoice.invoice_amount}
                                                    </PSpan>{" "}
                                                    <Tag
                                                        color={
                                                            invoice.invoice_paid
                                                                ? "success"
                                                                : "error"
                                                        }
                                                    >
                                                        {invoice.invoice_paid
                                                            ? "Paid"
                                                            : "Unpaid"}
                                                    </Tag>
                                                </Space>
                                            </List.Item>
                                        );
                                    })}
                            </List>
                        )}
                        {this.props.subscription?.invoices?.length === 0 && (
                            <Empty />
                        )}
                    </Card>

                    {this.state.isChangePlanModalOpen && (
                        <ChangePlanModal
                            isChangePlanModalOpen={
                                this.state.isChangePlanModalOpen
                            }
                            handleChangePlanModalOk={async () => {
                                await this.setState({
                                    isPaymentConfirmModalOpen: true,
                                });
                                await this.setState({
                                    isChangePlanModalOpen: false,
                                });
                            }}
                            handleChangePlanModalCancel={() => {
                                this.setState({ isChangePlanModalOpen: false });
                            }}
                            setNewPlan={(value) => {
                                this.setState({ newPlan: value });
                            }}
                        />
                    )}

                    {this.state.isPaymentConfirmModalOpen && (
                        <PaymentConfirmModal
                            newPlan={this.state.newPlan}
                            isPaymentConfirmModalOpen={
                                this.state.isPaymentConfirmModalOpen
                            }
                            handlePaymentConfirmModalOk={() =>
                                this.setState({
                                    isPaymentConfirmModalOpen: false,
                                })
                            }
                            handlePaymentConfirmModalCancel={() =>
                                this.setState({
                                    isPaymentConfirmModalOpen: false,
                                })
                            }
                        />
                    )}
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    padding: "0 1.5rem",
    maxWidth: "80rem",
    margin: "2rem auto",
    "@bp2": { marginTop: "4rem" },
    "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    "& h2": { marginBottom: "3rem" },
    "& .manage-subscription-title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1rem",
        "& button": {
            marginLeft: "1rem",
        },
    },
    "& .manage-subscription": {
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",

        "@bp4": {
            gap: "2rem",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
        "& h3": { margin: "0 0 1rem 0" },
        "& ul": {
            color: "rgb(75 85 99)",
            lineHeight: "1.75rem",
            fontSize: "0.875rem",
            margin: "0 0 0 0",
            padding: "0",
            listStyle: "none",
            "& li": {
                columnGap: "0.75rem",
                display: "flex",
            },
            "& svg": {
                color: "$colors$primary",
                width: "1.25rem",
                height: "1.75rem",
            },
            "& p": {
                margin: "0",
                padding: "0",
            },
        },
    },
    "& .manage-subscription-card-content": {
        display: "flex",
        justifyContent: "space-between",
        "& p": {
            padding: "0",
            margin: "0 0 1rem 0",
        },
    },
    "& .manage-subscription-buttons": {
        display: "grid",
        marginTop: "1rem",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        subscription:
            state.subscriptionReducer?.fetchSubscription?.subscription,
    };
}

export default connect(mapStateToProps, {
    fetchSubscription,
    cancelSubscription,
    editBilling,
    updateSubscription,
})(withRouter(Subscription));
