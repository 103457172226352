import { combineReducers } from "redux";

import authReducer from "./authReducer";
import patternReducer from "./patternReducer";
import subscriptionReducer from "./subscriptionReducer";
import teeReducer from "./teeReducer";

const reducers = {
    authReducer,
    patternReducer,
    subscriptionReducer,
    teeReducer,
};

const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
    if (action.type === "SIGN_OUT_SUCCESS") {
        state = {};
    }
    return appReducer(state, action);
};

export default rootReducer;
