export const BlogPosts = [
    {
        cover: "https://miro.medium.com/v2/resize:fit:1400/format:webp/0*-eeqjNmdZNRKsGP-",
        categories: ["Trends"],
        title: "Top Platforms for Selling Seamless Patterns",
        link: "/blog/top-platform-for-selling-seamless-patterns",
        author: "Phair Team",
        date: "Mar 28, 2024",
        excerpt:
            "Today's market shows an unprecedented demand for distinctive and attractive seamless patterns. These patterns play a crucial role across multiple sectors such as fashion, interior decorating, graphic design, and web development. There are plenty of online platforms that offer the opportunity to profit from your seamless patterns. In this blog post, we’ll delve into the top platforms for selling your seamless patterns, along with strategies for achieving success in the online marketplace.",
    },
    {
        cover: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*kGyMw6a5KPF1nOsUuUIBJQ.png",
        categories: ["Tutorials"],
        title: "How to create mockup images for seamless patterns in Patternful.ai",
        link: "/blog/how-to-create-mockup-images-for-seamless-patterns",
        author: "Phair Team",
        date: "Mar 27, 2024",
        excerpt:
            "Mockup images are essential for presenting designs in a realistic context, demonstrating how the end product will look. With Patternful.ai, producing these mockup images becomes straightforward once you've crafted your pattern.",
    },
    {
        cover: "https://miro.medium.com/v2/resize:fit:1400/format:webp/0*y89zyjZxjk1eW849",
        categories: ["Tutorials"],
        title: "Earning Passive Income on Society6 with Patternful.ai",
        link: "/blog/earn-passive-income-on-society6",
        author: "Phair Team",
        date: "Mar 22, 2024",
        excerpt:
            "Print on demand (POD) is a retail method where items are printed individually once an order is placed, removing the need for stockpiling inventory.",
    },
    {
        cover: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*ZCabx35IVPxfKax8-JxSgw.png",
        categories: ["Tutorials"],
        title: "How can I easily align multiple seamless patterns in Patternful.ai",
        link: "/blog/how-to-align-multiple-seamles-patterns",
        author: "Phair Team",
        date: "Mar 19, 2024",
        excerpt:
            "Aligning multiple seamless patterns in Patternful.ai is easy — we've taken care of it for you!",
    },
    {
        cover: "https://miro.medium.com/v2/resize:fit:4800/format:webp/1*4Svk8xESMCmtMOxKHDnGgg.png",
        categories: ["News"],
        title: "Creating Beautiful Patterns is Now Easy and Quick",
        link: "/blog/create-beautiful-seamless-patterns-is-quick-and-easy",
        author: "Phair Team",
        date: "Mar 18, 2024",
        excerpt:
            "This article introduces Patternful.ai, our new AI-enhanced tool for creating distinctive, eye-catching, and inventive patterns. These patterns are immediately available for use in branding, merchandise, or personal projects, offering a cutting-edge solution for your design needs.",
    },
];
