import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Slider, InputNumber, message } from "antd";
import { PBox, PH4, PText } from "../../theme/BaseTheme";
import { vectorizeImage } from "../../../actions/pattern";
import { vectorizeTeeImage } from "../../../actions/tee";

export const SVGModal = (props) => {
    const [colorsValue, setColorsValue] = useState(6);
    const [layersValue, setLayersValue] = useState(16);
    const [smoothnessValue, setSmoothnessValue] = useState(60);
    const [isProcessing, setIsProcessing] = useState(false);

    return (
        <PBox css={styles}>
            <Modal
                title="Convert to SVG Image"
                open={props.isSVGModalOpen}
                onOk={async () => {
                    await setIsProcessing(true);
                    message.open({
                        type: "loading",
                        content: "Converting to SVG.",
                        duration: 0,
                    });
                    if (props.generatorType == "Tee") {
                        await props.vectorizeTeeImage(
                            props.patternUUID,
                            colorsValue,
                            layersValue,
                            smoothnessValue
                        );
                    } else {
                        await props.vectorizeImage(
                            props.patternUUID,
                            colorsValue,
                            layersValue,
                            smoothnessValue
                        );
                    }
                    message.destroy();
                    await setIsProcessing(false);
                    props.handleSVGModalOk();
                }}
                onCancel={props.handleSVGModalCancel}
                okText={"Convert"}
                okButtonProps={{ loading: isProcessing }}
            >
                <PH4>
                    1. Color Precision - Larger number means more accurate
                    colors.
                </PH4>
                {/*<PText>
                    Number of significant bits to use in an RGB channel.
            </PText>*/}
                <PBox className="svg-modal-control">
                    <Slider
                        min={1}
                        max={8}
                        onChange={(newValue) => {
                            setColorsValue(newValue);
                        }}
                        value={
                            typeof colorsValue === "number" ? colorsValue : 0
                        }
                    />
                    <InputNumber
                        min={1}
                        max={8}
                        style={{
                            margin: "0 16px",
                        }}
                        value={colorsValue}
                        onChange={(newValue) => {
                            setColorsValue(newValue);
                        }}
                    />
                </PBox>
                <PH4>2. Layers - Larger number means less layers.</PH4>
                {/*<PText>Color difference between gradient layers.</PText>*/}
                <PBox className="svg-modal-control">
                    <Slider
                        min={0}
                        max={128}
                        onChange={(newValue) => {
                            setLayersValue(newValue);
                        }}
                        value={
                            typeof layersValue === "number" ? layersValue : 0
                        }
                    />
                    <InputNumber
                        min={0}
                        max={128}
                        style={{
                            margin: "0 16px",
                        }}
                        value={layersValue}
                        onChange={(newValue) => {
                            setLayersValue(newValue);
                        }}
                    />
                </PBox>
                <PH4>3. Smoothness - Larger number means smoother corners.</PH4>
                {/*<PText>
                    Minimum momentary angle (degree) to be considered a corner
                    (to be kept after smoothing).
                    </PText>*/}
                <PBox className="svg-modal-control">
                    <Slider
                        min={0}
                        max={180}
                        onChange={(newValue) => {
                            setSmoothnessValue(newValue);
                        }}
                        value={
                            typeof smoothnessValue === "number"
                                ? smoothnessValue
                                : 0
                        }
                    />
                    <InputNumber
                        min={0}
                        max={180}
                        style={{
                            margin: "0 16px",
                        }}
                        value={smoothnessValue}
                        onChange={(newValue) => {
                            setSmoothnessValue(newValue);
                        }}
                    />
                </PBox>
            </Modal>
        </PBox>
    );
};

const styles = {
    "& .svg-modal-control": {
        display: "flex",
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, { vectorizeImage, vectorizeTeeImage })(
    withRouter(SVGModal)
);
