import { Helmet } from "react-helmet";
import GetInTouch from "../../sections/GetInTouch";
import { Layout } from "./Layout";

export const Contact = () => {
    return (
        <Layout>
            <Helmet>
                <title>Contact</title>
                <meta
                    name="description"
                    content="We'd love to hear from you! If you have questions, requests, and feedback, please submit the form below. You could also email us at support@phair.io."
                />
                <meta
                    name="keywords"
                    content="Patternful, Patternful AI, AI Image Generation, AI Image Processing, AI Image Design, AI Image Editing, Seamless Pattern Generator, Mockup Generator, Background Remover, Binary Mask Generator, Image Upscaler, Digital Art, Graphic Design"
                />
            </Helmet>
            <GetInTouch />
        </Layout>
    );
};
