import {
    PSection,
    PBox,
    PContainer,
    PText,
    PH2,
    PH3,
    PH4,
    PUl,
    PLi,
    POl,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";

export const Terms = (props) => {
    return (
        <Layout>
            <PSection css={styles}>
                <PBox className="terms">
                    <PBox className="terms-title">
                        <PH2>Terms of Use</PH2>
                    </PBox>
                    <PBox>
                        <PText>
                            <i>Last updated: March 9th, 2024</i>
                        </PText>
                        <PH3>1. Introduction</PH3>
                        <PText>
                            Welcome to <b>Patternful.ai</b> (“<b>Company</b>”, “
                            <b>we</b>”, “<b>our</b>”, “<b>us</b>”)!
                        </PText>
                        <PText>
                            These Terms of Service (“<b>Terms</b>”, “
                            <b>Terms of Service</b>”) govern your use of our web
                            pages located at{" "}
                            <a href="https://www.patternful.ai">
                                https://www.patternful.ai
                            </a>{" "}
                            operated by Phair Labs.
                        </PText>
                        <PText>
                            Your agreement with us includes these Terms and our
                            Privacy Policy (“<b>Agreements</b>”). You
                            acknowledge that you have read and understood
                            Agreements, and agree to be bound by them. If you do
                            not agree with (or cannot comply with) Agreements,
                            then you may not use the Service, but please let us
                            know by emailing at support@patternful.ai, so we can
                            try to find a solution. These Terms apply to all
                            visitors, users and others who wish to access or use
                            Service.
                        </PText>
                        <PText>Thank you for being responsible.</PText>
                        <PH3>2. Purchases</PH3>
                        <PText>
                            If you wish to purchase any product or service made
                            available through Service (“<b>Purchase</b>”), you
                            may be asked to supply certain information relevant
                            to your Purchase including, without limitation, your
                            credit card number, the expiration date of your
                            credit card, your billing address, and your shipping
                            information.
                        </PText>
                        <PText>
                            You represent and warrant that: (i) you have the
                            legal right to use any credit card(s) or other
                            payment method(s) in connection with any Purchase;
                            and that (ii) the information you supply to us is
                            true, correct and complete.
                        </PText>
                        <PText>
                            We may employ the use of third party services for
                            the purpose of facilitating payment and the
                            completion of Purchases. By submitting your
                            information, you grant us the right to provide the
                            information to these third parties subject to our
                            Privacy Policy.
                        </PText>
                        <PText>
                            We reserve the right to refuse or cancel your order
                            at any time for reasons including but not limited
                            to: product or service availability, errors in the
                            description or price of the product or service,
                            error in your order or other reasons.
                        </PText>
                        <PText>
                            We reserve the right to refuse or cancel your order
                            if fraud or an unauthorized or illegal transaction
                            is suspected.
                        </PText>
                        <PH3>3. Subscriptions</PH3>
                        <PText>
                            Some parts of Service are billed on a subscription
                            basis (“<b>Subscription(s)</b>”). You will be billed
                            in advance on a recurring and periodic basis (“
                            <b>Billing Cycle</b>”). Billing cycles are set
                            either on a monthly or annual basis, depending on
                            the type of subscription plan you select when
                            purchasing a Subscription.
                        </PText>
                        <PText>
                            At the end of each Billing Cycle, your Subscription
                            will automatically renew under the exact same
                            conditions unless you cancel it or Patternful.ai
                            cancels it. You may cancel your Subscription renewal
                            either through your online account management page
                            or by contacting Patternful.ai customer support
                            team.
                        </PText>
                        <PText>
                            A valid payment method, including credit card, is
                            required to process the payment for your
                            subscription. You shall provide Patternful.ai with
                            accurate and complete billing information including
                            full name, address, state, zip code, telephone
                            number, and a valid payment method information. By
                            submitting such payment information, you
                            automatically authorize Patternful.ai to charge all
                            Subscription fees incurred through your account to
                            any such payment instruments. Should automatic
                            billing fail to occur for any reason, Patternful.ai
                            will issue an electronic invoice indicating that you
                            must proceed manually, within a certain deadline
                            date, with the full payment corresponding to the
                            billing period as indicated on the invoice.
                        </PText>
                        <PH3>4. Fee Changes</PH3>
                        <PText>
                            Patternful.ai, in its sole discretion and at any
                            time, may modify Subscription fees for the
                            Subscriptions. Any Subscription fee change will
                            become effective at the end of the then-current
                            Billing Cycle.
                        </PText>
                        <PText>
                            Patternful.ai will provide you with a reasonable
                            prior notice of any change in Subscription fees to
                            give you an opportunity to terminate your
                            Subscription before such change becomes effective.
                            Your continued use of Service after Subscription fee
                            change comes into effect constitutes your agreement
                            to pay the modified Subscription fee amount.
                        </PText>
                        <PH3>5. Refunds</PH3>
                        <PText>
                            We issue refunds for Contracts within twenty-four
                            (24) hours of the original purchase of the Contract.
                        </PText>
                        <PH3>6. Content</PH3>
                        <PText>
                            Our Service allows you to post, link, store, share
                            and otherwise make available certain information,
                            text, graphics, or other material (“<b>Content</b>
                            ”). You are responsible for Content that you post on
                            or through Service, including its legality,
                            reliability, and appropriateness.
                        </PText>
                        <PText>
                            By posting Content on or through Service, You
                            represent and warrant that: (i) Content is yours
                            (you own it) and/or you have the right to use it and
                            the right to grant us the rights and license as
                            provided in these Terms, and (ii) that the posting
                            of your Content on or through Service does not
                            violate the privacy rights, publicity rights,
                            copyrights, contract rights or any other rights of
                            any person or entity. We reserve the right to
                            terminate the account of anyone found to be
                            infringing on a copyright.
                        </PText>
                        <PText>
                            You retain any and all of your rights to any Content
                            you submit, post or display on or through Service
                            and you are responsible for protecting those rights.
                            We take no responsibility and assume no liability
                            for Content you or any third party posts on or
                            through Service. However, by posting Content using
                            Service you grant us the right and license to use,
                            modify, publicly perform, publicly display,
                            reproduce, and distribute such Content on and
                            through Service. You agree that this license
                            includes the right for us to make your Content
                            available to other users of Service, who may also
                            use your Content subject to these Terms.
                        </PText>
                        <PText>
                            Patternful.ai has the right but not the obligation
                            to monitor and edit all Content provided by users.
                            In addition, Content found on or through this
                            Service are the property of Patternful.ai or used
                            with permission. You may not distribute, modify,
                            transmit, reuse, download, repost, copy, or use said
                            Content, whether in whole or in part, for commercial
                            purposes or for personal gain, without express
                            advance written permission from us.
                        </PText>
                        <PH3>7. Prohibited Uses</PH3>
                        <PText>
                            You may use Service only for lawful purposes and in
                            accordance with Terms. You agree not to use Service:
                        </PText>
                        <PUl>
                            <PLi>
                                In any way that violates any applicable national
                                or international law or regulation.
                            </PLi>
                            <PLi>
                                For the purpose of exploiting, harming, or
                                attempting to exploit or harm minors in any way
                                by exposing them to inappropriate content or
                                otherwise.
                            </PLi>
                            <PLi>
                                To transmit, or procure the sending of, any
                                advertising or promotional material, including
                                any “junk mail”, “chain letter,” “spam,” or any
                                other similar solicitation.
                            </PLi>
                            <PLi>
                                To impersonate or attempt to impersonate
                                Company, a Company employee, another user, or
                                any other person or entity.
                            </PLi>
                            <PLi>
                                In any way that infringes upon the rights of
                                others, or in any way is illegal, threatening,
                                fraudulent, or harmful, or in connection with
                                any unlawful, illegal, fraudulent, or harmful
                                purpose or activity.
                            </PLi>
                            <PLi>
                                To engage in any other conduct that restricts or
                                inhibits anyone's use or enjoyment of Service,
                                or which, as determined by us, may harm or
                                offend Company or users of Service or expose
                                them to liability.
                            </PLi>
                        </PUl>
                        <PText>Additionally, you agree not to:</PText>
                        <PUl>
                            <PLi>
                                Use Service in any manner that could disable,
                                overburden, damage, or impair Service or
                                interfere with any other party's use of Service,
                                including their ability to engage in real time
                                activities through Service.
                            </PLi>
                            <PLi>
                                Use any robot, spider, or other automatic
                                device, process, or means to access Service for
                                any purpose, including monitoring or copying any
                                of the material on Service.
                            </PLi>
                            <PLi>
                                Use any manual process to monitor or copy any of
                                the material on Service or for any other
                                unauthorized purpose without our prior written
                                consent.
                            </PLi>
                            <PLi>
                                Use any device, software, or routine that
                                interferes with the proper working of Service.
                            </PLi>
                            <PLi>
                                Introduce any viruses, trojan horses, worms,
                                logic bombs, or other material which is
                                malicious or technologically harmful.
                            </PLi>
                            <PLi>
                                Attempt to gain unauthorized access to,
                                interfere with, damage, or disrupt any parts of
                                Service, the server on which Service is stored,
                                or any server, computer, or database connected
                                to Service.
                            </PLi>
                            <PLi>
                                Attack Service via a denial-of-service attack or
                                a distributed denial-of-service attack.
                            </PLi>
                            <PLi>
                                Take any action that may damage or falsify
                                Company rating.
                            </PLi>
                            <PLi>
                                Otherwise attempt to interfere with the proper
                                working of Service.
                            </PLi>
                            <PLi>
                                Use the Patternful.ai generated assets to
                                encourage or promote illegal activity or
                                violation of third party rights.
                            </PLi>
                        </PUl>
                        <PH3>8. Analytics</PH3>
                        <PText>
                            We may use third-party Service Providers to monitor
                            and analyze the use of our Service.
                        </PText>
                        <PText>
                            <b>Google Analytics</b> Google Analytics is a web
                            analytics service offered by Google that tracks and
                            reports website traffic. Google uses the data
                            collected to track and monitor the use of our
                            Service. This data is shared with other Google
                            services. Google may use the collected data to
                            contextualize and personalize the ads of its own
                            advertising network.
                        </PText>
                        <PH3>9. No Use By Minors</PH3>
                        <PText>
                            Service is intended only for access and use by
                            individuals at least eighteen (18) years old. By
                            accessing or using any of Service, you warrant and
                            represent that you are at least eighteen (18) years
                            of age and with the full authority, right, and
                            capacity to enter into this agreement and abide by
                            all of the terms and conditions of Terms. If you are
                            not at least eighteen (18) years old, you are
                            prohibited from both the access and usage of
                            Service.
                        </PText>
                        <PH3>10. Accounts</PH3>
                        <PText>
                            When you create an account with us, you guarantee
                            that you are above the age of 18, and that the
                            information you provide us is accurate, complete,
                            and current at all times. Inaccurate, incomplete, or
                            obsolete information may result in the immediate
                            termination of your account on Service.
                        </PText>
                        <PText>
                            You are responsible for maintaining the
                            confidentiality of your account and password,
                            including but not limited to the restriction of
                            access to your computer and/or account. You agree to
                            accept responsibility for any and all activities or
                            actions that occur under your account and/or
                            password, whether your password is with our Service
                            or a third-party service. You must notify us
                            immediately upon becoming aware of any breach of
                            security or unauthorized use of your account.
                        </PText>
                        <PText>
                            You may not use as a username the name of another
                            person or entity or that is not lawfully available
                            for use, a name or trademark that is subject to any
                            rights of another person or entity other than you,
                            without appropriate authorization. You may not use
                            as a username any name that is offensive, vulgar or
                            obscene.
                        </PText>
                        <PText>
                            We reserve the right to refuse service, terminate
                            accounts, remove or edit content, or cancel orders
                            at our sole discretion.
                        </PText>
                        <PH3>11. Intellectual Property</PH3>
                        <PText>
                            Service and its original content (excluding Content
                            provided by users), features and functionality are
                            and will remain the exclusive property of
                            Patternful.ai and its licensors. Service is
                            protected by copyright, trademark, and other laws of
                            foreign countries. Our trademarks and trade dress
                            may not be used in connection with any product or
                            service without the prior written consent of
                            Patternful.ai.
                        </PText>
                        <PH3>12. Copyright Policy</PH3>
                        <PText>
                            We respect the intellectual property rights of
                            others. It is our policy to respond to any claim
                            that Content posted on Service infringes on the
                            copyright or other intellectual property rights (“
                            <b>Infringement</b>”) of any person or entity.
                        </PText>
                        <PText>
                            If you are a copyright owner, or authorized on
                            behalf of one, and you believe that the copyrighted
                            work has been copied in a way that constitutes
                            copyright infringement, please submit your claim via
                            email to support@phair.io, with the subject line:
                            “Copyright Infringement” and include in your claim a
                            detailed description of the alleged Infringement as
                            detailed below, under “DMCA Notice and Procedure for
                            Copyright Infringement Claims” You may be held
                            accountable for damages (including costs and
                            attorneys' fees) for misrepresentation or bad-faith
                            claims on the infringement of any Content found on
                            and/or through Service on your copyright.
                        </PText>
                        <PH3>
                            13. DMCA Notice and Procedure for Copyright
                            Infringement Claims
                        </PH3>
                        <PText>
                            You may submit a notification pursuant to the
                            Digital Millennium Copyright Act (DMCA) by providing
                            our Copyright Agent with the following information
                            in writing (see 17 U.S.C 512(c)(3) for further
                            detail):
                        </PText>
                        <POl>
                            <PLi>
                                an electronic or physical signature of the
                                person authorized to act on behalf of the owner
                                of the copyright's interest;
                            </PLi>
                            <PLi>
                                a description of the copyrighted work that you
                                claim has been infringed, including the URL
                                (i.e., web page address) of the location where
                                the copyrighted work exists or a copy of the
                                copyrighted work;
                            </PLi>
                            <PLi>
                                identification of the URL or other specific
                                location on Service where the material that you
                                claim is infringing is located;
                            </PLi>
                            <PLi>
                                your address, telephone number, and email
                                address;
                            </PLi>
                            <PLi>
                                a statement by you that you have a good faith
                                belief that the disputed use is not authorized
                                by the copyright owner, its agent, or the law;
                            </PLi>
                            <PLi>
                                a statement by you, made under penalty of
                                perjury, that the above information in your
                                notice is accurate and that you are the
                                copyright owner or authorized to act on the
                                copyright owner's behalf.
                            </PLi>
                        </POl>
                        <PText>
                            You can contact our Copyright Agent via email at
                            support@phair.io
                        </PText>
                        <PH3>14. Error Reporting and Feedback</PH3>
                        <PText>
                            You may provide us either directly at
                            support@phair.io or via third party sites and tools
                            with information and feedback concerning errors,
                            suggestions for improvements, ideas, problems,
                            complaints, and other matters related to our Service
                            (“<b>Feedback</b>”). You acknowledge and agree that:
                            (i) you shall not retain, acquire or assert any
                            intellectual property right or other right, title or
                            interest in or to the Feedback; (ii) Company may
                            have development ideas similar to the Feedback;
                            (iii) Feedback does not contain confidential
                            information or proprietary information from you or
                            any third party; and (iv) Company is not under any
                            obligation of confidentiality with respect to the
                            Feedback. In the event the transfer of the ownership
                            to the Feedback is not possible due to applicable
                            mandatory laws, you grant Company and its affiliates
                            an exclusive, transferable, irrevocable,
                            free-of-charge, sub-licensable, unlimited and
                            perpetual right to use (including copy, modify,
                            create derivative works, publish, distribute and
                            commercialize) Feedback in any manner and for any
                            purpose.
                        </PText>
                        <PH3>15. Links To Other Web Sites</PH3>
                        <PText>
                            Our Service may contain links to third party web
                            sites or services that are not owned or controlled
                            by Patternful.ai.
                        </PText>
                        <PText>
                            Patternful.ai has no control over, and assumes no
                            responsibility for the content, privacy policies, or
                            practices of any third party web sites or services.
                            We do not warrant the offerings of any of these
                            entities/individuals or their websites.
                        </PText>
                        <PText>
                            YOU ACKNOWLEDGE AND AGREE THAT PATTERNFUL.AI SHALL
                            NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR
                            INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED
                            TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR
                            RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES
                            AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB
                            SITES OR SERVICES.
                        </PText>
                        <PText>
                            WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE
                            AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR
                            SERVICES THAT YOU VISIT.
                        </PText>
                        <PH3>16. Disclaimer Of Warranty</PH3>
                        <PText>
                            THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS”
                            AND “AS AVAILABLE” BASIS. COMPANY MAKES NO
                            REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
                            OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES,
                            OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
                            THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE
                            SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS
                            OBTAINED FROM US IS AT YOUR SOLE RISK.
                        </PText>
                        <PText>
                            NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH
                            COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH
                            RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
                            QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES.
                            WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR
                            ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR
                            WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
                            SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                            BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
                            THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR
                            THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                            VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
                            SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                            THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
                            EXPECTATIONS.
                        </PText>
                        <PText>
                            COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                            WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
                            INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                            MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
                            PARTICULAR PURPOSE.
                        </PText>
                        <PText>
                            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH
                            CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                        </PText>
                        <PH3>17. Limitation Of Liability</PH3>
                        <PText>
                            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND
                            OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS
                            HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL,
                            INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT
                            ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED
                            COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR
                            AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT
                            LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN
                            AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS
                            ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS
                            AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
                            FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM
                            THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY
                            FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
                            REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY
                            ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
                            PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON
                            THE PART OF COMPANY, IT WILL BE LIMITED TO THE
                            AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND
                            UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL
                            OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
                            EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR
                            CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR
                            EXCLUSION MAY NOT APPLY TO YOU.
                        </PText>
                        <PH3>18. Termination</PH3>
                        <PText>
                            We may terminate or suspend your account and bar
                            access to Service immediately, without prior notice
                            or liability, under our sole discretion, for any
                            reason whatsoever and without limitation, including
                            but not limited to a breach of Terms.
                        </PText>
                        <PText>
                            If you wish to terminate your account, you may
                            simply discontinue using Service. All provisions of
                            Terms which by their nature should survive
                            termination shall survive termination, including,
                            without limitation, ownership provisions, warranty
                            disclaimers, indemnity and limitations of liability.
                        </PText>
                        <PH3>19. Changes To Service</PH3>
                        <PText>
                            We reserve the right to withdraw or amend our
                            Service, and any service or material we provide via
                            Service, in our sole discretion without notice. We
                            will not be liable if for any reason all or any part
                            of Service is unavailable at any time or for any
                            period. From time to time, we may restrict access to
                            some parts of Service, or the entire Service, to
                            users, including registered users.
                        </PText>
                        <PH3>20. Amendments To Terms</PH3>
                        <PText>
                            We may amend Terms at any time by posting the
                            amended terms on this site. It is your
                            responsibility to review these Terms periodically.
                        </PText>
                        <PText>
                            Your continued use of the Service following the
                            posting of revised Terms means that you accept and
                            agree to the changes. You are expected to check this
                            page frequently so you are aware of any changes, as
                            they are binding on you.
                        </PText>
                        <PText>
                            By continuing to access or use our Service after any
                            revisions become effective, you agree to be bound by
                            the revised terms. If you do not agree to the new
                            terms, you are no longer authorized to use Service.
                        </PText>
                        <PH3>21. Waiver And Severability</PH3>
                        <PText>
                            No waiver by Company of any term or condition set
                            forth in Terms shall be deemed a further or
                            continuing waiver of such term or condition or a
                            waiver of any other term or condition, and any
                            failure of Company to assert a right or provision
                            under Terms shall not constitute a waiver of such
                            right or provision.
                        </PText>
                        <PText>
                            If any provision of Terms is held by a court or
                            other tribunal of competent jurisdiction to be
                            invalid, illegal or unenforceable for any reason,
                            such provision shall be eliminated or limited to the
                            minimum extent such that the remaining provisions of
                            Terms will continue in full force and effect.
                        </PText>
                        <PH3>26. Acknowledgement</PH3>
                        <PText>
                            BY USING SERVICE OR OTHER SERVICES PROVIDED BY US,
                            YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF
                            SERVICE AND AGREE TO BE BOUND BY THEM.
                        </PText>
                        <PH3>27. Contact Us</PH3>
                        <PText>
                            Please send your feedback, comments, requests for
                            technical support: By email: support@phair.io.
                        </PText>
                    </PBox>
                </PBox>
            </PSection>
        </Layout>
    );
};

const styles = {
    color: "rgb(75 85 99)",
    lineHeight: "1.8",
    maxWidth: "56rem",
    padding: "0 2rem",
    margin: "4rem auto",
    "& .terms": {
        marginBottom: "8rem",
    },
    "& .terms-title": {
        margin: "4rem 0",
        "& h2": {
            lineHeight: "2.5rem",
            fontWeight: "700",
            fontSize: "2.5rem",
            color: "rgb(17 24 39)",
        },
    },
    "& a": {
        color: "$colors$primary",
        textDecoration: "none",
        fontWeight: "500",
    },
};
