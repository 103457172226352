import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Logo } from "../components/Logo";
import { PBox, PSection, PLink } from "../theme/BaseTheme";
import { Dropdown, Tag } from "antd";
import Sticky from "react-sticky-el";
import { userSignOut, refetchUser } from "../../actions/auth";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKey: "",
            isLoading: true,
        };
    }
    async componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const refetch = urlParams.get("refetch");
        if (refetch === "true") {
            await this.props.refetchUser();
        }
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <></>;
        }
        return (
            <PSection css={styles}>
                <Sticky>
                    <PBox className="header-wrapper">
                        <PBox className="header">
                            <Logo />
                            <PBox className="search-bar">
                                <Dropdown
                                    trigger={["click"]}
                                    menu={{
                                        items: [
                                            {
                                                label: (
                                                    <a
                                                        href={`/seamless-pattern`}
                                                    >
                                                        Seamless Pattern
                                                        Generator
                                                    </a>
                                                ),
                                                key: "seamless-pattern-generator",
                                            },
                                            {
                                                label: (
                                                    <a href={`/mockup`}>
                                                        Mockup Generator
                                                    </a>
                                                ),
                                                key: "mockup-generator",
                                            },
                                            {
                                                label: (
                                                    <a href={`/tee-design`}>
                                                        Tee Design Generator
                                                    </a>
                                                ),
                                                key: "tee-design-generator",
                                            },
                                            {
                                                type: "divider",
                                            },
                                            {
                                                label: (
                                                    <a
                                                        href={`/background-remover`}
                                                    >
                                                        Background Remover
                                                    </a>
                                                ),
                                                key: "background-remover",
                                            },
                                            {
                                                label: (
                                                    <a
                                                        href={`/binary-mask-generator`}
                                                    >
                                                        Binary Mask Generator
                                                    </a>
                                                ),
                                                key: "binary-mask-generator",
                                            },
                                            {
                                                label: (
                                                    <a href={`/image-upscaler`}>
                                                        Image Upscaler
                                                    </a>
                                                ),
                                                key: "image-upscaler",
                                            },
                                            {
                                                label: (
                                                    <a href={`/object-remover`}>
                                                        Object Remover
                                                    </a>
                                                ),
                                                key: "object-remover",
                                            },
                                        ],
                                    }}
                                >
                                    <PLink className="sign-in">Tools</PLink>
                                </Dropdown>
                                <Dropdown
                                    trigger={["click"]}
                                    menu={{
                                        items: [
                                            {
                                                label: (
                                                    <a
                                                        href={`/pattern-gallery/1`}
                                                    >
                                                        Pattern Gallery
                                                    </a>
                                                ),
                                                key: "pattern-gallery",
                                            },
                                            {
                                                label: (
                                                    <a
                                                        href={`/tee-design-gallery/1`}
                                                    >
                                                        Tee Design Gallery
                                                    </a>
                                                ),
                                                key: "tee-design-gallery",
                                            },
                                        ],
                                    }}
                                >
                                    <PLink className="sign-in">Gallery</PLink>
                                </Dropdown>

                                <PLink className="sign-in" href="/pricing">
                                    Pricing
                                </PLink>
                            </PBox>
                            {!this.props.userAuth?.isLoggedIn && (
                                <PLink className="sign-in" href="/sign-in">
                                    Sign in
                                </PLink>
                            )}
                            {this.props.userAuth?.isLoggedIn && (
                                <PBox className="credits">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1.75rem"
                                        height="1.75rem"
                                        fill="#000000"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                    </svg>
                                    {this.props.userAuth?.userAccount?.credits}
                                </PBox>
                            )}
                            {this.props.userAuth?.isLoggedIn && (
                                <PBox className="account">
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    label: (
                                                        <PBox className="account-user-details">
                                                            <PBox>
                                                                <b>
                                                                    {
                                                                        this
                                                                            .props
                                                                            .userAuth
                                                                            ?.userAccount
                                                                            ?.user_name
                                                                    }
                                                                </b>{" "}
                                                                {this.props
                                                                    .userAuth
                                                                    ?.userAccount
                                                                    ?.plan ===
                                                                    "free" && (
                                                                    <Tag>
                                                                        Free
                                                                    </Tag>
                                                                )}
                                                                {this.props.userAuth?.userAccount?.plan.includes(
                                                                    "starter"
                                                                ) && (
                                                                    <Tag color="purple">
                                                                        Starter
                                                                    </Tag>
                                                                )}
                                                                {this.props.userAuth?.userAccount?.plan.includes(
                                                                    "standard"
                                                                ) && (
                                                                    <Tag color="blue">
                                                                        Standard
                                                                    </Tag>
                                                                )}
                                                                {this.props.userAuth?.userAccount?.plan.includes(
                                                                    "pro"
                                                                ) && (
                                                                    <Tag color="gold">
                                                                        Pro
                                                                    </Tag>
                                                                )}
                                                            </PBox>
                                                            <PBox>
                                                                {
                                                                    this.props
                                                                        .userAuth
                                                                        ?.userAccount
                                                                        ?.email
                                                                }
                                                            </PBox>
                                                        </PBox>
                                                    ),
                                                    key: "credits",
                                                },
                                                { type: "divider" },
                                                {
                                                    label: (
                                                        <a href={`/patterns/1`}>
                                                            My Patterns
                                                        </a>
                                                    ),
                                                    key: "patterns",
                                                },
                                                {
                                                    label: (
                                                        <a href={`/tees/1`}>
                                                            My Tee Designs
                                                        </a>
                                                    ),
                                                    key: "tee-designs",
                                                },
                                                { type: "divider" },
                                                {
                                                    label: (
                                                        <a
                                                            href={`/manage-subscription`}
                                                        >
                                                            Manage Subscription
                                                        </a>
                                                    ),
                                                    key: "subscription",
                                                },
                                                {
                                                    label: (
                                                        <PBox
                                                            onClick={() => {
                                                                this.props
                                                                    .userSignOut()
                                                                    .then(
                                                                        () => {
                                                                            this.props.history.push(
                                                                                "/sign-out"
                                                                            );
                                                                        }
                                                                    );
                                                            }}
                                                        >
                                                            Sign Out
                                                        </PBox>
                                                    ),
                                                    key: "signout",
                                                },
                                            ],
                                        }}
                                        trigger={["click"]}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.75rem"
                                            height="1.75rem"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path>
                                        </svg>
                                    </Dropdown>
                                </PBox>
                            )}

                            {this.props.userAuth?.isLoggedIn && (
                                <Dropdown
                                    trigger={["click"]}
                                    menu={{
                                        items: [
                                            {
                                                label: (
                                                    <a
                                                        href={`/seamless-pattern/app`}
                                                    >
                                                        Generating Pattern
                                                    </a>
                                                ),
                                                key: "seamless-pattern-generator",
                                            },
                                            {
                                                label: (
                                                    <a href={`/mockup/app`}>
                                                        Generating Mockup
                                                    </a>
                                                ),
                                                key: "mockup-generator",
                                            },
                                            {
                                                label: (
                                                    <a href={`/tee-design/app`}>
                                                        Generating Tee Design
                                                    </a>
                                                ),
                                                key: "tee-design-generator",
                                            },
                                            {
                                                type: "divider",
                                            },
                                            {
                                                label: (
                                                    <a
                                                        href={`/background-remover/app`}
                                                    >
                                                        Removing Background
                                                    </a>
                                                ),
                                                key: "background-remover",
                                            },
                                            {
                                                label: (
                                                    <a
                                                        href={`/binary-mask-generator/app`}
                                                    >
                                                        Generating Binary Mask
                                                    </a>
                                                ),
                                                key: "binary-mask-generator",
                                            },
                                            {
                                                label: (
                                                    <a
                                                        href={`/image-upscaler/app`}
                                                    >
                                                        Upscaling Image
                                                    </a>
                                                ),
                                                key: "image-upscaler",
                                            },
                                            {
                                                label: (
                                                    <a
                                                        href={`/object-remover/app`}
                                                    >
                                                        Removing Objects
                                                    </a>
                                                ),
                                                key: "object-remover",
                                            },
                                        ],
                                    }}
                                >
                                    <PLink
                                        className="banner-browsing-button"
                                        //href="/seamless-pattern/app"
                                    >
                                        Start
                                    </PLink>
                                </Dropdown>
                            )}

                            {!this.props.userAuth?.isLoggedIn && (
                                <PLink
                                    className="banner-browsing-button"
                                    href="/sign-up"
                                >
                                    Sign up
                                </PLink>
                            )}
                        </PBox>
                    </PBox>
                </Sticky>
            </PSection>
        );
    }
}

const styles = {
    zIndex: "100",
    "& .header-wrapper": {
        borderBottom: "1px solid $colors$borderColor",
        backgroundColor: "#fff",

        padding: "0.75rem 1rem",
    },
    "& .header": {
        paddingTop: "2px",
        paddingBottom: "2px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: "80rem",
        margin: "0 auto",
    },
    "& .search-bar": {
        display: "flex",
        flexGrow: "1",
        marginLeft: "1rem",
        marginRight: "1rem",
    },
    "& .sign-in": {
        marginRight: "1rem",
        textDecoration: "none",
        fontWeight: "600",
        fontSize: "14px",
        color: "$colors$text",
        cursor: "pointer",
        "&:hover": {
            color: "$colors$primary",
        },
    },
    "& .start-selling": {
        marginRight: "10px",
        paddingRight: "10px",
        textDecoration: "none",
        fontWeight: "600",
        fontSize: "14px",
        borderRight: "1px solid $colors$label",
    },
    "& .shopping-cart": {
        cursor: "pointer",
        margin: "0 6px",
        display: "block",
        "& span": { display: "block" },
        "& svg": { fill: "$colors$text", display: "block" },
        "& svg:hover": { fill: "$colors$link" },
    },
    "& .shop-dashboard": {
        cursor: "pointer",
        margin: "0 6px",
        "& svg": { fill: "$colors$text" },
        "& svg:hover": { fill: "$colors$link" },
    },
    "& .account": {
        cursor: "pointer",
        margin: "0 1rem 0 0",
        "& svg": { fill: "$colors$text", display: "block" },
        "& svg:hover": { fill: "$colors$link" },
    },
    "& .credits": {
        cursor: "pointer",
        margin: "0 1rem",
        display: "flex",
        alignItems: "center",
        fontWeight: "700",
        "& svg": {
            fill: "$colors$text",
            display: "block",
            marginRight: "0.75rem",
        },
        "& svg:hover": { fill: "$colors$link" },
        "&:hover": { color: "$colors$primary" },
    },
    "& .banner-browsing-button": {
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: "#fff",
        padding: "0.625rem 0.875rem",
        backgroundColor: "$colors$primary",
        borderRadius: "0.375rem",
        textDecoration: "none",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "$colors$primary500",
        },
    },
    "& .account-user-details": {
        padding: "0.5rem 0",
    },
};

function mapStateToProps(state) {
    return {
        userAuth: state.authReducer?.userAuth,
    };
}

export default connect(mapStateToProps, { userSignOut, refetchUser })(
    withRouter(Header)
);
