import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Input, Pagination } from "antd";
import {
    TwitterShareButton,
    XIcon,
    PinterestShareButton,
    PinterestIcon,
} from "react-share";
import { PBox, PH1, PImg, PLink, PText, PH4 } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { fetchSearchTeeDesigns } from "../../../actions/tee";

class TeeDesignGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            searchKey: "",
            page: parseInt(this.props.match.params.id),
            pageSize: 20,
            urlParams: new URLSearchParams(window.location.search),
            resultKey: "",
        };
    }
    async componentDidMount() {
        await this.props.fetchSearchTeeDesigns(
            this.state.urlParams,
            (this.state.page - 1) * this.state.pageSize,
            this.state.pageSize
        );
        await this.setState({
            searchKey: this.state.urlParams.get("key"),
            resultKey:
                "search_tee_designs" + "_" + this.state.urlParams.toString(),
        });
        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Tee Design Gallery</title>
                    <meta
                        name="description"
                        content="Showcase tee designs generated by Patternful.ai."
                    />
                    <meta
                        name="keywords"
                        content="Tee Design Generator, Tee Design AI, Tshirt Design Generator AI, Tshirt Design Maker, Tee Design Generator APP, Tshirt Design Online Generator, Tote Bag Design Generator, Tote Bag Design Generator AI, Print On Demand, Tshirt Design, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="pattern-gallery-title">
                        <PH1>Tee Design Gallery</PH1>
                        {/*!this.props.userAccount && (
                            <PText>
                                Try our{" "}
                                <a href="/tee-design/app">
                                    Tee Design Generator
                                </a>{" "}
                                to create your own unique and beautiful
                                patterns.{" "}
                                <a href="/sign-up" target="_blank">
                                    Sign up
                                </a>{" "}
                                to get <b>20 FREE credits</b>!
                            </PText>
                        )*/}
                        {/*this.props.userAccount &&
                            this.props.userAccount.plan == "free" && (
                                <PText>
                                    <a href="/pricing">Subscribe</a> to one of
                                    our plans to get access to our Community
                                    Gallery.
                                </PText>
                            )*/}
                        {/*this.props.userAccount &&
                            this.props.userAccount.plan != "free" && (
                                <PText>
                                    <b>Update</b>: Only paid members can view
                                    our Community Gallery. Upgrade to{" "}
                                    <a href="/pricing">Pro</a> plan to keep your
                                    generations private.
                                </PText>
                            )*/}
                    </PBox>
                    {/*this.props.userAccount &&
                        this.props.userAccount.plan != "free" && (
                            <PBox className="pattern-gallery-search">
                                <Input.Search
                                    placeholder="Search for patterns"
                                    onSearch={(value) => {
                                        window.location.href = `/tee-design-gallery/1?key=${value}`;
                                    }}
                                />
                            </PBox>
                        )*/}
                    {/*this.props.userAccount &&
                        this.props.userAccount.plan != "free" &&
                        this.props.searchResults[this.state.resultKey]
                            ?.totalSearchTeeDesigns !== 0 && (
                            <PH4>Search results for {this.state.searchKey}</PH4>
                        )*/}
                    <PBox className="pattern-gallery-search">
                        <Input.Search
                            placeholder="Search for patterns"
                            onSearch={(value) => {
                                window.location.href = `/tee-design-gallery/1?key=${value}`;
                            }}
                        />
                    </PBox>
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchTeeDesigns !== 0 && (
                        <PH4>Search results for {this.state.searchKey}</PH4>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchTeeDesigns !== 0 && (
                        <PBox className="pattern-gallery-container">
                            {this.props.searchResults[
                                this.state.resultKey
                            ]?.searchTeeDesigns?.map((tee, idx) => {
                                return (
                                    <PBox className="pattern-gallery-image">
                                        <PImg
                                            crossorigin={
                                                process.env.NODE_ENV ===
                                                "development"
                                                    ? "anonymous"
                                                    : ""
                                            }
                                            src={tee.src}
                                            alt={
                                                "patternful ai" + tee.prompt !==
                                                "hidden"
                                                    ? tee.prompt
                                                    : ""
                                            }
                                        />
                                        <PBox className="pattern-gallery-share-buttons">
                                            <TwitterShareButton
                                                url={String(
                                                    window.location.origin
                                                )}
                                                title={`${
                                                    tee.prompt !== "hidden"
                                                        ? tee.prompt
                                                        : ""
                                                }`}
                                                hashtags={[
                                                    "PatternfulAI",
                                                    "Phair",
                                                    "DesignInspiration",
                                                    "TeeDesign",
                                                    "TShirtDesign",
                                                    "TShirts",
                                                    "Apparel",
                                                    "Clothing",
                                                    "TShirtLovers",
                                                    "TShirtForSale",
                                                    "TShirtArt",
                                                    "GraphicTShirt",
                                                ]}
                                            >
                                                <XIcon size={32} round />
                                            </TwitterShareButton>
                                            <PinterestShareButton
                                                url={String(
                                                    window.location.origin
                                                )}
                                                media={tee.src}
                                                description={
                                                    tee.prompt !== "hidden"
                                                        ? tee.prompt
                                                        : ""
                                                }
                                            >
                                                <PinterestIcon
                                                    size={32}
                                                    round
                                                />
                                            </PinterestShareButton>
                                        </PBox>
                                        <PBox className="pattern-gallery-prompt">
                                            <PText>Prompt: {tee.prompt}</PText>
                                        </PBox>
                                    </PBox>
                                );
                            })}
                        </PBox>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchTeeDesigns === 0 && (
                        <PH4>No Results Found.</PH4>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchTeeDesigns !== 0 && (
                        <PBox className="pattern-gallery-pagination">
                            <Pagination
                                defaultCurrent={this.state.page}
                                total={parseInt(
                                    this.props.searchResults[
                                        this.state.resultKey
                                    ]?.totalSearchTeeDesigns
                                )}
                                pageSize={this.state.pageSize}
                                showSizeChanger={false}
                                showQuickJumper={true}
                                onChange={async (page) => {
                                    window.location.href = `/tee-design-gallery/${page}?${this.state.urlParams.toString()}`;
                                }}
                            />
                        </PBox>
                    )}
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    padding: "0 1.5rem",
    maxWidth: "80rem",
    margin: "2rem auto",
    "@bp2": { marginTop: "4rem" },
    "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    "& h1": { textAlign: "center", marginBottom: "2rem" },
    "& .pattern-gallery-search": {
        marginBottom: "3rem",
    },
    "& .pattern-gallery-container": {
        margin: "0 auto 6rem auto",
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "@bp2": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
    "& .pattern-gallery-title": {
        textAlign: "center",
        marginBottom: "3rem",
        "& a": {
            textDecoration: "none",
            color: "$colors$primary",
            fontWeight: "500",
        },
    },
    "& .pattern-gallery-image": {
        position: "relative",
        overflow: "hidden",
        border: "1px solid $colors$borderColor",
        borderRadius: "0.5rem",
        "& img": {
            width: "100%",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            display: "block",
        },
        "& .pattern-gallery-share-buttons": {
            position: "absolute",
            left: "1rem",
            top: "1rem",
            "& button": { marginRight: "0.5rem" },
            opacity: "0",
            visibility: "hidden",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
        },
        "& .pattern-gallery-external-link": {
            position: "absolute",
            right: "1rem",
            top: "1rem",
            opacity: "0",
            visibility: "hidden",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            borderRadius: "50%",
            padding: "0.3rem",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            "& svg": { fill: "#fff" },
        },
        "& .pattern-gallery-prompt": {
            position: "absolute",
            left: "0",
            bottom: "0",
            padding: "0 1rem",
            margin: "0",
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            opacity: "0",
            visibility: "hidden",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            "& p": {
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
                display: "-webkit-box",
            },
        },
    },
    "& .pattern-gallery-image:hover": {
        "& img": { transform: "scale(1.1)" },
        "& .pattern-gallery-share-buttons": {
            opacity: "1",
            visibility: "visible",
        },
        "& .pattern-gallery-external-link": {
            opacity: "1",
            visibility: "visible",
        },
        "& .pattern-gallery-prompt": { opacity: "1", visibility: "visible" },
    },
    "& .pattern-gallery-pagination": {
        display: "flex",
        justifyContent: "center",
        marginBottom: "6rem",
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        searchResults: state.teeReducer?.fetchSearchTeeDesigns,
    };
}

export default connect(mapStateToProps, { fetchSearchTeeDesigns })(
    withRouter(TeeDesignGallery)
);
