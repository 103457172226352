import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
    PImg,
} from "../../theme/BaseTheme";

export const LandingTools = () => {
    return (
        <PSection css={styles}>
            <PBox className="landing-tools">
                <PBox className="landing-tools-container">
                    <PText className="landing-tools-subtitle">
                        Patternful.ai Tools
                    </PText>
                    <PH1 className="landing-tools-title">
                        AI-Powered Image Generation and Editing
                    </PH1>

                    <PBox className="landing-tools-wrapper">
                        <Link to="/seamless-pattern">
                            <PBox className="landing-tools-item">
                                <video autoPlay muted loop>
                                    <source
                                        src="/images/landing/pattern-preview.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <PText>Seamless Pattern Generator</PText>
                            </PBox>
                        </Link>
                        <Link to="/mockup">
                            <PBox className="landing-tools-item">
                                <video autoPlay muted loop>
                                    <source
                                        src="/images/landing/mockup-preview.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <PText>Mockup Generator</PText>
                            </PBox>
                        </Link>
                        <Link to="/background-remover">
                            <PBox className="landing-tools-item">
                                <video autoPlay muted loop>
                                    <source
                                        src="/images/landing/bg-remover-preview.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <PText>Background Remover</PText>
                            </PBox>
                        </Link>
                        <Link to="/binary-mask-generator">
                            <PBox className="landing-tools-item">
                                <video autoPlay muted loop>
                                    <source
                                        src="/images/landing/binary-mask-preview.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <PText>Binary Mask Generator</PText>
                            </PBox>
                        </Link>
                        <Link to="/image-upscaler">
                            <PBox className="landing-tools-item">
                                <video autoPlay muted loop>
                                    <source
                                        src="/images/landing/upscaler-preview.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <PText>Image Upscaler</PText>
                            </PBox>
                        </Link>
                        <Link to="/object-remover">
                            <PBox className="landing-tools-item">
                                <video autoPlay muted loop>
                                    <source
                                        src="/images/landing/object-remover-preview.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                                <PText>Object Remover</PText>
                            </PBox>
                        </Link>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    //backgroundColor: "rgb(238, 236, 255)",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .landing-tools": {
        //padding: "4rem 1.5rem 1rem 1.5rem",
        maxWidth: "64rem",
        margin: "0 auto",
        paddingBottom: "2rem",
        "@bp2": { marginTop: "2rem" },
        "@bp4": { paddingLeft: "4rem", paddingRight: "4rem" },
    },
    "& .landing-tools-container": {
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
    },
    "& .landing-tools-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .landing-tools-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.5rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "3rem" },
    },
    "& .landing-tools-wrapper": {
        position: "relative",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        maxWidth: "80rem",
        marginTop: "4rem",
        marginBottom: "4rem",
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "hidden",
        display: "grid",
        maxWidth: "42rem",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gap: "2rem",
        "@bp4": {
            //paddingLeft: "2rem",
            //paddingRight: "2rem",
            maxWidth: "none",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "4rem",
            marginBottom: "4rem",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        },
    },
    "& .landing-tools-item": {
        position: "relative",
        borderRadius: "0.5rem",
        overflow: "hidden",
        cursor: "pointer",
        "& video": {
            width: "100%",
            aspectRatio: "1/1",
        },
        "& p": {
            textAlign: "center",
            margin: "0",
            width: "100%",
            color: "#fff",
            fontWeight: "700",
            fontSize: "1.25rem",
            padding: "1rem 0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
    },
    "& a": {
        textDecoration: "none",
    },
};
