import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PText, PH1 } from "../../theme/BaseTheme";
import { LandingBanner } from "../../sections/landing/LandingBanner";
import { LandingTools } from "../../sections/landing/LandingTools";
import { FeatureImageLeft } from "../../sections/FeatureImageLeft";
import { FeatureImageRight } from "../../sections/FeatureImageRight";
import { SellFaq } from "../../sections/SellFaq";
import { CallToAction } from "../../sections/CallToAction";

const landing_faq_data = [
    {
        question: "What is Patternful.ai?",
        answer: "Patternful.ai is a platform offering AI-enhanced solutions and tools to generate and edit images that are ready-to-use for your brand, merchandise, or personal applications.",
    },
    {
        question: "Do I need to install anything to use Patternful.ai?",
        answer: "No, Patternful.ai is a cloud-based platform. All image generation and editing are done online. You can use it directly in your web browser across multiple devices.",
    },
    {
        question: "Can I use Patternful.ai for free?",
        answer: "Yes, Patternful.ai offers both free and premium services. Free users have access to basic features, while premium users limitlessly enjoy advanced tools and priority support. Sign up for an account on Patternful.ai to receive free credits and explore our premium features.",
    },
    {
        question: "Is my data safe with Patternful.ai?",
        answer: "Yes, your data are safe with us. Patternful.ai prioritizes user data privacy and safety. Your designs are always private, proprietary, and they remain your creative property. All your uploaded images are automatically deleted from our servers after a few hours.",
    },
];

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles}>
                <Layout>
                    <Helmet>
                        <title>Patternful AI</title>
                        <meta
                            name="description"
                            content="Patternful.ai is a platform offering AI-enhanced solutions and tools to generate and edit images that are ready-to-use for your brand, merchandise, or personal applications."
                        />
                        <meta
                            name="keywords"
                            content="Patternful, Patternful AI, AI Image Generation, AI Image Processing, AI Image Design, AI Image Editing, Seamless Pattern Generator, Mockup Generator, Background Remover, Binary Mask Generator, Image Upscaler, Digital Art, Graphic Design"
                        />
                    </Helmet>
                    <LandingBanner />
                    <LandingTools />
                    <FeatureImageLeft
                        image={"/images/landing/seamless-pattern.webp"}
                        title={"Limitless Creativity of Seamless Patterns"}
                        description={
                            "Our seamless pattern generator offers limitless possibilities of unique and appealing patterns to elevate your projects. Enjoy repeatable, seamless, and royalty-free designs."
                        }
                    />
                    <FeatureImageRight
                        image={"/images/mockup/speedup.webp"}
                        title={"Stunning Mockup Images in One Click"}
                        description={
                            "Get product mockups in one click, with ready-made templates. Know how your design will look on a product without having to place an order."
                        }
                    />
                    <FeatureImageLeft
                        image={"/images/removebg/quality.webp"}
                        title={"Make Transparent Background Instantly"}
                        description={
                            "Gone are the days of manual editing and removing image backgrounds. Our background remover automates the process entirely, leaving you with a high-quality, clean PNG background."
                        }
                    />
                    <FeatureImageRight
                        image={"/images/binary-mask/multiple.webp"}
                        title={"Create High-Quality Segmentation Masks"}
                        description={
                            "There's no need to invest in expensive photo editing software. Our binary mask generator help you easily segment and crop out objects, "
                        }
                    />
                    <FeatureImageLeft
                        image={"/images/object-remover/people.webp"}
                        title={"Remove Objects from Photos Online"}
                        description={
                            "Automatically remove people, text, date stamps, and any other unwanted things to create professional images in a few clicks."
                        }
                    />
                    <CallToAction
                        title={"Sign Up and Get Your FREE Credits."}
                        subtitle={
                            "Ready to elevate your projects and business with Patternful.ai? Start generating and editing images today for free."
                        }
                        link={"/sign-up"}
                        cta={"Sign Up"}
                    />
                    <SellFaq data={landing_faq_data} />
                </Layout>
            </PBox>
        );
    }
}

const styles = {
    "& .landing-section-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .landing-section-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.5rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "3rem" },
    },
};

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(Landing));
