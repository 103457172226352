import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PLink,
    PImg,
} from "../../theme/BaseTheme";

export const MockupBanner = (props) => {
    return (
        <PSection css={styles}>
            <PBox className="mockup-banner">
                <PContainer>
                    <PBox className="mockup-banner-container">
                        <svg
                            className="mockup-banner-background"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                    width="200"
                                    height="200"
                                    x="50%"
                                    y="-1"
                                    patternUnits="userSpaceOnUse"
                                >
                                    <path d="M.5 200V.5H200" fill="none"></path>
                                </pattern>
                            </defs>
                            <svg x="50%" y="-1" class="adh aoo">
                                <path
                                    d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                    stroke-width="0"
                                ></path>
                            </svg>
                            <rect
                                width="100%"
                                height="100%"
                                stroke-width="0"
                                fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
                            ></rect>
                        </svg>
                        <PBox className="mockup-banner-wrapper">
                            <PBox className="mockup-banner-left">
                                <PBox className="mockup-banner-desc">
                                    <PH1 className="mockup-banner-title">
                                        Create Stunning Mockups Online <br />
                                        with One Click
                                    </PH1>
                                    <PText className="mockup-banner-text">
                                        Forget about spending hours learning how
                                        to use Photoshop. With just one click,
                                        you can produce beautiful and
                                        near-perfect mockup images.
                                    </PText>
                                </PBox>
                                <PBox className="mockup-banner-start-button">
                                    <PLink href={`/mockup/app`}>
                                        Generate Mockup
                                    </PLink>
                                </PBox>
                            </PBox>
                            <PBox className="mockup-banner-right">
                                <PBox className="mockup-banner-right-column">
                                    <PImg
                                        alt="patternful ai"
                                        className="mockup-banner-img"
                                        src={"/images/mockup_2.jpg"}
                                    />
                                </PBox>
                                <PBox className="mockup-banner-right-column">
                                    <PImg
                                        alt="patternful ai"
                                        className="mockup-banner-img"
                                        src={"/images/mockup_5.jpg"}
                                    />
                                    <PImg
                                        alt="patternful ai"
                                        className="mockup-banner-img"
                                        src={"/images/mockup_3.jpg"}
                                    />
                                </PBox>
                                <PBox className="mockup-banner-right-column">
                                    <PImg
                                        alt="patternful ai"
                                        className="mockup-banner-img"
                                        src={"/images/mockup_4.jpg"}
                                    />
                                    <PImg
                                        alt="patternful ai"
                                        className="mockup-banner-img"
                                        src={"/images/mockup_1.jpg"}
                                    />
                                    <PBox className="mockup-banner-img-fake" />
                                </PBox>
                            </PBox>
                        </PBox>
                    </PBox>
                </PContainer>
            </PBox>
        </PSection>
    );
};

const styles = {
    "& .mockup-banner": {
        fontFamily:
            "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        padding: "2rem 1rem 2rem 1rem",
        maxWidth: "80rem",
        margin: "0 auto",
        "@bp3": { paddingTop: "4rem", paddingBottom: "4rem" },
        "@bp4": { paddingTop: "6rem", paddingBottom: "4rem" },
        //"@bp5": { paddingTop: "8rem" },
    },
    "& .mockup-banner-container": {
        position: "relative",
    },
    "& .mockup-banner-wrapper": {
        "@bp4": { display: "flex" },
        position: "relative",
    },
    "& .mockup-banner-left": {
        flexShrink: "0",
        "@bp3": { paddingRight: "8rem" },
        "@bp4": { paddingRight: "0" },
    },
    "& .mockup-banner-right": {
        marginTop: "-6rem",
        display: "flex",
        gap: "1rem",
        justifyContent: "flex-end",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        maxWidth: "48rem",
        "@bp1": { gap: "2rem" },
        "@bp2": { maxWidth: "64rem" },
        "@bp3": { marginTop: "-10rem" },
        "@bp4": { marginTop: "2rem" },
        "@bp5": { marginTop: "0rem" },
        "@bp6": { marginTop: "-2rem" },
    },
    "& .mockup-banner-right-column": {
        width: "11rem",
        "& img": {
            marginTop: "1rem",
            objectFit: "cover",
            maxWidth: "100%",
            height: "auto",
            borderRadius: "0.75rem",
            aspectRatio: "2/3",
            border: "1px solid $colors$borderColor",
        },
    },
    "& .mockup-banner-img": {
        borderRadius: "1rem",
        height: "32rem",
        marginTop: "4rem",
        width: "100%",
        "@bp2": {
            width: "64rem",
            marginTop: "6rem",
            marginLeft: "auto",
            marginRight: "auto",
        },
        "@bp4": {
            width: "48rem",
            marginLeft: "2rem",
            marginTop: "0",
        },
    },
    "& .mockup-banner-img-fake": {
        height: "12rem",
    },
    "& .mockup-banner-background": {
        maskImage: "radial-gradient(24rem 24rem at center,white,transparent)",
        stroke: "#e5e7eb",
        width: "100%",
        height: "60rem",
        zIndex: "-10",
        inset: "0px",
        top: "-12.5rem",
        left: "0px",
        position: "absolute",
        display: "block",
        verticalAlign: "middle",
        overflowClipMargin: "content-box",
        overflow: "hidden",
    },
    "& .mockup-banner-desc": {
        textAlign: "left",
        marginTop: "0rem",
        "@bp4": { marginTop: "5rem" },
    },
    "& .mockup-banner-title": {
        fontSize: "3rem",
        lineHeight: "3.5rem",
        maxWidth: "36rem",
        textAlign: "left",
        "@bp2": { fontSize: "3.75rem", lineHeight: "4rem" },
        "@bp4": { maxWidth: "32rem" },
        "@bp5": { maxWidth: "36rem" },
    },
    "& .mockup-banner-text": {
        fontSize: "1.125rem",
        marginTop: "1.5rem",
        lineHeight: "2rem",
        maxWidth: "40rem",
        color: "rgb(75 85 99)",
        "@bp4": { maxWidth: "32rem" },
        "@bp5": { maxWidth: "40rem" },
    },
    "& .mockup-banner-start-button": {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        marginTop: "2.5rem",
        "& a": {
            boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)",
            color: "#fff",
            fontWeight: "600",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "0.625rem 0.875rem",
            backgroundColor: "$colors$primary700",
            borderRadius: "0.375rem",
            textDecoration: "none",
            "&:hover": {
                backgroundColor: "$colors$primary500",
            },
        },
    },
};
