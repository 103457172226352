import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { SellFaq } from "../../sections/SellFaq";
import { FeatureImageLeft } from "../../sections/FeatureImageLeft";
import { FeatureImageRight } from "../../sections/FeatureImageRight";
import { BinaryMaskBanner } from "../../sections/binary_mask/BinaryMaskBanner";
import { BinaryMaskHow } from "../../sections/binary_mask/BinaryMaskHow";
import { CallToAction } from "../../sections/CallToAction";

export const binay_mask_generator_faq_data = [
    {
        question: "What are binary masks?",
        answer: "A binary mask defines a region of interest (ROI) of an image. A binary mask image is usually a black and white image that consists of 0 and 1 pixels. Mask pixel values of 1 (white pixels) indicate image pixels that belong to the ROI. Mask pixel values of 0 (black pixels) indicate image pixels that are part of the background.",
    },
    {
        question: "What are the outputs of the binary mask generator?",
        answer: "The output is one zip file, including four images: 1. a binary mask of all highlighted objects; 2. an inverted binary mask of all highlighted objects; 3. an image of all segmented objects (original image with the binary mask applied), the background is transparent; 4. an image of everything else without the segmented objects (original image with inverted binary mask applied).",
    },
    {
        question: "What is the size of the output binary mask?",
        answer: "The output binary masks have the same size as the original image. The output segmented objects also share the same size.",
    },
    {
        question: "What about privacy? Are my images safe?",
        answer: "Yes, your images are safe with us. Patternful.ai prioritizes user data safety. Your images are always private, proprietary, and they remain your property. All your uploaded images are automatically deleted from our servers after a few hours.",
    },
];

class BinaryMaskLanding extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Binary Mask Generator</title>
                    <meta
                        name="description"
                        content="Segment and generate binary masks. AI-powered segmentation and generation of binary object masks, for your online business, marketing, or just for fun!"
                    />
                    <meta
                        name="keywords"
                        content="Binary Mask Generator, Segmentation Mask Generator, Binary Mask Generator APP, Binary Mask Generator Online, Image Segmentation, AI Image Editing, Patternful, Patternful AI"
                    />
                </Helmet>
                <BinaryMaskBanner />
                <BinaryMaskHow />
                <FeatureImageRight
                    image={"/images/binary-mask/multiple.webp"}
                    title={"Multiple Objects Segmentation at the Same Time"}
                    description={
                        "Our binary mask generator can segment multiple isolated objects simultaneously. Upload once and highlight all objects you want, we do the rest for you."
                    }
                />
                <CallToAction
                    title={"Upload Image to Generate Masks"}
                    subtitle={
                        "Segment objects and generate binary masks in just one click."
                    }
                    link={"/binary-mask-generator/app"}
                    cta={"Get Started"}
                />
                <SellFaq data={binay_mask_generator_faq_data} />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(BinaryMaskLanding));
