import { combineReducers } from "redux";

const generatePatterns = (state = { generations: [] }, action) => {
    switch (action.type) {
        case "GENERATE_PATTERNS_SUCCESS":
            return {
                ...state,
                generations: [
                    {
                        isLoading: false,
                        isError: false,
                        isSuccess: true,
                        hash: action.payload.hash,
                        patterns: action.payload.patterns,
                    },
                    ...state.generations,
                ],
            };
        default:
            return state;
    }
};

const generateMockup = (state = {}, action) => {
    switch (action.type) {
        case "GENERATE_MOCKUP_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                mockup: action.payload,
            };
        case "CLEAR_STORED_MOCKUP":
            return {};
        default:
            return state;
    }
};

const removeBackground = (state = {}, action) => {
    switch (action.type) {
        case "REMOVE_BACKGROUND_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                uuid: action.payload.uuid,
                image: action.payload.image,
                uploadedImage: action.uploaded_image_object,
            };
        case "CLEAR_STORED_RMBG_IMAGE":
            return {};
        default:
            return state;
    }
};

const generateBinaryMask = (state = {}, action) => {
    switch (action.type) {
        case "GENERATE_BINARY_MASK_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                uuid: action.payload.uuid,
                image: action.payload.image,
            };
        case "CLEAR_STORED_MASKED_IMAGE":
            return {};
        default:
            return state;
    }
};

const removeObjects = (state = {}, action) => {
    switch (action.type) {
        case "REMOVE_OBJECTS_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                uuid: action.payload.uuid,
                image: action.payload.image,
            };
        case "CLEAR_STORED_OBJECTS_REMOVED_IMAGE":
            return {};
        default:
            return state;
    }
};

const fetchSearchPatterns = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_SEARCH_PATTERNS_SUCCESS":
            state[action.key] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                searchPatterns: action.payload.searchResult,
                searchKey: action.payload.searchKey,
                totalSearchPatterns: action.payload.totalSearchResult,
            };
        default:
            return state;
    }
};

const fetchSearchProducts = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_SEARCH_PRODUCTS_SUCCESS":
            state[action.key] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                searchProducts: action.payload.searchResult,
                searchKey: action.payload.searchKey,
                totalSearchProducts: action.payload.totalSearchResult,
            };
        default:
            return state;
    }
};

const fetchShareProduct = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_SHARE_PRODUCT_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                share: action.payload,
            };
        default:
            return state;
    }
};

const fetchUserPatterns = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_USER_PATTERNS_SUCCESS":
            state[action.key] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                searchPatterns: action.payload.searchResult,
                searchKey: action.payload.searchKey,
                totalSearchPatterns: action.payload.totalSearchResult,
            };
        default:
            return state;
    }
};

const fetchPattern = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_PATTERN_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                pattern: action.payload,
            };
        default:
            return state;
    }
};

const fetchMockupTemplates = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_MOCKUP_TEMPLATES_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                mockup_templates: action.payload,
            };
        case "FETCH_PATTERN_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                mockup_templates: action.payload.mockup_templates,
            };
        default:
            return state;
    }
};

const upscaleImage = (state = {}, action) => {
    switch (action.type) {
        case "UPSCALE_IMAGE_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                upscaledImage: action.payload.image,
            };
        case "CLEAR_UPSCALED_IMAGE":
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    generatePatterns,
    fetchSearchPatterns,
    fetchSearchProducts,
    fetchShareProduct,
    fetchUserPatterns,
    fetchPattern,
    generateMockup,
    removeBackground,
    fetchMockupTemplates,
    generateBinaryMask,
    upscaleImage,
    removeObjects,
});
