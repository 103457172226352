import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox } from "../../theme/BaseTheme";
import Control from "../../sections/tee_design/Control";
import Display from "../../sections/tee_design/Display";

class TeeDesignGeneratorApp extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Tee Designs Generator App</title>
                    <meta
                        name="description"
                        content="Transform your ideas into wearable art with our AI-powered t-shirt design generator."
                    />
                    <meta
                        name="keywords"
                        content="Tee Design Generator, Tee Design AI, Tshirt Design Generator AI, Tshirt Design Maker, Tee Design Generator APP, Tshirt Design Online Generator, Tote Bag Design Generator, Tote Bag Design Generator AI, Print On Demand, Tshirt Design, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="app-content">
                        <Control />
                        <Display />
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    "& .app-content": {
        display: "grid",
        gap: "1rem",
        alignItems: "flex-start",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "@bp4": {
            gap: "2rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
        padding: "3rem 2rem",
        //maxWidth: "80rem",
        margin: "0 auto",
    },
};

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(TeeDesignGeneratorApp));
