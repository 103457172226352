import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PH1, PH2, PBox, PLink, PText } from "../../theme/BaseTheme";

class Sitemap extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles}>
                    <PH1 className="sitemap-title">Sitemap</PH1>
                    <PH2>Pattern Gallery</PH2>
                    {[...Array(2).keys()].map((id) => {
                        return (
                            <PText>
                                <PLink
                                    className="sitemap-link"
                                    href={`/pattern-gallery/${id + 1}`}
                                >
                                    Pattern Gallery Page {id + 1}
                                </PLink>
                            </PText>
                        );
                    })}
                    <PH2>Tools</PH2>
                    <PText>
                        <PLink
                            className="sitemap-link"
                            href={`/seamless-pattern`}
                        >
                            Seamless Pattern Generator
                        </PLink>
                    </PText>
                    <PText>
                        <PLink className="sitemap-link" href={`/mockup`}>
                            Mockup Generator
                        </PLink>
                    </PText>
                    <PText>
                        <PLink
                            className="sitemap-link"
                            href={`/background-remover`}
                        >
                            Background Remover
                        </PLink>
                    </PText>
                    <PText>
                        <PLink
                            className="sitemap-link"
                            href={`/image-upscaler`}
                        >
                            Image Upscaler
                        </PLink>
                    </PText>
                    <PText>
                        <PLink
                            className="sitemap-link"
                            href={`/object-remover`}
                        >
                            Object Remover
                        </PLink>
                    </PText>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    marginBottom: "2.5rem",
    maxWidth: "80rem",

    marginLeft: "auto",
    marginRight: "auto",

    padding: "2rem",
    "& .sitemap-title": {
        fontSize: "2rem",
        fontWeight: "700",
        lineHeight: "2rem",
        color: "rgb(17 24 39)",
        margin: "2rem 0",
        textAlign: "center",
    },
    "& h2": {
        padding: "0.25rem 1rem",
    },
    "& .sitemap-link": {
        padding: "0.25rem 1rem",

        "& a": {
            textDecoration: "none",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: "rgb(17 24 39)",
        },
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {})(withRouter(Sitemap));
