import { Layout } from "./Layout";
import { PContainer, PSection, PBox, PH1, PText } from "../../theme/BaseTheme";

export const ContactConfirm = () => {
    return (
        <Layout>
            <PSection css={styles}>
                <PBox className="contact-confirmation-section">
                    <PContainer>
                        <PBox className="contact-confirmation-section-banner">
                            <PBox className="contact-confirmation-section-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="64"
                                    height="64"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M227.32,28.68a16,16,0,0,0-15.66-4.08l-.15,0L19.57,82.84a16,16,0,0,0-2.42,29.84l85.62,40.55,40.55,85.62A15.86,15.86,0,0,0,157.74,248q.69,0,1.38-.06a15.88,15.88,0,0,0,14-11.51l58.2-191.94c0-.05,0-.1,0-.15A16,16,0,0,0,227.32,28.68ZM157.83,231.85l-.05.14L118.42,148.9l47.24-47.25a8,8,0,0,0-11.31-11.31L107.1,137.58,24,98.22l.14,0L216,40Z"></path>
                                </svg>
                            </PBox>
                            <PH1>Thanks for contacting us!</PH1>
                            <PText>
                                Your message has been sent. We will respond to
                                your request shortly.
                            </PText>
                        </PBox>
                    </PContainer>
                </PBox>
            </PSection>
        </Layout>
    );
};

const styles = {
    "& .contact-confirmation-section": {
        paddingTop: "6rem",
        paddingBottom: "8rem",
    },
    "& .contact-confirmation-section-banner": {
        textAlign: "center",
        maxWidth: "64rem",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "60px",
        color: "$colors$text",
        "& h1": {
            fontWeight: "600",
            fontSize: "32px",
        },
        "& p": {
            lineHeight: "1.8",
        },
    },
    "& .contact-confirmation-section-icon": {
        "& svg": {
            fill: "$colors$primary",
        },
    },
};
