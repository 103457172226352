import { message } from "antd";
import axios from "axios";
import download from "downloadjs";

// Generate tee designs
export const generateTeeDesigns = (shape, prompt) => async (dispatch) => {
    await axios
        .get(`/api/tees/generate/${shape}/${prompt}`)
        .then(async (response) => {
            if (response?.data?.error_message) {
                message.error(response?.data?.error_message);
            }
            dispatch({
                type: "GENERATE_TEE_DESIGNS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Fetch search result in tee design gallery
export const fetchSearchTeeDesigns =
    (urlParams, offset, limit) => async (dispatch) => {
        var key = await urlParams.get("key");
        await axios
            .get(`/api/tees/search_tee_designs/${key}/${offset}/${limit}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_SEARCH_TEE_DESIGNS_SUCCESS",
                    payload: response.data,
                    key: "search_tee_designs" + "_" + urlParams.toString(),
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Fetch tee designs of a user
export const fetchUserTeeDesigns =
    (urlParams, offset, limit) => async (dispatch) => {
        var key = await urlParams.get("key");
        await axios
            .get(`/api/tees/user_tees/${key}/${offset}/${limit}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_USER_TEE_DESIGNS_SUCCESS",
                    payload: response.data,
                    key: "user_tee_designs" + "_" + urlParams.toString(),
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Download tee design image
export const downloadTeeImage =
    (pattern_uuid, format, resolution, size) => async (dispatch) => {
        await axios
            .get(
                `/api/tees/download/${pattern_uuid}/${format}/${resolution}/${size}`,
                {
                    responseType: "arraybuffer",
                }
            )
            .then(async (response) => {
                await download(
                    response.data,
                    `img.${format}`,
                    `image/${format}`
                );
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Convert tee design image to svg
export const vectorizeTeeImage =
    (pattern_uuid, colors, layers, smoothness) => async (dispatch) => {
        await axios
            .get(
                `/api/tees/vectorize/${pattern_uuid}/${colors}/${layers}/${smoothness}`,
                {
                    responseType: "arraybuffer",
                }
            )
            .then(async (response) => {
                await download(response.data, `img.svg`, `image/svg`);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Generate tee design variations
export const generateTeeVariations = (pattern_uuid) => async (dispatch) => {
    await axios
        .get(`/api/tees/variations/${pattern_uuid}`)
        .then(async (response) => {
            if (response?.data?.error_message) {
                message.error(response?.data?.error_message);
            }
            dispatch({
                type: "GENERATE_TEE_DESIGNS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Upscale tee design image
export const upscaleTeeImage = (pattern_uuid, upscale) => async (dispatch) => {
    await axios
        .get(`/api/tees/upscale/${pattern_uuid}/${upscale}`, {
            responseType: "arraybuffer",
        })
        .then(async (response) => {
            await download(response.data, `img.png`, `image/png`);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};
