import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox } from "../../theme/BaseTheme";
import Control from "../../sections/Control";
import Display from "../../sections/Display";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Seamless Pattern Generator App</title>
                    <meta
                        name="description"
                        content="Creating beautiful patterns is now easy and quick. Patternful.ai's AI-powered seamless pattern generator make it easy for you to produce distinctive, attractive, and ready-to-use pattern images."
                    />
                    <meta
                        name="keywords"
                        content="Seamless Pattern Generator, Seamless Pattern AI, Seamless Pattern Maker, Seamless Pattern APP, Seamless Pattern Online Generator, Print On Demand, Pattern Design, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="app-content">
                        <Control />
                        <Display />
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    "& .app-content": {
        display: "grid",
        gap: "1rem",
        alignItems: "flex-start",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "@bp4": {
            gap: "2rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
        padding: "3rem 2rem",
        //maxWidth: "80rem",
        margin: "0 auto",
    },
};

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(App));
