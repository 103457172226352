import { message } from "antd";
import axios from "axios";
import download from "downloadjs";

// Generate seamless patterns
export const generateImages = (prompt) => async (dispatch) => {
    await axios
        .get(`/api/patterns/generate/${prompt}`)
        .then(async (response) => {
            if (response?.data?.error_message) {
                message.error(response?.data?.error_message);
            }
            dispatch({
                type: "GENERATE_PATTERNS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Generate seamless pattern variations
export const generateVariations = (pattern_uuid) => async (dispatch) => {
    await axios
        .get(`/api/patterns/variations/${pattern_uuid}`)
        .then(async (response) => {
            if (response?.data?.error_message) {
                message.error(response?.data?.error_message);
            }
            dispatch({
                type: "GENERATE_PATTERNS_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Generate mockups for seamless patterns
export const generateMockup =
    (pattern_uuid, mockup_uuid, mockup_credits) => async (dispatch) => {
        await axios
            .get(
                `/api/patterns/mockup/${pattern_uuid}/${mockup_uuid}/${mockup_credits}`
            )
            .then(async (response) => {
                dispatch({
                    type: "GENERATE_MOCKUP_SUCCESS",
                    payload: response.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Download edited seamless pattern image
export const downloadEditedImage =
    (pattern_uuid, image_object, format, dpi, width, height, upscale) =>
    async (dispatch) => {
        await axios
            .post(
                `/api/patterns/download_edited_image/${pattern_uuid}/${format}/${dpi}/${width}/${height}/${upscale}`,
                {
                    edited_image: image_object,
                },
                {
                    responseType: "arraybuffer",
                }
            )
            .then(async (response) => {
                await download(
                    response.data,
                    `img.${format}`,
                    `image/${format}`
                );
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Download seamless pattern image
export const downloadImage =
    (pattern_uuid, format, resolution, size) => async (dispatch) => {
        await axios
            .get(
                `/api/patterns/download/${pattern_uuid}/${format}/${resolution}/${size}`,
                {
                    responseType: "arraybuffer",
                }
            )
            .then(async (response) => {
                await download(
                    response.data,
                    `img.${format}`,
                    `image/${format}`
                );
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Convert seamless pattern image to svg
export const vectorizeImage =
    (pattern_uuid, colors, layers, smoothness) => async (dispatch) => {
        await axios
            .get(
                `/api/patterns/vectorize/${pattern_uuid}/${colors}/${layers}/${smoothness}`,
                {
                    responseType: "arraybuffer",
                }
            )
            .then(async (response) => {
                await download(response.data, `img.svg`, `image/svg`);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Upscale seamless pattern image
export const upscalePatternImage =
    (pattern_uuid, upscale) => async (dispatch) => {
        await axios
            .get(`/api/patterns/upscale/${pattern_uuid}/${upscale}`, {
                responseType: "arraybuffer",
            })
            .then(async (response) => {
                await download(response.data, `img.png`, `image/png`);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Fetch search result in pattern gallery
export const fetchSearchPatterns =
    (urlParams, offset, limit) => async (dispatch) => {
        var key = await urlParams.get("key");
        await axios
            .get(`/api/patterns/search_patterns/${key}/${offset}/${limit}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_SEARCH_PATTERNS_SUCCESS",
                    payload: response.data,
                    key: "search_patterns" + "_" + urlParams.toString(),
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Fetch search result in product gallery
export const fetchSearchProducts =
    (urlParams, offset, limit) => async (dispatch) => {
        var key = await urlParams.get("key");

        await axios
            .get(`/api/patterns/search_products/${key}/${offset}/${limit}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_SEARCH_PRODUCTS_SUCCESS",
                    payload: response.data,
                    key: "search_products" + "_" + urlParams.toString(),
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Share seamless pattern in product gallery
export const shareProduct = (pattern_uuid, share, url) => async (dispatch) => {
    await axios
        .post(`/api/patterns/share/${pattern_uuid}/${share}`, { url: url })
        .then(async (response) => {
            message.success(response.data.message, 3);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Fetch share product for seamless pattern
export const fetchShareProduct = (pattern_uuid) => async (dispatch) => {
    await axios
        .get(`/api/patterns/fetch_share/${pattern_uuid}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_SHARE_PRODUCT_SUCCESS",
                payload: response?.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Fetch seamless patterns of a user
export const fetchUserPatterns =
    (urlParams, offset, limit) => async (dispatch) => {
        var key = await urlParams.get("key");
        await axios
            .get(`/api/patterns/user_patterns/${key}/${offset}/${limit}`)
            .then(async (response) => {
                dispatch({
                    type: "FETCH_USER_PATTERNS_SUCCESS",
                    payload: response.data,
                    key: "user_patterns" + "_" + urlParams.toString(),
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Fetch seamless pattern by pattern uuid
export const fetchPattern = (pattern_uuid) => async (dispatch) => {
    await axios
        .get(`/api/patterns/fetch/${pattern_uuid}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_PATTERN_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Fetch mockup templates for mockup generator
export const fetchMockupTemplates = (category) => async (dispatch) => {
    await axios
        .get(`/api/patterns/fetch_mockup_templates/${category}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_MOCKUP_TEMPLATES_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Upload design and generate mockups
export const uploadAndGenerateMockup =
    (uploaded_image_object, mockup_uuid, mockup_credits) =>
    async (dispatch) => {
        await axios
            .post(
                `/api/patterns/upload_and_mockup/${mockup_uuid}/${mockup_credits}`,
                {
                    uploaded_image: uploaded_image_object,
                }
            )
            .then(async (response) => {
                dispatch({
                    type: "GENERATE_MOCKUP_SUCCESS",
                    payload: response.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Clear previous generated mockup for mockup generator
export const clearStoredMockup = () => async (dispatch) => {
    await dispatch({ type: "CLEAR_STORED_MOCKUP" });
};

// Remove background of an image
export const removeBackground = (uploaded_image_object) => async (dispatch) => {
    await axios
        .post(`/api/patterns/remove_background`, {
            uploaded_image: uploaded_image_object,
        })
        .then(async (response) => {
            dispatch({
                type: "REMOVE_BACKGROUND_SUCCESS",
                payload: response.data,
                uploaded_image_object: uploaded_image_object,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Download original size of image with background removed
export const removeBackgroundDownloadHD = (uuid) => async (dispatch) => {
    await axios
        .get(`/api/patterns/remove_background_download_hd/${uuid}`, {
            responseType: "arraybuffer",
        })
        .then(async (response) => {
            await download(response.data, `img.png`, `image/png`);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Clear previously processed image with background removed
export const clearStoredRemoveBackgroundImage = () => async (dispatch) => {
    await dispatch({ type: "CLEAR_STORED_RMBG_IMAGE" });
};

// Generate binary mask of an image
export const generateBinaryMask =
    (uploaded_image_object, sketch_mask_svg) => async (dispatch) => {
        await axios
            .post(`/api/patterns/generate_binary_mask`, {
                uploaded_image: uploaded_image_object,
                sketch_mask_svg: sketch_mask_svg,
            })
            .then(async (response) => {
                dispatch({
                    type: "GENERATE_BINARY_MASK_SUCCESS",
                    payload: response.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Download generated binary masks
export const binaryMaskDownload = (uuid) => async (dispatch) => {
    await axios
        .get(`/api/patterns/binary_mask_download/${uuid}`, {
            responseType: "arraybuffer",
        })
        .then(async (response) => {
            await download(response.data, `mask.zip`, `application/zip`);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Clear previously generated masked image
export const clearStoredMaskedImage = () => async (dispatch) => {
    await dispatch({ type: "CLEAR_STORED_MASKED_IMAGE" });
};

// Upscale image for upscaler
export const upscaleImage =
    (uploaded_image_object, upscale) => async (dispatch) => {
        await axios
            .post(`/api/patterns/upscale_image/${upscale}`, {
                uploaded_image: uploaded_image_object,
            })
            .then(async (response) => {
                dispatch({
                    type: "UPSCALE_IMAGE_SUCCESS",
                    payload: response.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Download Upscaled Image
export const upscaleImageDownload = (image) => async (dispatch) => {
    await axios
        .get(`/api/patterns/upscale_image_download/${image}`, {
            responseType: "arraybuffer",
        })
        .then(async (response) => {
            await download(response.data, `img.png`, `image/png`);
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};

// Clear previously upscaled image
export const clearUpscaledImage = () => async (dispatch) => {
    await dispatch({ type: "CLEAR_UPSCALED_IMAGE" });
};

// Remove highlighted objects in an image
export const removeObjects =
    (uploaded_image_object, sketch_mask_svg) => async (dispatch) => {
        await axios
            .post(`/api/patterns/remove_objects`, {
                uploaded_image: uploaded_image_object,
                sketch_mask_svg: sketch_mask_svg,
            })
            .then(async (response) => {
                dispatch({
                    type: "REMOVE_OBJECTS_SUCCESS",
                    payload: response.data,
                });
            })
            .catch((error) => {
                message.error(error?.response?.data?.message, 5);
            });
    };

// Clear previously generated masked image
export const clearStoredObjectsRemovedImage = () => async (dispatch) => {
    await dispatch({ type: "CLEAR_STORED_OBJECTS_REMOVED_IMAGE" });
};
