import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Pagination } from "antd";
import {
    TwitterShareButton,
    XIcon,
    PinterestShareButton,
    PinterestIcon,
} from "react-share";
import { PBox, PH1, PImg, PLink, PText, PH4 } from "../../theme/BaseTheme";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { fetchSearchProducts } from "../../../actions/pattern";

class ProductGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            searchKey: "",
            page: parseInt(this.props.match.params.id),
            pageSize: 20,
            urlParams: new URLSearchParams(window.location.search),
            resultKey: "",
        };
    }
    async componentDidMount() {
        await this.props.fetchSearchProducts(
            this.state.urlParams,
            (this.state.page - 1) * this.state.pageSize,
            this.state.pageSize
        );
        await this.setState({
            searchKey: this.state.urlParams.get("key"),
            resultKey:
                "search_products" + "_" + this.state.urlParams.toString(),
        });
        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles}>
                    <PH1>Product Gallery</PH1>
                    <PBox className="product-gallery-search">
                        <Input.Search
                            placeholder="Search for products"
                            onSearch={(value) => {
                                window.location.href = `/product-gallery/1?key=${value}`;
                            }}
                        />
                    </PBox>
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchProducts !== 0 && (
                        <PH4>Search results for {this.state.searchKey}</PH4>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchProducts !== 0 && (
                        <PBox className="product-gallery-container">
                            {this.props.searchResults[
                                this.state.resultKey
                            ]?.searchProducts?.map((pattern, idx) => {
                                return (
                                    <PBox className="product-gallery-image">
                                        <PImg
                                            src={pattern.src}
                                            alt="patternful ai"
                                        />
                                        <PBox className="product-gallery-share-buttons">
                                            <TwitterShareButton
                                                url={String(
                                                    window.location.origin
                                                )}
                                            >
                                                <XIcon size={32} round />
                                            </TwitterShareButton>
                                            <PinterestShareButton
                                                url={String(
                                                    window.location.origin
                                                )}
                                                media={pattern.src}
                                            >
                                                <PinterestIcon
                                                    size={32}
                                                    round
                                                />
                                            </PinterestShareButton>
                                        </PBox>
                                        <PBox className="product-gallery-external-link">
                                            <PLink
                                                target="_blank"
                                                href={pattern.external_link}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="#000000"
                                                    viewBox="0 0 256 256"
                                                >
                                                    <path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path>
                                                </svg>
                                            </PLink>
                                        </PBox>
                                    </PBox>
                                );
                            })}
                        </PBox>
                    )}

                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchProducts === 0 && (
                        <PH4>No Results Found.</PH4>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchProducts !== 0 && (
                        <PBox className="product-gallery-pagination">
                            <Pagination
                                defaultCurrent={this.state.page}
                                total={parseInt(
                                    this.props.searchResults[
                                        this.state.resultKey
                                    ]?.totalSearchProducts
                                )}
                                pageSize={this.state.pageSize}
                                showSizeChanger={false}
                                showQuickJumper={true}
                                onChange={async (page) => {
                                    window.location.href = `/product-gallery/${page}?${this.state.urlParams.toString()}`;
                                }}
                            />
                        </PBox>
                    )}
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    padding: "0 1.5rem",
    maxWidth: "80rem",
    margin: "2rem auto",
    "@bp2": { marginTop: "4rem" },
    "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    "& h1": { textAlign: "center", marginBottom: "3rem" },
    "& .product-gallery-search": {
        marginBottom: "3rem",
    },
    "& .product-gallery-container": {
        margin: "0 auto 6rem auto",
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "@bp2": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
    "& .product-gallery-image": {
        position: "relative",
        overflow: "hidden",
        "& img": {
            width: "100%",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
        },
        "& .product-gallery-share-buttons": {
            position: "absolute",
            left: "1rem",
            top: "1rem",
            "& button": { marginRight: "0.5rem" },
            opacity: "0",
            visibility: "hidden",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
        },
        "& .product-gallery-external-link": {
            position: "absolute",
            right: "1rem",
            top: "1rem",
            opacity: "0",
            visibility: "hidden",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            borderRadius: "50%",
            padding: "0.3rem",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            "& svg": { fill: "#fff" },
        },
        "& .product-gallery-prompt": {
            position: "absolute",
            left: "0",
            bottom: "0",
            padding: "0 1rem",
            margin: "0",
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            opacity: "0",
            visibility: "hidden",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            "& p": {
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
                display: "-webkit-box",
            },
        },
    },
    "& .product-gallery-image:hover": {
        "& img": { transform: "scale(1.1)" },
        "& .product-gallery-share-buttons": {
            opacity: "1",
            visibility: "visible",
        },
        "& .product-gallery-external-link": {
            opacity: "1",
            visibility: "visible",
        },
        "& .product-gallery-prompt": { opacity: "1", visibility: "visible" },
    },
    "& .product-gallery-pagination": {
        display: "flex",
        justifyContent: "center",
        marginBottom: "6rem",
    },
};

function mapStateToProps(state) {
    return { searchResults: state.patternReducer?.fetchSearchProducts };
}

export default connect(mapStateToProps, { fetchSearchProducts })(
    withRouter(ProductGallery)
);
