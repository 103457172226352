import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
    PImg,
} from "../../theme/BaseTheme";

export const TeeFor = () => {
    return (
        <PSection css={styles}>
            <PBox className="sell-for">
                <PBox className="sell-for-container">
                    <PText className="sell-for-subtitle">
                        Applicable Across Multiple Uses
                    </PText>
                    <PH1 className="sell-for-title">
                        Generate Designs For Apparels
                    </PH1>

                    <PBox className="sell-for-wrapper">
                        <PBox className="sell-for-item">
                            <PImg
                                src={"/images/tee/tee.webp"}
                                alt="patternful ai"
                            />
                            <PText>Tees</PText>
                        </PBox>
                        <PBox className="sell-for-item">
                            <PImg
                                src={"/images/tee/sweatshirt.webp"}
                                alt="patternful ai"
                            />
                            <PText>Sweatshirts</PText>
                        </PBox>
                        <PBox className="sell-for-item">
                            <PImg
                                src={"/images/tee/hoodie.webp"}
                                alt="patternful ai"
                            />
                            <PText>Hoodies</PText>
                        </PBox>
                        <PBox className="sell-for-item">
                            <PImg
                                src={"/images/tee/tote.webp"}
                                alt="patternful ai"
                            />
                            <PText>Tote Bags</PText>
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-for": {
        padding: "0 1.5rem 0.5rem 1.5rem",
        maxWidth: "80rem",
        margin: "4rem auto",
        "@bp2": { marginTop: "8rem" },
        "@bp4": {
            paddingLeft: "4rem",
            paddingRight: "4rem",
        },
    },
    "& .sell-for-container": {
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
    },
    "& .sell-for-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .sell-for-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.5rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "3rem" },
    },
    "& .sell-for-wrapper": {
        position: "relative",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        maxWidth: "80rem",
        marginTop: "4rem",
        marginBottom: "4rem",
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "hidden",
        display: "grid",
        maxWidth: "42rem",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gap: "1rem",
        "@bp4": {
            //paddingLeft: "2rem",
            //paddingRight: "2rem",
            maxWidth: "none",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "4rem",
            marginBottom: "4rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
    },
    "& .sell-for-item": {
        position: "relative",
        borderRadius: "0.5rem",
        overflow: "hidden",
        "-webkit-transition": "all .5s ease",
        "-moz-transition": "all .5s ease",
        transition: "all .5s ease",
        "& img": {
            width: "100%",
            aspectRatio: "1/1",
            objectPosition: "center",
            objectFit: "cover",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
        },
        "& img:hover": {
            transform: "scale(1.1)",
        },
        "& p": {
            textAlign: "center",
            position: "absolute",
            bottom: "0",
            margin: "0",
            width: "100%",
            color: "#fff",
            fontWeight: "700",
            fontSize: "1.25rem",
            padding: "1rem 0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
    },
};
