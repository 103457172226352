import { PH1, PH3, PLink, PText, PBox, PUl, PLi } from "../../theme/BaseTheme";
import { ErrorLayout } from "./ErrorLayout";

export const Error404 = (props) => {
    return (
        <ErrorLayout>
            <PBox css={styles} className="error-404">
                <PBox className="error-404-title">
                    <PText className="error-404-404">404</PText>
                    <PH1>This page does not exist</PH1>
                    <PText className="error-404-desc">
                        Sorry, we couldn't find the page you're looking for.
                    </PText>
                </PBox>
                <PBox className="error-404-popular-pages">
                    <PUl>
                        <PLi
                            onClick={() => {
                                props.history.push("/pattern-gallery/1");
                            }}
                        >
                            <PBox className="error-404-page-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M216,40H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V184h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM72,56H216v62.75l-10.07-10.06a16,16,0,0,0-22.63,0l-20,20-44-44a16,16,0,0,0-22.62,0L72,109.37ZM184,200H40V88H56v80a16,16,0,0,0,16,16H184Zm32-32H72V132l36-36,49.66,49.66a8,8,0,0,0,11.31,0L194.63,120,216,141.38V168ZM160,84a12,12,0,1,1,12,12A12,12,0,0,1,160,84Z"></path>
                                </svg>
                            </PBox>
                            <PBox className="error-404-page-desc">
                                <PH3>Pattern Gallery</PH3>
                                <PText>
                                    Discover patterns generated at
                                    Patternful.ai.
                                </PText>
                            </PBox>
                            <PBox className="error-404-page-arrow">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="nz sb axp"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </PBox>
                        </PLi>
                        <PLi
                            onClick={() => {
                                props.history.push("/product-gallery/1");
                            }}
                        >
                            <PBox className="error-404-page-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M168,96H88a40,40,0,0,0-40,40v8a40,40,0,0,0,40,40h80a40,40,0,0,0,40-40v-8A40,40,0,0,0,168,96Zm24,48a24,24,0,0,1-24,24H88a24,24,0,0,1-24-24v-8a24,24,0,0,1,24-24h80a24,24,0,0,1,24,24ZM208,32a32.06,32.06,0,0,0-31,24H79a32,32,0,0,0-63,8v80a72.08,72.08,0,0,0,72,72h80a72.08,72.08,0,0,0,72-72V64A32,32,0,0,0,208,32Zm16,112a56.06,56.06,0,0,1-56,56H88a56.06,56.06,0,0,1-56-56V64a16,16,0,0,1,32,0,8,8,0,0,0,8,8H184a8,8,0,0,0,8-8,16,16,0,0,1,32,0Zm-120-4a12,12,0,1,1-12-12A12,12,0,0,1,104,140Zm72,0a12,12,0,1,1-12-12A12,12,0,0,1,176,140Z"></path>
                                </svg>
                            </PBox>
                            <PBox className="error-404-page-desc">
                                <PH3>Product Gallery</PH3>
                                <PText>
                                    Discover products created at Patternful.ai.
                                </PText>
                            </PBox>
                            <PBox className="error-404-page-arrow">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="nz sb axp"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </PBox>
                        </PLi>
                        <PLi
                            onClick={() => {
                                props.history.push("/pricing");
                            }}
                        >
                            <PBox className="error-404-page-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M243.31,136,144,36.69A15.86,15.86,0,0,0,132.69,32H40a8,8,0,0,0-8,8v92.69A15.86,15.86,0,0,0,36.69,144L136,243.31a16,16,0,0,0,22.63,0l84.68-84.68a16,16,0,0,0,0-22.63Zm-96,96L48,132.69V48h84.69L232,147.31ZM96,84A12,12,0,1,1,84,72,12,12,0,0,1,96,84Z"></path>
                                </svg>
                            </PBox>
                            <PBox className="error-404-page-desc">
                                <PH3>Pricing</PH3>
                                <PText>
                                    Check the plans Patternful.ai provide.
                                </PText>
                            </PBox>
                            <PBox className="error-404-page-arrow">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="nz sb axp"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </PBox>
                        </PLi>
                    </PUl>
                </PBox>
                <PBox className="error-404-home-link">
                    <PText>
                        Go back to <PLink href="/">Home</PLink>
                    </PText>
                </PBox>
            </PBox>
        </ErrorLayout>
    );
};

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "600px",
    backgroundColor: "#fff",
    padding: "1rem 2rem 2rem 2rem",
    "& .error-404-title": {
        textAlign: "center",
    },
    "& .error-404-404": {
        fontSize: "1.2rem",
        fontWeight: "600",
        lineHeight: "2rem",
        color: "$colors$primary",
    },
    "& h1": {
        fontSize: "2rem",
        "@bp2": { fontSize: "3rem" },
        lineHeight: "1",
        fontWeight: "700",
        letterSpacing: "-0.025em",
        marginTop: "1rem",
    },
    "& .error-404-desc": {
        lineHeight: "2rem",
        fontSize: "1.125rem",
        marginTop: "1.5rem",
        color: "rgb(75 85 99)",
    },
    "& .error-404-popular-pages": {
        marginTop: "3rem",
        maxWidth: "28rem",
        "@bp2": { width: "32rem" },
        display: "flow-root",
        marginLeft: "auto",
        marginRight: "auto",
        "& ul": {
            borderTop: "1px solid $colors$borderColor",
            listStyle: "none",
            margin: "0",
            padding: "0",
        },
        "& li": {
            paddingTop: "1.5rem",
            paddingBottom: "0.8rem",
            columnGap: "1.5rem",
            display: "flex",
            position: "relative",
            borderBottom: "1px solid $colors$borderColor",
            cursor: "pointer",
        },
    },
    "& .error-404-page-desc": {
        flex: "1 1 auto",
        "& h3": {
            lineHeight: "1.5rem",
            fontWeight: "600",
            fontSize: "0.875rem",
            margin: "0",
        },
        "& p": {
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontSize: "0.875rem",
            marginTop: "0.5rem",
        },
    },
    "& .error-404-page-icon": {
        borderRadius: "0.5rem",
        justifyContent: "center",
        alignItems: "center",
        flex: "0 0 auto",
        width: "2.5rem",
        height: "2.5rem",
        display: "flex",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
        "& svg": {
            width: "1.5rem",
            height: "1.5rem",
            color: "$colors$primary700",
            display: "block",
            verticalAlign: "middle",
        },
    },
    "& .error-404-page-arrow": {
        alignSelf: "center",
        flex: "0 0 auto",
        "& svg": {
            color: "rgb(156 163 175)",
            width: "1.25rem",
            height: "1.25rem",
            display: "block",
            verticalAlign: "middle",
        },
    },
    "& .error-404-home-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};
