import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PH1, PH2, PH4 } from "../../theme/BaseTheme";
import { Input, Pagination } from "antd";
import TeeDesignCard from "../../components/app/TeeDesignCard";
import { fetchUserTeeDesigns } from "../../../actions/tee";

class TeeDesigns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            searchKey: "",
            page: parseInt(this.props.match.params.id),
            pageSize: 20,
            urlParams: new URLSearchParams(window.location.search),
            resultKey: "",
        };
    }
    async componentDidMount() {
        await this.props.fetchUserTeeDesigns(
            this.state.urlParams,
            (this.state.page - 1) * this.state.pageSize,
            this.state.pageSize
        );
        await this.setState({
            searchKey: this.state.urlParams.get("key"),
            resultKey:
                "user_tee_designs" + "_" + this.state.urlParams.toString(),
        });
        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>User Tee Designs</title>
                    <meta
                        name="description"
                        content="Transform your ideas into wearable art with our AI-powered t-shirt design generator."
                    />
                    <meta
                        name="keywords"
                        content="Tee Design Generator, Tee Design AI, Tshirt Design Generator AI, Tshirt Design Maker, Tee Design Generator APP, Tshirt Design Online Generator, Tote Bag Design Generator, Tote Bag Design Generator AI, Print On Demand, Tshirt Design, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PH2>My Tee Designs</PH2>
                    <PBox className="patterns-search">
                        <Input.Search
                            placeholder="Search for tee designs"
                            onSearch={(value) => {
                                window.location.href = `/tees/1?key=${value}`;
                            }}
                        />
                    </PBox>
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchTeeDesigns !== 0 && (
                        <PH4>Search results for {this.state.searchKey}</PH4>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchTeeDesigns === 0 && (
                        <PH4>No Results Found.</PH4>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchTeeDesigns !== 0 && (
                        <PBox className="patterns-container">
                            {this.props.searchResults[
                                this.state.resultKey
                            ]?.searchTeeDesigns?.map((tee, idx) => {
                                return (
                                    <TeeDesignCard
                                        pattern_uuid={tee.pattern_uuid}
                                        image_url={tee.src}
                                        prompt={tee.prompt}
                                    />
                                );
                            })}
                        </PBox>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchTeeDesigns !== 0 && (
                        <PBox className="pattern-gallery-pagination">
                            <Pagination
                                defaultCurrent={this.state.page}
                                total={parseInt(
                                    this.props.searchResults[
                                        this.state.resultKey
                                    ]?.totalSearchTeeDesigns
                                )}
                                pageSize={this.state.pageSize}
                                showSizeChanger={false}
                                showQuickJumper={true}
                                onChange={async (page) => {
                                    window.location.href = `/tees/${page}?${this.state.urlParams.toString()}`;
                                }}
                            />
                        </PBox>
                    )}
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    padding: "0 1.5rem",
    maxWidth: "80rem",
    margin: "2rem auto",
    "@bp2": { marginTop: "4rem" },
    "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    "& h2": { marginBottom: "3rem" },
    "& .patterns-search": {
        marginBottom: "3rem",
    },
    "& .patterns-container": {
        margin: "0 auto 6rem auto",
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "@bp2": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
    "& .ant-card-meta-description": { color: "#1b1b1b" },
    "& .pattern-gallery-pagination": {
        display: "flex",
        justifyContent: "center",
        marginBottom: "6rem",
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth.userAccount,
        searchResults: state.teeReducer?.fetchUserTeeDesigns,
    };
}

export default connect(mapStateToProps, { fetchUserTeeDesigns })(
    withRouter(TeeDesigns)
);
