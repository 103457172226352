import {
    PSection,
    PBox,
    PContainer,
    PH2,
    PH3,
    PH4,
    PText,
    PUl,
    PLi,
    POl,
} from "../../theme/BaseTheme";
import { Layout } from "./Layout";

export const Privacy = (props) => {
    return (
        <Layout>
            <PSection css={styles}>
                <PBox className="privacy">
                    <PBox className="privacy-title">
                        <PH2>Privacy Policy</PH2>
                    </PBox>
                    <PBox>
                        <PText>
                            <i>Last updated: March 9th, 2024</i>
                        </PText>
                        <PH3>1. Introduction</PH3>
                        <PText>Welcome to Patternful.ai.</PText>
                        <PText>
                            Patternful.ai (“<b>us</b>”, “<b>we</b>”, or “
                            <b>our</b>
                            ”) operates{" "}
                            <a href="https://www.patternful.ai">
                                https://www.patternful.ai
                            </a>{" "}
                            (hereinafter referred to as “<b>Service</b>”).
                        </PText>
                        <PText>
                            Our Privacy Policy governs your visit to{" "}
                            <a href="https://www.patternful.ai">
                                https://www.patternful.ai
                            </a>
                            , and explains how we collect, safeguard and
                            disclose information that results from your use of
                            our Service.
                        </PText>
                        <PText>
                            We use your data to provide and improve Service. By
                            using Service, you agree to the collection and use
                            of information in accordance with this policy.
                            Unless otherwise defined in this Privacy Policy, the
                            terms used in this Privacy Policy have the same
                            meanings as in our Terms and Conditions.
                        </PText>
                        <PText>
                            Our Terms and Conditions (“<b>Terms</b>”) govern all
                            use of our Service and together with the Privacy
                            Policy constitutes your agreement with us (“
                            <b>agreement</b>”).
                        </PText>
                        <PH3>2. Definitions</PH3>
                        <PText>
                            <b>SERVICE</b> means the{" "}
                            <a href="https://www.patternful.ai">
                                https://www.patternful.ai
                            </a>
                            website operated by Patternful.ai.
                        </PText>
                        <PText>
                            <b>PERSONAL DATA</b> means data about a living
                            individual who can be identified from those data (or
                            from those and other information either in our
                            possession or likely to come into our possession).
                        </PText>
                        <PText>
                            <b>USAGE DATA</b> is data collected automatically
                            either generated by the use of Service or from
                            Service infrastructure itself (for example, the
                            duration of a page visit).
                        </PText>
                        <PText>
                            <b>COOKIES</b> are small files stored on your device
                            (computer or mobile device).
                        </PText>
                        <PText>
                            <b>DATA CONTROLLER</b> means a natural or legal
                            person who (either alone or jointly or in common
                            with other persons) determines the purposes for
                            which and the manner in which any personal data are,
                            or are to be, processed. For the purpose of this
                            Privacy Policy, we are a Data Controller of your
                            data.
                        </PText>
                        <PText>
                            <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means
                            any natural or legal person who processes the data
                            on behalf of the Data Controller. We may use the
                            services of various Service Providers in order to
                            process your data more effectively.
                        </PText>
                        <PText>
                            <b>DATA SUBJECT</b> is any living individual who is
                            the subject of Personal Data.
                        </PText>
                        <PText>
                            <b>THE USER</b> is the individual using our Service.
                            The User corresponds to the Data Subject, who is the
                            subject of Personal Data.
                        </PText>
                        <PH3>3. Information Collection and Use</PH3>
                        <PText>
                            We collect several different types of information
                            for various purposes to provide and improve our
                            Service to you.
                        </PText>
                        <PH3>4. Types of Data Collected</PH3>
                        <PText>
                            <b>Personal Data</b>
                        </PText>
                        <PText>
                            While using our Service, we may ask you to provide
                            us with certain personally identifiable information
                            that can be used to contact or identify you (“
                            <b>Personal Data</b>”). Personally identifiable
                            information may include, but is not limited to:
                        </PText>
                        <PUl>
                            <PLi>Email Address</PLi>
                            <PLi>First Name and Last Name</PLi>
                            <PLi>Cookies and Usage Data</PLi>
                        </PUl>
                        <PText>
                            We may use your Personal Data to contact you with
                            newsletters, marketing or promotional materials and
                            other information that may be of interest to you.
                            You may opt out of receiving any, or all, of these
                            communications from us by following the unsubscribe
                            link or by emailing at support@phair.io.
                        </PText>
                        <PText>
                            <b>Usage Data</b>
                        </PText>
                        <PText>
                            We may also collect information that your browser
                            sends whenever you visit our Service or when you
                            access Service by or through a mobile device (“
                            <b>Usage Data</b>”).
                        </PText>
                        <PText>
                            This Usage Data may include information such as your
                            computer's Internet Protocol address (e.g. IP
                            address), browser type, browser version, the pages
                            of our Service that you visit, the time and date of
                            your visit, the time spent on those pages, unique
                            device identifiers and other diagnostic data.
                        </PText>
                        <PText>
                            <b>Tracking Cookies Data</b>
                        </PText>
                        <PText>
                            We use cookies and similar tracking technologies to
                            track the activity on our Service and we hold
                            certain information.
                        </PText>
                        <PText>
                            Cookies are files with a small amount of data which
                            may include an anonymous unique identifier. Cookies
                            are sent to your browser from a website and stored
                            on your device. Other tracking technologies are also
                            used such as beacons, tags and scripts to collect
                            and track information and to improve and analyze our
                            Service.
                        </PText>
                        <PText>
                            You can instruct your browser to refuse all cookies
                            or to indicate when a cookie is being sent. However,
                            if you do not accept cookies, you may not be able to
                            use some portions of our Service.
                        </PText>
                        <PText>Examples of Cookies we use:</PText>
                        <PUl>
                            <PLi>
                                <b>Session Cookies:</b> We use Session Cookies
                                to operate our Service.
                            </PLi>
                            <PLi>
                                <b>Preference Cookies:</b> We use Preference
                                Cookies to remember your preferences and various
                                settings.
                            </PLi>
                            <PLi>
                                <b>Security Cookies:</b> We use Security Cookies
                                for security purposes.
                            </PLi>
                            <PLi>
                                <b>Advertising Cookies:</b> Advertising Cookies
                                are used to serve you with advertisements that
                                may be relevant to you and your interests.
                            </PLi>
                        </PUl>
                        <PH3>5. Use of Data</PH3>
                        <PText>
                            Patternful.ai uses the collected data for various
                            purposes:
                        </PText>
                        <PUl>
                            <PLi>to provide and maintain our Service;</PLi>
                            <PLi>
                                to notify you about changes to our Service;
                            </PLi>
                            <PLi>
                                to allow you to participate in interactive
                                features of our Service when you choose to do
                                so;
                            </PLi>
                            <PLi>to provide customer support;</PLi>
                            <PLi>
                                to gather analysis or valuable information so
                                that we can improve our Service;
                            </PLi>
                            <PLi>to monitor the usage of our Service;</PLi>
                            <PLi>
                                to detect, prevent and address technical issues;
                            </PLi>
                            <PLi>
                                to fulfill any other purpose for which you
                                provide it;
                            </PLi>
                            <PLi>
                                to carry out our obligations and enforce our
                                rights arising from any contracts entered into
                                between you and us, including for billing and
                                collection;
                            </PLi>
                            <PLi>
                                to provide you with notices about your account
                                and/or subscription, including expiration and
                                renewal notices, email-instructions, etc.;
                            </PLi>
                            <PLi>
                                to provide you with news, special offers and
                                general information about other goods, services
                                and events which we offer that are similar to
                                those that you have already purchased or
                                enquired about unless you have opted not to
                                receive such information;
                            </PLi>
                            <PLi>
                                in any other way we may describe when you
                                provide the information;
                            </PLi>
                            <PLi>for any other purpose with your consent.</PLi>
                        </PUl>
                        <PH3>6. Retention of Data</PH3>
                        <PText>
                            We will retain your Personal Data only for as long
                            as is necessary for the purposes set out in this
                            Privacy Policy. We will retain and use your Personal
                            Data to the extent necessary to comply with our
                            legal obligations (for example, if we are required
                            to retain your data to comply with applicable laws),
                            resolve disputes, and enforce our legal agreements
                            and policies.
                        </PText>
                        <PText>
                            We will also retain Usage Data for internal analysis
                            purposes. Usage Data is generally retained for a
                            shorter period, except when this data is used to
                            strengthen the security or to improve the
                            functionality of our Service, or we are legally
                            obligated to retain this data for longer time
                            periods.
                        </PText>
                        <PH3>7. Transfer of Data</PH3>
                        <PText>
                            Your information, including Personal Data, may be
                            transferred to - and maintained on - computers
                            located outside of your state, province, country or
                            other governmental jurisdiction where the data
                            protection laws may differ from those of your
                            jurisdiction.
                        </PText>
                        <PText>
                            If you are located outside United States and choose
                            to provide information to us, please note that we
                            transfer the data, including Personal Data, to
                            United States and process it there.
                        </PText>
                        <PText>
                            Your consent to this Privacy Policy followed by your
                            submission of such information represents your
                            agreement to that transfer.
                        </PText>
                        <PText>
                            Patternful.ai will take all the steps reasonably
                            necessary to ensure that your data is treated
                            securely and in accordance with this Privacy Policy
                            and no transfer of your Personal Data will take
                            place to an organisation or a country unless there
                            are adequate controls in place including the
                            security of your data and other personal
                            information.
                        </PText>
                        <PH3>8. Security of Data</PH3>
                        <PText>
                            The security of your data is important to us but
                            remember that no method of transmission over the
                            Internet or method of electronic storage is 100%
                            secure. While we strive to use commercially
                            acceptable means to protect your Personal Data, we
                            cannot guarantee its absolute security.
                        </PText>
                        <PH3>
                            9. Your Data Protection Rights Under General Data
                            Protection Regulation (GDPR)
                        </PH3>
                        <PText>
                            If you are a resident of the European Union (EU) and
                            European Economic Area (EEA), you have certain data
                            protection rights, covered by GDPR. - See more at
                            <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                                https://eur-lex.europa.eu/eli/reg/2016/679/oj
                            </a>{" "}
                            We aim to take reasonable steps to allow you to
                            correct, amend, delete, or limit the use of your
                            Personal Data.
                        </PText>
                        <PText>
                            If you wish to be informed what Personal Data we
                            hold about you and if you want it to be removed from
                            our systems, please email us at support@phair.io.
                        </PText>
                        <PText>
                            In certain circumstances, you have the following
                            data protection rights:
                        </PText>
                        <PUl>
                            <PLi>
                                the right to access, update or to delete the
                                information we have on you;
                            </PLi>
                            <PLi>
                                the right of rectification. You have the right
                                to have your information rectified if that
                                information is inaccurate or incomplete;
                            </PLi>
                            <PLi>
                                the right to object. You have the right to
                                object to our processing of your Personal Data;
                            </PLi>
                            <PLi>
                                the right of restriction. You have the right to
                                request that we restrict the processing of your
                                personal information;
                            </PLi>
                            <PLi>
                                the right to data portability. You have the
                                right to be provided with a copy of your
                                Personal Data in a structured, machine-readable
                                and commonly used format;
                            </PLi>
                            <PLi>
                                the right to withdraw consent. You also have the
                                right to withdraw your consent at any time where
                                we rely on your consent to process your personal
                                information;
                            </PLi>
                        </PUl>
                        <PText>
                            Please note that we may ask you to verify your
                            identity before responding to such requests. Please
                            note, we may not be able to provide Service without
                            some necessary data.
                        </PText>
                        <PText>
                            You have the right to complain to a Data Protection
                            Authority about our collection and use of your
                            Personal Data. For more information, please contact
                            your local data protection authority in the European
                            Economic Area (EEA).
                        </PText>
                        <PH3>
                            10. Your Data Protection Rights under the California
                            Privacy Protection Act (CalOPPA)
                        </PH3>
                        <PText>
                            CalOPPA is the first state law in the nation to
                            require commercial websites and online services to
                            post a privacy policy. The law's reach stretches
                            well beyond California to require a person or
                            company in the United States (and conceivable the
                            world) that operates websites collecting personally
                            identifiable information from California consumers
                            to post a conspicuous privacy policy on its website
                            stating exactly the information being collected and
                            those individuals with whom it is being shared, and
                            to comply with this policy. - See more at:
                            <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
                                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                            </a>
                        </PText>
                        <PText>
                            According to CalOPPA we agree to the following:
                        </PText>
                        <PUl>
                            <PLi>users can visit our site anonymously;</PLi>
                            <PLi>
                                our Privacy Policy link includes the word
                                “Privacy”, and can easily be found on the page
                                specified above on the home page of our website;
                            </PLi>
                            <PLi>
                                users will be notified of any privacy policy
                                changes on our Privacy Policy Page;
                            </PLi>
                            <PLi>
                                users are able to change their personal
                                information by emailing us at support@phair.io.
                            </PLi>
                        </PUl>
                        <PText>Our Policy on “Do Not Track” Signals:</PText>
                        <PText>
                            We honor Do Not Track signals and do not track,
                            plant cookies, or use advertising when a Do Not
                            Track browser mechanism is in place. Do Not Track is
                            a preference you can set in your web browser to
                            inform websites that you do not want to be tracked.
                        </PText>
                        <PText>
                            You can enable or disable Do Not Track by visiting
                            the Preferences or Settings page of your web
                            browser.
                        </PText>
                        <PH3>11. Service Providers</PH3>
                        <PText>
                            We may employ third party companies and individuals
                            to facilitate our Service (“<b>Service Providers</b>
                            ”), provide Service on our behalf, perform
                            Service-related services or assist us in analyzing
                            how our Service is used.
                        </PText>
                        <PText>
                            These third parties have access to your Personal
                            Data only to perform these tasks on our behalf and
                            are obligated not to disclose or use it for any
                            other purpose.
                        </PText>
                        <PH3>12. Analytics</PH3>
                        <PText>
                            We may use third-party Service Providers to monitor
                            and analyze the use of our Service.
                        </PText>
                        <PText>
                            <b>Google Analytics</b>
                        </PText>
                        <PText>
                            Google Analytics is a web analytics service offered
                            by Google that tracks and reports website traffic.
                            Google uses the data collected to track and monitor
                            the use of our Service. This data is shared with
                            other Google services. Google may use the collected
                            data to contextualize and personalize the ads of its
                            own advertising network.
                        </PText>
                        <PText>
                            For more information on the privacy practices of
                            Google, please visit the Google Privacy Terms web
                            page:{" "}
                            <a href="https://policies.google.com/privacy?hl=en">
                                https://policies.google.com/privacy?hl=en
                            </a>{" "}
                            We also encourage you to review the Google's policy
                            for safeguarding your data:
                            <a href="https://support.google.com/analytics/answer/6004245">
                                https://support.google.com/analytics/answer/6004245
                            </a>
                            .
                        </PText>
                        <PH3>13. Payments</PH3>
                        <PText>
                            We may provide paid products and/or services within
                            Service. In that case, we use third-party services
                            for payment processing (e.g. payment processors).
                        </PText>
                        <PText>
                            We will not store or collect your payment card
                            details. That information is provided directly to
                            our third-party payment processors whose use of your
                            personal information is governed by their Privacy
                            Policy. These payment processors adhere to the
                            standards set by PCI-DSS as managed by the PCI
                            Security Standards Council, which is a joint effort
                            of brands like Visa, Mastercard, American Express
                            and Discover. PCI-DSS requirements help ensure the
                            secure handling of payment information.
                        </PText>
                        <PText>
                            The payment processors we work with are: Stripe.
                            Their Privacy Policy can be viewed at:
                            <a href="https://stripe.com/us/privacy">
                                https://stripe.com/us/privacy
                            </a>
                        </PText>
                        <PH3>14. Links to Other Sites</PH3>
                        <PText>
                            Our Service may contain links to other sites that
                            are not operated by us. If you click a third party
                            link, you will be directed to that third party's
                            site. We strongly advise you to review the Privacy
                            Policy of every site you visit.
                        </PText>
                        <PText>
                            We have no control over and assume no responsibility
                            for the content, privacy policies or practices of
                            any third party sites or services.
                        </PText>
                        <PH3>15. Children's Privacy</PH3>
                        <PText>
                            Our Services are not intended for use by children
                            under the age of 18 (“<b>Child</b>” or “
                            <b>Children</b>”). We do not knowingly collect
                            personally identifiable information from Children
                            under 18. If you become aware that a Child has
                            provided us with Personal Data, please contact us.
                            If we become aware that we have collected Personal
                            Data from Children without verification of parental
                            consent, we take steps to remove that information
                            from our servers.
                        </PText>
                        <PH3>16. Changes to This Privacy Policy</PH3>
                        <PText>
                            We may update our Privacy Policy from time to time.
                            We will notify you of any changes by posting the new
                            Privacy Policy on this page.
                        </PText>
                        <PText>
                            We will let you know via email and/or a prominent
                            notice on our Service, prior to the change becoming
                            effective and update “effective date” at the top of
                            this Privacy Policy.
                        </PText>
                        <PText>
                            You are advised to review this Privacy Policy
                            periodically for any changes. Changes to this
                            Privacy Policy are effective when they are posted on
                            this page.
                        </PText>
                        <PH3>17. Contact Us</PH3>
                        <PText>
                            If you have any questions about this Privacy Policy,
                            please contact us: By email: support@phair.io.
                        </PText>
                    </PBox>
                </PBox>
            </PSection>
        </Layout>
    );
};

const styles = {
    color: "rgb(75 85 99)",
    lineHeight: "1.8",
    maxWidth: "56rem",
    padding: "0 2rem",
    margin: "4rem auto",
    "& .privacy": {
        marginBottom: "8rem",
    },
    "& .privacy-title": {
        margin: "4rem 0",
        "& h2": {
            lineHeight: "2.5rem",
            fontWeight: "700",
            fontSize: "2.5rem",
            color: "rgb(17 24 39)",
        },
    },
    "& a": {
        color: "$colors$primary",
        textDecoration: "none",
        fontWeight: "500",
    },
};
