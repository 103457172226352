import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PatternHow } from "../../sections/pattern/PatternHow";
import { PatternWhy } from "../../sections/pattern/PatternWhy";
import { PatternFor } from "../../sections/pattern/PatternFor";
import { PatternWhyMore } from "../../sections/pattern/PatternWhyMore";
import { PBox } from "../../theme/BaseTheme";
import { SellFaq } from "../../sections/SellFaq";
import { PatternBanner } from "../../sections/pattern/PatternBanner";
import { CallToAction } from "../../sections/CallToAction";
import { TeeBanner } from "../../sections/tee_design/TeeBanner";
import { TeeFor } from "../../sections/tee_design/TeeFor";
import { TeeHow } from "../../sections/tee_design/TeeHow";

const td_faq_data = [
    {
        question: "What is the tee design generator?",
        answer: "Our Tee Design Generator is an online tool or software that enables users to effortlessly create custom t-shirt designs. Simply input your design ideas as a prompt, choose between a round or square design, and our AI will generate graphics or designs ready to be applied and printed on t-shirts.",
    },
    {
        question:
            "Can I use generated designs for personal and commercial purposes?",
        answer: "Absolutely! You can use the designs created with the tee design generator in any way you like—whether for personal use or commercial purposes. The designs aren't limited to t-shirts; feel free to apply them to other items like tote bags as well.",
    },
    {
        question:
            "Are the designs created with a Tee Design Generator ready for printing?",
        answer: "Yes, we offer designs in webp, png, and jpeg formats, along with various DPI options. You can also convert the image to a vector format or upscale the design to higher resolution. Additional download formats will be available soon.",
    },
];

class TeeDesignLanding extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Tee Designs Generator</title>
                    <meta
                        name="description"
                        content="Transform your ideas into wearable art with our AI-powered t-shirt design generator."
                    />
                    <meta
                        name="keywords"
                        content="Tee Design Generator, Tee Design AI, Tshirt Design Generator AI, Tshirt Design Maker, Tee Design Generator APP, Tshirt Design Online Generator, Tote Bag Design Generator, Tote Bag Design Generator AI, Print On Demand, Tshirt Design, Patternful, Patternful AI"
                    />
                </Helmet>
                <TeeBanner user_uuid={this.props.userAccount?.uuid} />{" "}
                <TeeHow />
                <TeeFor />
                <CallToAction
                    title={
                        "Start Generating Your One-of-a-Kind Tees for Free Today"
                    }
                    subtitle={
                        "Ready to elevate your projects and business with Patternful.ai? Sign up and start generating your unique tees."
                    }
                    link={"/tee-design/app"}
                    cta={"Get Started"}
                />
                <SellFaq data={td_faq_data} />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(TeeDesignLanding));
