import { PBox, PSection, PText, PH1, PImg, PH2 } from "../theme/BaseTheme";

export const FeatureImageRight = (props) => {
    return (
        <PSection css={styles}>
            <PBox className="sell-why">
                <PBox className="sell-why-container">
                    <PBox className="sell-why-reasons-wrapper">
                        <PBox className="sell-why-reasons">
                            <PBox className="sell-why-reasons-item">
                                <PH2>{props.title}</PH2>
                                <PText>{props.description}</PText>
                            </PBox>
                        </PBox>
                        <PBox>
                            <PImg
                                alt="patternful ai"
                                className={"sell-why-reasons-img"}
                                src={props.image}
                            />
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    "& .sell-why": {
        padding: "0 1.5rem",
        maxWidth: "80rem",
        margin: "4rem auto",
        "@bp2": { marginTop: "6rem" },
        "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    },
    "& .sell-why-container": {
        position: "relative",
    },
    "& .sell-why-reasons-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        gap: "2rem",
        alignItems: "center",
        //maxWidth: "42rem",
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: "column",
        "@bp4": {
            flexDirection: "row",
            gap: "4rem",
            //maxWidth: "56rem",
        },
    },
    "& .sell-why-reasons-img": {
        objectFit: "cover",
        borderRadius: "1rem",
        maxWidth: "48rem",
        margin: "1.5rem auto",
        //border: "1px solid $colors$borderColor",
    },
    "& .sell-why-reasons": {
        width: "100%",
        //"@bp4": { flex: "1 1 auto", maxWidth: "36rem" },
    },
    "& .sell-why-reasons-item": {
        padding: "0.5rem 1rem",
        margin: "0 2rem",
        "& h2": {
            color: "rgb(17 24 39)",
            fontWeight: "600",
            fontSize: "2.5rem",
            lineHeight: "2.8rem",
            width: "100%",
        },
        "& p": {
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontSize: "1rem",
            width: "100%",
            marginTop: "0.5rem",
        },
        "@bp4": { margin: "0" },
    },
};
