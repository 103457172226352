import { combineReducers } from "redux";

const generateTeeDesigns = (state = { generations: [] }, action) => {
    switch (action.type) {
        case "GENERATE_TEE_DESIGNS_SUCCESS":
            return {
                ...state,
                generations: [
                    {
                        isLoading: false,
                        isError: false,
                        isSuccess: true,
                        hash: action.payload.hash,
                        designs: action.payload.teeDesigns,
                    },
                    ...state.generations,
                ],
            };
        default:
            return state;
    }
};

const fetchSearchTeeDesigns = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_SEARCH_TEE_DESIGNS_SUCCESS":
            state[action.key] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                searchTeeDesigns: action.payload.searchResult,
                searchKey: action.payload.searchKey,
                totalSearchTeeDesigns: action.payload.totalSearchResult,
            };
        default:
            return state;
    }
};

const fetchUserTeeDesigns = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_USER_TEE_DESIGNS_SUCCESS":
            state[action.key] = {
                isLoading: false,
                isError: false,
                isSuccess: true,
                searchTeeDesigns: action.payload.searchResult,
                searchKey: action.payload.searchKey,
                totalSearchTeeDesigns: action.payload.totalSearchResult,
            };
        default:
            return state;
    }
};

export default combineReducers({
    generateTeeDesigns,
    fetchSearchTeeDesigns,
    fetchUserTeeDesigns,
});
