import React, { Fragment, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import Landing from "./views/pages/landing/Landing";
import { Contact } from "./views/pages/landing/Contact";
import { ContactConfirm } from "./views/pages/landing/ContactConfirm";

import { FAQs } from "./views/pages/terms/FAQs";
import { Licenses } from "./views/pages/terms/Licenses";
import { Privacy } from "./views/pages/terms/Privacy";
import { Terms } from "./views/pages/terms/Terms";
import Sitemap from "./views/pages/landing/Sitemap";

import { Error404 } from "./views/pages/error/Error404";
import { Error500 } from "./views/pages/error/Error500";

import SignIn from "./views/pages/authentication/SignIn";
import SignUp from "./views/pages/authentication/SignUp";
import SignOut from "./views/pages/authentication/SignOut";
import ResetPassword from "./views/pages/authentication/ResetPassword";
import ForgotPassword from "./views/pages/authentication/ForgotPassword";
import ForgotPasswordConfirm from "./views/pages/authentication/ForgotPasswordConfirm";
import RegistrationSuccess from "./views/pages/authentication/RegistrationSuccess";
import EmailNotVerified from "./views/pages/authentication/EmailNotVerified";
import EmailConfirmation from "./views/pages/authentication/EmailConfirmation";
import SessionExpired from "./views/pages/authentication/SessionExpired";

import Pricing from "./views/pages/landing/Pricing";
import App from "./views/pages/app/App";
import Design from "./views/pages/app/Design";
import Patterns from "./views/pages/app/Patterns";
import Subscription from "./views/pages/app/Subscription";
import PatternGallery from "./views/pages/landing/PatternGallery";
import ProductGallery from "./views/pages/landing/ProductGallery";
import MockupLanding from "./views/pages/landing/MockupLanding";
import MockupApp from "./views/pages/app/MockupApp";
import { CreatePatternsIsQuickAndEasy } from "./views/pages/blog/posts/CreatePatternsIsQuickAndEasy";
import { BlogHome } from "./views/pages/blog/BlogHome";
import { HowToAlignMultiplePatterns } from "./views/pages/blog/posts/HowToAlignMultiplePatterns";
import { EarnPassiveIncomeOnSociety6 } from "./views/pages/blog/posts/EarnPassiveIncomeOnSociety6";
import { HowToCreateMockupImages } from "./views/pages/blog/posts/HowToCreateMockupImages";
import { TopPlatformForSellingSeamlessPatterns } from "./views/pages/blog/posts/TopPlatformForSellingSeamlessPatterns";
import PatternLanding from "./views/pages/landing/PatternLanding";
import BgRemoverLanding from "./views/pages/landing/BgRemoverLanding";
import BgRemoverApp from "./views/pages/app/BgRemoverApp";
import BinaryMaskLanding from "./views/pages/landing/BinaryMaskLanding";
import BinaryMaskApp from "./views/pages/app/BinaryMaskApp";
import UpscalerLanding from "./views/pages/landing/UpscalerLanding";
import UpscalerApp from "./views/pages/app/UpscalerApp";
import ObjectRemoverLanding from "./views/pages/landing/ObjectRemoverLanding";
import ObjectRemoverApp from "./views/pages/app/ObjectRemoverApp";
import RemoveObjectWrapper from "./views/pages/app/RemoveObjectWrapper";
import TeeDesignGeneratorApp from "./views/pages/app/TeeDesignGeneratorApp";
import TeeDesignLanding from "./views/pages/landing/TeeDesignLanding";
import TeeDesignGallery from "./views/pages/landing/TeeDesignGallery";
import TeeDesigns from "./views/pages/app/TeeDesigns";

const UserRoute = (props) => {
    return props.isLoggedIn ? <Route {...props} /> : <Redirect to="/sign-in" />;
};

const ProRoute = (props) => {
    if (!props.isLoggedIn) {
        return <Redirect to="/sign-in" />;
    } else {
        if (!props.isProPlan) {
            return <Redirect to="/manage-subscription" />;
        } else {
            return <Route {...props} />;
        }
    }
};

const ScrollToTop = () => {
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, []);

    return null;
};

const AppRoutes = (props) => {
    return (
        <Fragment>
            <ScrollToTop />
            <Switch>
                {/* Landing */}
                <Route component={Landing} exact path="/" />
                <Route component={Pricing} exact path="/pricing" />
                <Route
                    component={PatternGallery}
                    exact
                    path="/pattern-gallery/:id"
                />
                <Route
                    component={TeeDesignGallery}
                    exact
                    path="/tee-design-gallery/:id"
                />
                <Route
                    component={ProductGallery}
                    exact
                    path="/product-gallery/:id"
                />
                <Route component={Contact} exact path="/contact" />
                <Route component={Sitemap} exact path="/sitemap" />
                <Route
                    component={ContactConfirm}
                    exact
                    path="/contact-confirm"
                />
                {/* Seamless Pattern */}
                <Route
                    component={PatternLanding}
                    exact
                    path="/seamless-pattern"
                />
                <UserRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    component={App}
                    exact
                    path="/seamless-pattern/app"
                />
                <UserRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    component={Design}
                    exact
                    path="/seamless-pattern/design/:pattern_uuid"
                />
                {/* Tee Design Generator */}
                <Route component={TeeDesignLanding} exact path="/tee-design" />
                <UserRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    component={TeeDesignGeneratorApp}
                    exact
                    path="/tee-design/app"
                />
                {/* Mockup */}
                <Route component={MockupLanding} exact path="/mockup" />
                <Route component={MockupApp} exact path="/mockup/app" />
                {/* Background Remover */}
                <Route
                    component={BgRemoverLanding}
                    exact
                    path="/background-remover"
                />
                <Route
                    component={BgRemoverApp}
                    exact
                    path="/background-remover/app"
                />
                {/* Binary Mask */}
                <Route
                    component={BinaryMaskLanding}
                    exact
                    path="/binary-mask-generator"
                />
                <Route
                    component={BinaryMaskApp}
                    exact
                    path="/binary-mask-generator/app"
                />
                {/* Upscaler */}
                <Route
                    component={UpscalerLanding}
                    exact
                    path="/image-upscaler"
                />
                <Route
                    component={UpscalerApp}
                    exact
                    path="/image-upscaler/app"
                />
                {/* Object Remover */}
                <Route
                    component={ObjectRemoverLanding}
                    exact
                    path="/object-remover"
                />
                {/*<Route
                    component={ObjectRemoverApp}
                    exact
                    path="/object-remover/app"
    />*/}
                <Route
                    component={RemoveObjectWrapper}
                    exact
                    path="/object-remover/app"
                />
                {/* User */}
                <UserRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    component={Patterns}
                    exact
                    path="/patterns/:id"
                />
                <UserRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    component={TeeDesigns}
                    exact
                    path="/tees/:id"
                />
                <UserRoute
                    isLoggedIn={props.currentUser?.isLoggedIn}
                    component={Subscription}
                    exact
                    path="/manage-subscription"
                />
                {/* Terms */}
                {/*<Route component={FAQs} exact path="/faqs" />*/}
                <Route component={Privacy} exact path="/privacy" />
                <Route component={Terms} exact path="/terms" />

                {/* Error Pages */}
                <Route component={Error404} exact path="/404" />
                <Route component={Error500} exact path="/500" />
                {/* Authentication Pages */}
                <Route component={SignIn} exact path="/sign-in" />
                <Route component={SignUp} exact path="/sign-up" />
                <Route component={SignOut} exact path="/sign-out" />
                <Route
                    component={SessionExpired}
                    exact
                    path="/session-expired"
                />
                <Route
                    component={RegistrationSuccess}
                    exact
                    path="/registration-success"
                />
                <Route
                    component={EmailNotVerified}
                    exact
                    path="/email-not-verified"
                />
                <Route
                    component={ForgotPassword}
                    exact
                    path="/forgot-password"
                />
                <Route
                    component={ResetPassword}
                    exact
                    path="/reset-password/:token"
                />
                <Route
                    component={ForgotPasswordConfirm}
                    exact
                    path="/forgot-password-confirm"
                />
                <Route
                    component={EmailConfirmation}
                    exact
                    path="/email-confirmation/:token"
                />
                {/* Blogs */}
                <Route component={BlogHome} exact path="/blog" />
                <Route
                    component={CreatePatternsIsQuickAndEasy}
                    exact
                    path="/blog/create-beautiful-seamless-patterns-is-quick-and-easy"
                />
                <Route
                    component={HowToAlignMultiplePatterns}
                    exact
                    path="/blog/how-to-align-multiple-seamles-patterns"
                />
                <Route
                    component={EarnPassiveIncomeOnSociety6}
                    exact
                    path="/blog/earn-passive-income-on-society6"
                />
                <Route
                    component={HowToCreateMockupImages}
                    exact
                    path="/blog/how-to-create-mockup-images-for-seamless-patterns"
                />
                <Route
                    component={TopPlatformForSellingSeamlessPatterns}
                    exact
                    path="/blog/top-platform-for-selling-seamless-patterns"
                />
                {/* 404 */}
                <Redirect to="/404" />
            </Switch>
        </Fragment>
    );
};

export default AppRoutes;
