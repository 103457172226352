import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card } from "antd";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
    PSpan,
    PH4,
    PUl,
    PLi,
    PLink,
} from "../theme/BaseTheme";
import { createSubscription } from "../../actions/subscription";
import {
    ProPlanFeatures,
    StandardPlanFeatures,
    StarterPlanFeatures,
} from "../components/app/PlanFeatures";
import { useState } from "react";

const SellPlan = (props) => {
    const [planInterval, setPlanInterval] = useState("monthly");

    return (
        <PSection css={styles}>
            <PBox className="sell-plan">
                <PContainer>
                    <PText className="sell-plan-subtitle">Pricing</PText>
                    <PH1 className="sell-plan-title">Subscription Plans</PH1>
                    <PBox className="sell-plan-switch">
                        <Button
                            type={"primary"}
                            ghost={planInterval === "monthly" ? false : true}
                            onClick={() => {
                                setPlanInterval("monthly");
                            }}
                        >
                            Monthly
                        </Button>
                        <Button
                            type={"primary"}
                            ghost={planInterval === "annually" ? false : true}
                            onClick={() => {
                                setPlanInterval("annually");
                            }}
                        >
                            Annually (1 month free)
                        </Button>
                    </PBox>
                    <PBox className="sell-plan-cards">
                        <Card hoverable={true} className="sell-plan-card">
                            <PH4>Starter</PH4>
                            {planInterval === "monthly" && (
                                <>
                                    <PText className="sell-plan-card-price">
                                        <PSpan>$10</PSpan>/month
                                    </PText>
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={async () => {
                                            if (props.isLoggedIn) {
                                                await props.createSubscription(
                                                    "starter_monthly"
                                                );
                                            } else {
                                                window
                                                    .open("/sign-in", "_blank")
                                                    .focus();
                                            }
                                        }}
                                    >
                                        Buy Plan
                                    </Button>
                                </>
                            )}
                            {planInterval === "annually" && (
                                <>
                                    <PText className="sell-plan-card-price">
                                        <PSpan>$110</PSpan>/year
                                    </PText>
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={async () => {
                                            if (props.isLoggedIn) {
                                                await props.createSubscription(
                                                    "starter_annually"
                                                );
                                            } else {
                                                window
                                                    .open("/sign-in", "_blank")
                                                    .focus();
                                            }
                                        }}
                                    >
                                        Buy Plan
                                    </Button>
                                </>
                            )}
                            <StarterPlanFeatures highlight={true} />
                        </Card>
                        <Card
                            hoverable={true}
                            className="sell-plan-card-highlight"
                        >
                            <PH4>Standard</PH4>
                            {planInterval === "monthly" && (
                                <>
                                    <PText className="sell-plan-card-price">
                                        <PSpan>$20</PSpan>/month
                                    </PText>
                                    <Button
                                        type="primary"
                                        onClick={async () => {
                                            if (props.isLoggedIn) {
                                                await props.createSubscription(
                                                    "standard_monthly"
                                                );
                                            } else {
                                                window
                                                    .open("/sign-in", "_blank")
                                                    .focus();
                                            }
                                        }}
                                    >
                                        Buy Plan
                                    </Button>
                                </>
                            )}
                            {planInterval === "annually" && (
                                <>
                                    <PText className="sell-plan-card-price">
                                        <PSpan>$220</PSpan>/year
                                    </PText>
                                    <Button
                                        type="primary"
                                        onClick={async () => {
                                            if (props.isLoggedIn) {
                                                await props.createSubscription(
                                                    "standard_annually"
                                                );
                                            } else {
                                                window
                                                    .open("/sign-in", "_blank")
                                                    .focus();
                                            }
                                        }}
                                    >
                                        Buy Plan
                                    </Button>
                                </>
                            )}
                            <StandardPlanFeatures highlight={true} />
                        </Card>
                        <Card hoverable={true} className={"sell-plan-card"}>
                            <PH4>Pro</PH4>
                            {planInterval === "monthly" && (
                                <>
                                    <PText className="sell-plan-card-price">
                                        <PSpan>$40</PSpan>/month
                                    </PText>
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={async () => {
                                            if (props.isLoggedIn) {
                                                await props.createSubscription(
                                                    "pro_monthly"
                                                );
                                            } else {
                                                window
                                                    .open("/sign-in", "_blank")
                                                    .focus();
                                            }
                                        }}
                                    >
                                        Buy Plan
                                    </Button>
                                </>
                            )}
                            {planInterval === "annually" && (
                                <>
                                    <PText className="sell-plan-card-price">
                                        <PSpan>$440</PSpan>/year
                                    </PText>
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={async () => {
                                            if (props.isLoggedIn) {
                                                await props.createSubscription(
                                                    "pro_annually"
                                                );
                                            } else {
                                                window
                                                    .open("/sign-in", "_blank")
                                                    .focus();
                                            }
                                        }}
                                    >
                                        Buy Plan
                                    </Button>
                                </>
                            )}
                            <ProPlanFeatures highlight={true} />
                        </Card>
                    </PBox>
                </PContainer>
            </PBox>
        </PSection>
    );
};

const styles = {
    marginTop: "80px",
    "& .sell-plan": { marginBottom: "1rem" },
    "& .sell-plan-table": {},
    "& .sell-plan-container": {
        position: "relative",
    },
    "& .sell-plan-subtitle": {
        textAlign: "center",
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        margin: "0",
    },
    "& .sell-plan-title": {
        textAlign: "center",
        fontSize: "2.5rem",
        lineHeight: "1",
        color: "rgb(17 24 39)",
        letterSpacing: "-0.025em",
        fontWeight: "700",
        margin: "0.75rem 0 0 0",
    },
    "& .sell-plan-switch": {
        display: "flex",
        justifyContent: "center",
        marginTop: "3rem",
        "& button": {
            marginRight: "0.25rem",
        },
    },
    "& .sell-plan-coming-soon": {
        textAlign: "center",
        color: "rgb(75 85 99)",
        lineHeight: "2rem",
        fontSize: "1.125rem",
        maxWidth: "42rem",
        margin: "1.5rem auto",
    },
    "& .sell-plan-cards": {
        display: "grid",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gap: "2rem",
        maxWidth: "28rem",
        marginTop: "4.5rem",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp4": {
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            maxWidth: "none",
        },
    },
    "& .sell-plan-card": {
        padding: "0.5rem",
        borderRadius: "1.5rem",
        border: "1px solid $colors$borderColor",
        "& h4": {
            fontWeight: "600",
            fontSize: "1.125rem",
            marginTop: "0.25rem",
        },
        "& .sell-plan-card-desc": {
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontSize: "0.875rem",
            margin: "1rem 0 0 0",
        },
        "& .sell-plan-card-price": {
            columnGap: "0.25rem",
            alignItems: "baseline",
            display: "flex",
            margin: "1.5rem 0 0 0",
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontWeight: "600",
            fontSize: "0.875rem",
            "& span": {
                color: "rgb(17 24 39)",
                fontWeight: "700",
                fontSize: "2.25rem",
                lineHeight: "2.5rem",
                letterSpacing: "-0.025rem",
            },
        },
        "& button": {
            width: "100%",
            marginTop: "1.5rem",
        },
        "& a": {
            border: "1px solid $colors$borderColor",
            color: "$colors$primary",
            lineHeight: "1.5rem",
            textAlign: "center",
            padding: "0.5rem 0.75rem",
            fontSize: "0.875rem",
            borderRadius: "0.375rem",
            marginTop: "1.5rem",
            display: "block",
            cursor: "pointer",
        },
        "& ul": {
            color: "rgb(75 85 99)",
            lineHeight: "1.75rem",
            fontSize: "0.875rem",
            margin: "2rem 0 0 0",
            padding: "0",
            listStyle: "none",
            "& li": {
                columnGap: "0.75rem",
                display: "flex",
            },
            "& svg": {
                color: "$colors$primary",
                width: "1.25rem",
                height: "1.75rem",
            },
            "& p": {
                margin: "0",
                padding: "0",
            },
        },
    },
    "& .sell-plan-card-highlight": {
        padding: "0.5rem",
        borderRadius: "1.5rem",
        border: "2px solid $colors$primary",
        "& h4": {
            fontWeight: "600",
            fontSize: "1.125rem",
            marginTop: "0.25rem",
        },
        "& .sell-plan-card-desc": {
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontSize: "0.875rem",
            margin: "1rem 0 0 0",
        },
        "& .sell-plan-card-price": {
            columnGap: "0.25rem",
            alignItems: "baseline",
            display: "flex",
            margin: "1.5rem 0 0 0",
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontWeight: "600",
            fontSize: "0.875rem",
            "& span": {
                color: "rgb(17 24 39)",
                fontWeight: "700",
                fontSize: "2.25rem",
                lineHeight: "2.5rem",
                letterSpacing: "-0.025rem",
            },
        },
        "& button": {
            width: "100%",
            marginTop: "1.5rem",
        },
        "& a": {
            border: "1px solid $colors$primary",
            color: "#fff",
            backgroundColor: "$colors$primary",
            lineHeight: "1.5rem",
            textAlign: "center",
            padding: "0.5rem 0.75rem",
            fontSize: "0.875rem",
            borderRadius: "0.375rem",
            marginTop: "1.5rem",
            display: "block",
            cursor: "pointer",
        },
        "& ul": {
            color: "rgb(75 85 99)",
            lineHeight: "1.75rem",
            fontSize: "0.875rem",
            margin: "2rem 0 0 0",
            padding: "0",
            listStyle: "none",
            "& li": {
                columnGap: "0.75rem",
                display: "flex",
            },
            "& svg": {
                color: "$colors$primary",
                width: "1.25rem",
                height: "1.75rem",
            },
            "& p": {
                margin: "0",
                padding: "0",
            },
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        isLoggedIn: state.authReducer?.userAuth?.isLoggedIn,
    };
}

export default connect(mapStateToProps, { createSubscription })(
    withRouter(SellPlan)
);
