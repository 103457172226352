import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PBox, PH4, PLink, PText } from "../theme/BaseTheme";
import { Loader } from "../components/Loader";
import PatternCard from "../components/app/PatternCard";
import { Card, Skeleton } from "antd";
import {
    CopyOutlined,
    EllipsisOutlined,
    DownloadOutlined,
    SettingOutlined,
} from "@ant-design/icons";

class Display extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <PBox css={styles}>
                <Card
                    className="display-card"
                    title="Generated Patterns"
                    extra={<a href="/patterns/1">My Patterns</a>}
                >
                    <PBox className="display-images">
                        {this.props.generations?.map((generation) => {
                            return generation?.patterns.map((pattern) => {
                                return (
                                    <PatternCard
                                        pattern_uuid={pattern.uuid}
                                        image_url={pattern.url}
                                        prompt={pattern.prompt}
                                    />
                                );
                            });
                        })}
                    </PBox>
                </Card>
            </PBox>
        );
    }
}

const styles = {
    "@bp4": {
        gridColumn: "span 3 / span 3",
    },
    "& .display-card": {
        height: "48rem",
    },
    "& .display-card > .ant-card-body": { padding: 0 },
    "& .display-images": {
        display: "grid",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        padding: "1.5rem",
        overflow: "scroll",
        height: "40rem",
        gap: "1rem",
        "@bp2": { gridTemplateColumns: "repeat(2, minmax(0, 1fr))" },
        "@bp3": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp4": {
            gap: "1rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
        "@bp5": { gap: "2rem" },
    },
};

function mapStateToProps(state) {
    return {
        generations: state.patternReducer?.generatePatterns?.generations,
    };
}

export default connect(mapStateToProps, {})(withRouter(Display));
