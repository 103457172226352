import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
    PH2,
} from "../theme/BaseTheme";

const SellFaqItem = (props) => {
    return (
        <PBox className="sell-faq-container">
            <PBox className="sell-faq-question">
                <PH3>{props.question}</PH3>
            </PBox>
            <PBox className="sell-faq-answer">
                <PText>{props.answer}</PText>
            </PBox>
        </PBox>
    );
};

export const SellFaq = (props) => {
    return (
        <PSection css={styles}>
            <PBox className="sell-faq">
                <PH2 className="sell-faq-title">Frequently Asked Questions</PH2>
                <PBox className="sell-faq-content">
                    {props.data?.map((item) => {
                        return (
                            <SellFaqItem
                                question={item.question}
                                answer={item.answer}
                            />
                        );
                    })}
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-faq": {
        padding: "4rem 1.5rem 4rem 1.5rem",
        maxWidth: "42rem",
        margin: "0 auto 8rem auto",
        "@bp2": {
            padding: "4rem 1.5rem 2rem 1.5rem",
        },
        "@bp4": {
            maxWidth: "72rem",
            padding: "4rem 2rem 2rem 2rem",
        },
    },
    "& .sell-faq-title": {
        color: "rgb(17 24 39)",
        lineHeight: "2.5rem",
        fontWeight: "700",
        fontSize: "1.5rem",
    },
    "& .sell-faq-content": {
        marginTop: "2.5rem",
    },
    "& .sell-faq-container": {
        position: "relative",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        borderTop: "1px solid $colors$borderColor",
        "@bp4": {
            display: "grid",
            gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
            gap: "2rem",
        },
    },
    "& .sell-faq-question": {
        color: "rgb(17 24 39)",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        "@bp4": { gridColumn: "span 5 / span 5" },
    },
    "& .sell-faq-answer": {
        marginTop: "1rem",
        color: "rgb(75 85 99)",
        lineHeight: "1.75rem",
        fontSize: "1rem",
        "@bp4": {
            marginTop: "0px",
            gridColumn: "span 7 / span 7",
        },
    },
};
