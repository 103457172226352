import { Cascader } from "antd";
import { PBox, PText } from "../../theme/BaseTheme";

export const Dimension = (props) => {
    const options = [
        {
            value: "office",
            label: "Office",
            children: [
                {
                    value: "acrylic_boxes",
                    label: "Acrylic Boxes",
                    children: [
                        {
                            value: "1087x1087",
                            label: "1087px x 1087px",
                        },
                    ],
                },
                {
                    value: "acrylic_trays",
                    label: "Acrylic Trays",
                    children: [
                        {
                            value: "3375x4368",
                            label: "Portrait: 3375px x 4368px",
                        },
                        {
                            value: "4368x3375",
                            label: "Landscape: 4368px x 3375px",
                        },
                    ],
                },
                {
                    value: "jigsaw_puzzles",
                    label: "Jigsaw Puzzles",
                    children: [
                        {
                            value: "5925x7425",
                            label: "5925px x 7425px",
                        },
                    ],
                },
                {
                    value: "notebooks",
                    label: "Notebooks",
                    children: [
                        {
                            value: "3675x2475",
                            label: "3675px x 2475px",
                        },
                    ],
                },
                {
                    value: "stationery_cards",
                    label: "Stationery Cards",
                    children: [
                        {
                            value: "1300x2000",
                            label: "1300px x 2000px",
                        },
                    ],
                },
                {
                    value: "stickers",
                    label: "Stickers",
                    children: [
                        {
                            value: "1800x1800",
                            label: "1800px x 1800px",
                        },
                    ],
                },
                {
                    value: "wrapping_paper",
                    label: "Wrapping Paper",
                    children: [
                        {
                            value: "6075x8665",
                            label: "6075px x 8775px",
                        },
                    ],
                },
            ],
        },
        {
            value: "apparel_and_bags",
            label: "Apparel & Bags",
            children: [
                {
                    value: "all_over_graphic_t_shirts",
                    label: "All Over Graphic T-Shirts",
                    children: [
                        {
                            value: "8700x7050",
                            label: "8700px x 7050px",
                        },
                    ],
                },
                {
                    value: "backpacks",
                    label: "Backpacks",
                    children: [
                        {
                            value: "3900x4575",
                            label: "3900px x 4575px",
                        },
                    ],
                },
                {
                    value: "biker_tanks",
                    label: "Biker Tanks",
                    children: [
                        {
                            value: "3300x5100",
                            label: "3300px x 5100px",
                        },
                    ],
                },
                {
                    value: "carry_all_pouches",
                    label: "Carry-All Pouches",
                    children: [
                        {
                            value: "4600x3000",
                            label: "4600px x 3000px",
                        },
                    ],
                },
                {
                    value: "crewneck_sweatshirts",
                    label: "Crewneck Sweatshirts",
                    children: [
                        {
                            value: "3300x5100",
                            label: "3300px x 5100px",
                        },
                    ],
                },
                {
                    value: "duffle_bags",
                    label: "Duffle Bags",
                    children: [
                        {
                            value: "14579x5700",
                            label: "14579px x 5700px",
                        },
                    ],
                },
                {
                    value: "face_masks",
                    label: "Face Masks",
                    children: [
                        {
                            value: "1245x1170",
                            label: "1245px x 1170px",
                        },
                    ],
                },
                {
                    value: "fanny_packs",
                    label: "Fanny Packs",
                    children: [
                        {
                            value: "2700x1800",
                            label: "2700px x 1800px",
                        },
                    ],
                },
                {
                    value: "hoodies",
                    label: "Hoodies",
                    children: [
                        {
                            value: "3300x5100",
                            label: "3300px x 5100px",
                        },
                    ],
                },
                {
                    value: "leggings",
                    label: "Leggings",
                    children: [
                        {
                            value: "7500x9000",
                            label: "7500px x 9000px",
                        },
                    ],
                },
                {
                    value: "long_sleeve_tees",
                    label: "Long Sleeve Tees",
                    children: [
                        {
                            value: "3300x5100",
                            label: "3300px x 5100px",
                        },
                    ],
                },
                {
                    value: "socks",
                    label: "Socks",
                    children: [
                        {
                            value: "14172x3000",
                            label: "14172px X 3000px",
                        },
                    ],
                },
                {
                    value: "t_shirts",
                    label: "T-Shirts",
                    children: [
                        {
                            value: "3300x5100",
                            label: "3300px x 5100px",
                        },
                    ],
                },
                {
                    value: "tank_tops",
                    label: "Tank Tops",
                    children: [
                        {
                            value: "3300x5100",
                            label: "3300px x 5100px",
                        },
                    ],
                },
                {
                    value: "tote_bags",
                    label: "Tote Bags",
                    children: [
                        {
                            value: "3500x3500",
                            label: "3500px x 3500px",
                        },
                    ],
                },
                {
                    value: "v_necks",
                    label: "V-Necks",
                    children: [
                        {
                            value: "3300x5100",
                            label: "3300px x 5100px",
                        },
                    ],
                },
            ],
        },
        {
            value: "tech",
            label: "Tech",
            children: [
                {
                    value: "android_cases",
                    label: "Android Cases",
                    children: [
                        {
                            value: "1300x2000",
                            label: "1300 x 2000px",
                        },
                    ],
                },
                {
                    value: "apple_watch_bands",
                    label: "Apple Watch Bands",
                    children: [
                        {
                            value: "364x2933",
                            label: "364px x 2933px",
                        },
                    ],
                },
                {
                    value: "card_cases_android",
                    label: "Card Cases (Android)",
                    children: [
                        {
                            value: "1300x2000",
                            label: "1300px x 2000px",
                        },
                    ],
                },
                {
                    value: "card_cases_iphone",
                    label: "Card Cases (iPhone)",
                    children: [
                        {
                            value: "1300x2000",
                            label: "1300px x 2000px",
                        },
                    ],
                },
                {
                    value: "clear_cases",
                    label: "Clear Cases",
                    children: [
                        {
                            value: "979x1917",
                            label: "979px x 1917px",
                        },
                    ],
                },
                {
                    value: "ipad_folio_cases",
                    label: "iPad Folio Cases",
                    children: [
                        {
                            value: "5624x3473",
                            label: "5624px x 3473px",
                        },
                    ],
                },
                {
                    value: "iphone_cases",
                    label: "iPhone Cases",
                    children: [
                        {
                            value: "1300x2000",
                            label: "1300px x 2000px",
                        },
                    ],
                },
                {
                    value: "iphone_skins",
                    label: "iPhone Skins",
                    children: [
                        {
                            value: "1300x2000",
                            label: "1300px x 2000px",
                        },
                    ],
                },
                {
                    value: "laptop_skins",
                    label: "Laptop Skins",
                    children: [
                        {
                            value: "4600x3000",
                            label: "4600px x 3000px",
                        },
                    ],
                },
                {
                    value: "laptop_sleeves",
                    label: "Laptop Sleeves",
                    children: [
                        {
                            value: "4600x3000",
                            label: "4600px x 3000px",
                        },
                    ],
                },
                {
                    value: "wallet_cases_android",
                    label: "Wallet Cases (Android)",
                    children: [
                        {
                            value: "2191x2097",
                            label: "2191px x 2097px",
                        },
                    ],
                },
                {
                    value: "wallet_cases_iphone",
                    label: "Wallet Cases (iPhone)",
                    children: [
                        {
                            value: "2274x2002",
                            label: "2274px x 2002px",
                        },
                    ],
                },
            ],
        },
        {
            value: "wall_art",
            label: "Wall Art",
            children: [
                {
                    value: "art_prints",
                    label: "Art Prints",
                    children: [
                        {
                            value: "4000x5000",
                            label: "4000px x 5000px",
                        },
                    ],
                },
                {
                    value: "art_prints_framed",
                    label: "Art Prints - Framed",
                    children: [
                        {
                            value: "4000x5000",
                            label: "4000px x 5000px",
                        },
                    ],
                },
                {
                    value: "art_prints_recessed_framed",
                    label: "Art Prints - Recessed Framed",
                    children: [
                        {
                            value: "4000x5000",
                            label: "4000px x 5000px",
                        },
                    ],
                },
                {
                    value: "canvas_prints",
                    label: "Canvas Prints",
                    children: [
                        {
                            value: "4000x5000",
                            label: "4000px x 5000px",
                        },
                    ],
                },
                {
                    value: "canvas_prints_framed",
                    label: "Canvas Prints - Framed",
                    children: [
                        {
                            value: "2400x3600",
                            label: "2400px x 3600px",
                        },
                        {
                            value: "3000x3000",
                            label: "3000px x 3000px",
                        },
                        {
                            value: "3600x5400",
                            label: "3600px x 5400px",
                        },
                        {
                            value: "6000x6000",
                            label: "6000px x 6000px",
                        },
                        {
                            value: "4800x7200",
                            label: "4800px x 7200px",
                        },
                        {
                            value: "7200x10800",
                            label: "7200px x 10800px",
                        },
                        {
                            value: "9000x9000",
                            label: "9000px x 9000px",
                        },
                    ],
                },
                {
                    value: "floating_acrylic_prints",
                    label: "Floating Acrylic Prints",
                    children: [
                        {
                            value: "5118x6825",
                            label: "5118px x 6825px",
                        },
                    ],
                },
                {
                    value: "framed_mini_art_prints",
                    label: "Framed Mini Art Prints",
                    children: [
                        {
                            value: "1238x1238",
                            label: "Square: 1238px x 1238px",
                        },
                        {
                            value: "938x1238",
                            label: "Portrait: 938px x 1238px",
                        },
                        {
                            value: "1238x938",
                            label: "Landscape: 1238px x 938px",
                        },
                    ],
                },
                {
                    value: "mini_art_prints",
                    label: "Mini Art Prints",
                    children: [
                        {
                            value: "938x1238",
                            label: "Portrait: 938px x 1238px",
                        },
                        {
                            value: "1238x938",
                            label: "Landscape: 1238px x 938px",
                        },
                        {
                            value: "1238x1238",
                            label: "Square: 1238px x 1238px",
                        },
                    ],
                },
                {
                    value: "posters",
                    label: "Posters",
                    children: [
                        {
                            value: "2718x3618",
                            label: "2718px x 3618px",
                        },
                    ],
                },
                {
                    value: "wall_hangings",
                    label: "Wall Hangings",
                    children: [
                        {
                            value: "7800x5678",
                            label: "Landscape: 7800px x 5678px",
                        },
                        {
                            value: "4058x5678",
                            label: "Portrait: 4058px x 5678px",
                        },
                    ],
                },
                {
                    value: "wood_wall_art",
                    label: "Wood Wall Art",
                    children: [
                        {
                            value: "2601x2601",
                            label: "2601px x 2601px",
                        },
                    ],
                },
            ],
        },
        {
            value: "furniture",
            label: "Furniture",
            children: [
                {
                    value: "bar_stools",
                    label: "Bar Stools",
                    children: [
                        {
                            value: "3300x3300",
                            label: "3300px x 3300px",
                        },
                    ],
                },
                {
                    value: "benches",
                    label: "Benches",
                    children: [
                        {
                            value: "8175x3675",
                            label: "8175px x 3675px",
                        },
                    ],
                },
                {
                    value: "coffee_tables",
                    label: "Coffee Tables",
                    children: [
                        {
                            value: "5362x2662",
                            label: "5362px x 2662px",
                        },
                    ],
                },
                {
                    value: "counter_stools",
                    label: "Counter Stools",
                    children: [
                        {
                            value: "3300x3300",
                            label: "3300px x 3300px",
                        },
                    ],
                },
                {
                    value: "credenzas",
                    label: "Credenzas",
                    children: [
                        {
                            value: "5325x3300",
                            label: "5325px x 3300px",
                        },
                    ],
                },
                {
                    value: "side_tables",
                    label: "Side Tables",
                    children: [
                        {
                            value: "2850x2850",
                            label: "2850px x 2850px",
                        },
                    ],
                },
            ],
        },
        {
            value: "bed_and_bath",
            label: "Bed & Bath",
            children: [
                {
                    value: "bath_mats",
                    label: "Bath Mats",
                    children: [
                        {
                            value: "2592x1656",
                            label: "2592px x 1656px",
                        },
                    ],
                },
                {
                    value: "comforters",
                    label: "Comforters",
                    children: [
                        {
                            value: "6000x6000",
                            label: "6000px x 6000px",
                        },
                    ],
                },
                {
                    value: "duvet_covers",
                    label: "Duvet Covers",
                    children: [
                        {
                            value: "6000x6000",
                            label: "6000px x 6000px",
                        },
                    ],
                },
                {
                    value: "hand_and_bath_towels",
                    label: "Hand & Bath Towels",
                    children: [
                        {
                            value: "3700x7400",
                            label: "3700px x 7400px",
                        },
                    ],
                },
                {
                    value: "pillow_shams",
                    label: "Pillow Shams",
                    children: [
                        {
                            value: "6105x3500",
                            label: "6105px x 3500px",
                        },
                    ],
                },
                {
                    value: "shower_curtains",
                    label: "Shower Curtains",
                    children: [
                        {
                            value: "6000x6000",
                            label: "6000px x 6000px",
                        },
                    ],
                },
            ],
        },
        {
            value: "outdoor_and_lifestyle",
            label: "Outdoor & Lifestyle",
            children: [
                {
                    value: "beach_towels",
                    label: "Beach Towels",
                    children: [
                        {
                            value: "3700x7400",
                            label: "3700px x 7400px",
                        },
                    ],
                },
                {
                    value: "can_coolers",
                    label: "Can Coolers",
                    children: [
                        {
                            value: "3084x1276",
                            label: "3084px X 1276px",
                        },
                    ],
                },
                {
                    value: "folding_stool",
                    label: "Folding Stool",
                    children: [
                        {
                            value: "2250x2700",
                            label: "2250px x 2700px",
                        },
                    ],
                },
                {
                    value: "outdoor_floor_cushions",
                    label: "Outdoor Floor Cushions",
                    children: [
                        {
                            value: "5600x4300",
                            label: "5600px x 4300px",
                        },
                    ],
                },
                {
                    value: "picnic_blankets",
                    label: "Picnic Blankets",
                    children: [
                        {
                            value: "9300x7750",
                            label: "9300px x 7750px",
                        },
                    ],
                },
                {
                    value: "sling_chairs",
                    label: "Sling Chairs",
                    children: [
                        {
                            value: "2550x7800",
                            label: "2550px x 7800px",
                        },
                    ],
                },
                {
                    value: "sun_shades",
                    label: "Sun Shades",
                    children: [
                        {
                            value: "8604x5148",
                            label: "8604px x 5148px",
                        },
                    ],
                },
                {
                    value: "welcome_mats",
                    label: "Welcome Mats",
                    children: [
                        {
                            value: "5700x3800",
                            label: "5700px x 3800px",
                        },
                    ],
                },
                {
                    value: "yoga_mats",
                    label: "Yoga Mats",
                    children: [
                        {
                            value: "3750x10650",
                            label: "3750px x 10650px",
                        },
                    ],
                },
                {
                    value: "yoga_towels",
                    label: "Yoga Towels",
                    children: [
                        {
                            value: "3750x10650",
                            label: "3750px x 10650px",
                        },
                    ],
                },
            ],
        },
        {
            value: "home_decor",
            label: "Home Decor",
            children: [
                {
                    value: "blackout_curtains",
                    label: "Blackout Curtains",
                    children: [
                        {
                            value: "5300x9900",
                            label: "5300px x 9900px",
                        },
                    ],
                },
                {
                    value: "floor_pillows",
                    label: "Floor Pillows",
                    children: [
                        {
                            value: "4500x4500",
                            label: "4500px x 4500px",
                        },
                    ],
                },
                {
                    value: "rectangular_pillows",
                    label: "Rectangular Pillows",
                    children: [
                        {
                            value: "4600x3000",
                            label: "4600px x 3000px",
                        },
                    ],
                },
                {
                    value: "rugs",
                    label: "Rugs",
                    children: [
                        {
                            value: "7400x5000",
                            label: "Landscape: 7400px x 5000px",
                        },
                        {
                            value: "5000x7400",
                            label: "Portrait: 5000px x 7400px",
                        },
                    ],
                },
                {
                    value: "sheer_curtains",
                    label: "Sheer Curtains",
                    children: [
                        {
                            value: "5300x9900",
                            label: "5300px x 9900px",
                        },
                    ],
                },
                {
                    value: "throw_blankets",
                    label: "Throw Blankets",
                    children: [
                        {
                            value: "6500x5525",
                            label: "6500px x 5525px",
                        },
                    ],
                },
                {
                    value: "throw_pillows",
                    label: "Throw Pillows",
                    children: [
                        {
                            value: "3500x3500",
                            label: "3500px x 3500px",
                        },
                    ],
                },
                {
                    value: "wall_clocks",
                    label: "Wall Clocks",
                    children: [
                        {
                            value: "3500x3500",
                            label: "3500px x 3500px",
                        },
                    ],
                },
                {
                    value: "wall_murals",
                    label: "Wall Murals",
                    children: [
                        {
                            value: "6000x6000",
                            label: "Square: 6000px x 6000px",
                        },
                        {
                            value: "9000x9000",
                            label: "Landscape: 9000px x 6000px",
                        },
                    ],
                },
                {
                    value: "wall_tapestries",
                    label: "Wall Tapestries",
                    children: [
                        {
                            value: "6500x5525",
                            label: "Landscape: 6500px x 5525px",
                        },
                        {
                            value: "5525x6500",
                            label: "Portrait: 5525px x 6500px",
                        },
                    ],
                },
                {
                    value: "wallpaper",
                    label: "Wall Paper",
                    children: [
                        {
                            value: "3600x3600",
                            label: "3600px x 3600px",
                        },
                    ],
                },
            ],
        },
        {
            value: "tabletop",
            label: "Tabletop",
            children: [
                {
                    value: "coasters",
                    label: "Coasters",
                    children: [
                        {
                            value: "2400x2400",
                            label: "2400px x 2400px",
                        },
                    ],
                },
                {
                    value: "coffee_mugs",
                    label: "Coffee Mugs",
                    children: [
                        {
                            value: "4600x2000",
                            label: "4600px x 2000px",
                        },
                    ],
                },
                {
                    value: "cutting_boards",
                    label: "Cutting Boards",
                    children: [
                        {
                            value: "2500x1575",
                            label: "Rectangle: 2500px x 1575px",
                        },
                        {
                            value: "2500x2500",
                            label: "Round: 2500px x 2500px",
                        },
                    ],
                },
                {
                    value: "travel_mugs",
                    label: "Travel Mugs",
                    children: [
                        {
                            value: "2795x2100",
                            label: "2795px x 2100px",
                        },
                    ],
                },
                {
                    value: "placemats",
                    label: "Placemats",
                    children: [
                        {
                            value: "2400x3000",
                            label: "2400px x 3000px",
                        },
                    ],
                },
                {
                    value: "serving_trays",
                    label: "Serving Trays",
                    children: [
                        {
                            value: "3319x2569",
                            label: "Landscape: 3319px x 2569px",
                        },
                        {
                            value: "2569x3319",
                            label: "Portrait: 2569 x 3319px",
                        },
                    ],
                },
                {
                    value: "water_bottles",
                    label: "Water Bottles",
                    children: [
                        {
                            value: "3390x2230",
                            label: "3390px x 2230px",
                        },
                    ],
                },
            ],
        },
    ];
    const onChange = (value) => {
        console.log(value);
        props.setWidth(value[2].split("x")[0]);
        props.setHeight(value[2].split("x")[1]);
    };
    const filter = (inputValue, path) =>
        path.some(
            (option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
                -1
        );
    return (
        <PBox css={styles}>
            <Cascader
                style={{
                    width: "100%",
                }}
                options={options}
                onChange={onChange}
                placeholder="Search for products."
                showSearch={{
                    filter,
                }}
                onSearch={(value) => console.log(value)}
            />
        </PBox>
    );
};

const styles = {
    margin: "1rem auto 2rem auto",
};
