import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PLink,
    PText,
    PBox,
    PHighlightButton,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { userSignOut } from "../../../actions/auth";

class SignOut extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {}

    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles}>
                    <PBox className="title">
                        <PH2>You are Logged Out</PH2>
                    </PBox>
                    <PBox className="sign-out">
                        <PBox className="sign-out-title">
                            <PBox>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="64"
                                    height="64"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path>
                                </svg>
                            </PBox>
                            <PText>Thank you for using Patternful.ai!</PText>
                        </PBox>

                        <PHighlightButton
                            type="primary"
                            size="large"
                            className="sign-out-button"
                            style={{}}
                            onClick={() => {
                                this.props.history.push("/");
                            }}
                        >
                            Home
                        </PHighlightButton>
                    </PBox>
                    <PBox className="sign-in-link">
                        <PText>
                            Sign in to continue.{" "}
                            <PLink href="/sign-in">Sign In</PLink>
                        </PText>
                    </PBox>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .sign-out": {
        width: "400px",
        marginTop: "2rem",
        backgroundColor: "#fff",
        padding: "3rem 2rem 2rem 2rem",
        borderRadius: "0.5rem",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    },
    "& .title": { fontSize: "18px", marginTop: "2rem" },
    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "#000",
        },
    },
    "& .sign-out-button": {
        color: "#fff",
        backgroundColor: "$colors$primary700",
        width: "100%",
        borderColor: "$colors$primary700",
        marginTop: "16px",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "$colors$primary500",
            borderColor: "$colors$primary500",
        },
        margin: "20px 0",
    },
    "& .sign-out-title": {
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h2": {
            fontWeight: "400",
        },
        "& svg": {
            backgroundColor: "#fff",
            borderRadius: "100px",
            fill: "$colors$primary",
            marginBottom: "2rem",
        },
    },
    "& .sign-in-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};

export default connect(null, { userSignOut })(withRouter(SignOut));
