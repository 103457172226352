import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Radio, message } from "antd";
import { PBox, PH4, PText } from "../../theme/BaseTheme";
import { downloadTeeImage } from "../../../actions/tee";

const TeeDownloadModal = (props) => {
    const [formatValue, setFormatValue] = useState("webp");
    const [resolutionValue, setResolutionValue] = useState("72");
    const [sizeValue, setSizeValue] = useState("1024");
    const [isProcessing, setIsProcessing] = useState(false);

    return (
        <Modal
            title="Download Image"
            open={props.isDownloadModalOpen}
            onOk={async () => {
                console.log("downloading: ", props.patternUUID);
                await setIsProcessing(true);
                message.open({
                    type: "loading",
                    content: "Preparing for downloading.",
                    duration: 0,
                });
                await props.downloadTeeImage(
                    props.patternUUID,
                    formatValue,
                    resolutionValue,
                    sizeValue
                );
                message.destroy();
                await setIsProcessing(false);
                props.handleDownloadModalOk();
            }}
            okButtonProps={{ loading: isProcessing }}
            onCancel={props.handleDownloadModalCancel}
            okText={"Download"}
        >
            <PH4>1. Formats</PH4>
            <Radio.Group
                options={[
                    { label: "WEBP", value: "webp" },
                    { label: "PNG", value: "png" },
                    { label: "JPEG", value: "jpg" },
                ]}
                value={formatValue}
                onChange={(e) => {
                    setFormatValue(e.target.value);
                }}
            />
            <PH4>2. DPI</PH4>
            <Radio.Group
                options={[
                    { label: "72 dpi", value: "72" },
                    { label: "100 dpi", value: "100" },
                    { label: "150 dpi", value: "150" },
                    { label: "200 dpi", value: "200" },
                    { label: "300 dpi", value: "300" },
                ]}
                value={resolutionValue}
                onChange={(e) => {
                    setResolutionValue(e.target.value);
                }}
            />
            <PText>
                <br />
            </PText>
        </Modal>
    );
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, { downloadTeeImage })(
    withRouter(TeeDownloadModal)
);
