import clsx from "clsx";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
} from "../../theme/BaseTheme";

export const PatternHow = () => {
    return (
        <PSection css={styles}>
            <PBox className="sell-how">
                <PBox className="sell-how-container">
                    <PText className="sell-how-subtitle">
                        Start Generating Patterns for Free
                    </PText>
                    <PH1 className="sell-how-title">
                        How Patternful.ai Works
                    </PH1>

                    <PBox className="sell-how-steps-wrapper">
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-1"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>1. Compose and Generate</PH3>
                            <PText>
                                Describe the pattern you intend to create and
                                generate patterns effortlessly with a single
                                click.
                            </PText>
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-2"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M87.82,196.31a20.82,20.82,0,0,1-9.19,15.23C73.44,215,67,216,61.14,216A61.23,61.23,0,0,1,46,214a8,8,0,0,1,4.3-15.41c4.38,1.2,14.95,2.7,19.55-.36.88-.59,1.83-1.52,2.14-3.93.35-2.67-.71-4.1-12.78-7.59-9.35-2.7-25-7.23-23-23.11a20.55,20.55,0,0,1,9-14.95c11.84-8,30.72-3.31,32.83-2.76a8,8,0,0,1-4.07,15.48c-4.48-1.17-15.23-2.56-19.83.56a4.54,4.54,0,0,0-2,3.67c-.11.9-.14,1.09,1.12,1.9,2.31,1.49,6.44,2.68,10.45,3.84C73.5,174.17,90.06,179,87.82,196.31ZM216,88v24a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,1,1-16,0V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.65,2.34l56,56A8,8,0,0,1,216,88Zm-56-8h28.69L160,51.31Zm-13.3,64.47a8,8,0,0,0-10.23,4.84L124,184.21l-12.47-34.9a8,8,0,1,0-15.06,5.38l20,56a8,8,0,0,0,15.07,0l20-56A8,8,0,0,0,146.7,144.47ZM208,176h-8a8,8,0,0,0,0,16v5.29a13.38,13.38,0,0,1-8,2.71c-8.82,0-16-9-16-20s7.18-20,16-20a13.27,13.27,0,0,1,7.53,2.38,8,8,0,0,0,8.95-13.26A29.38,29.38,0,0,0,192,144c-17.64,0-32,16.15-32,36s14.36,36,32,36a30.06,30.06,0,0,0,21.78-9.6,8,8,0,0,0,2.22-5.53V184A8,8,0,0,0,208,176Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>2. Scale or Upscale</PH3>
                            <PText>
                                Upscale patterns to get high resolution images
                                or convert patterns to scalable vector images.
                            </PText>
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-3"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-69.66,42.34L160,188.69V128a8,8,0,0,0-16,0v60.69l-18.34-18.35a8,8,0,0,0-11.32,11.32l32,32a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>3. Edit and Export</PH3>
                            <PText>
                                Create similar variations, edit patterns, and
                                download in multiple sizes, file types and
                                resolutions.
                            </PText>
                            {/*<PH3>3. Generate Variations</PH3>
                            <PText>
                                Create similar pattern variations for a related,
                                unified, yet diverse collection.
                        </PText>*/}
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-4"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M200,48H179.31L165.66,34.34A8.07,8.07,0,0,0,160.05,32H96a8,8,0,0,0-5.66,2.34L76.69,48H56A16,16,0,0,0,40,64V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V64A16,16,0,0,0,200,48Zm-38.76,4.56L168,59.31V112L138.57,86.56ZM88,59.31l6.76-6.75,22.67,34L88,112ZM120,216H56V64H72v48a15.85,15.85,0,0,0,9.21,14.49A16.1,16.1,0,0,0,88,128a15.89,15.89,0,0,0,10.2-3.73.52.52,0,0,0,.11-.1L120,105.48ZM111,48h34.1L128,73.58ZM200,216H136V105.48l21.65,18.7a.52.52,0,0,0,.11.1A15.89,15.89,0,0,0,168,128a16.1,16.1,0,0,0,6.83-1.54A15.85,15.85,0,0,0,184,112V64h16Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>4. Generate Mockups</PH3>
                            <PText>
                                Use patterns on mockup images for
                                straightforward visualization, presentation, and
                                marketing.
                            </PText>
                            {/*<PH3>4. Edit and Export</PH3>
                            <PText>
                                Edit patterns, and download in multiple
                                dimensions, file types and resolutions.
                        </PText>*/}
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    backgroundColor: "rgb(238, 236, 255)",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-how": {
        padding: "4rem 1.5rem",
        maxWidth: "80rem",
        margin: "4rem auto",
        "@bp2": { marginTop: "8rem" },
        "@bp4": {
            paddingLeft: "4rem",
            paddingRight: "4rem",
        },
    },
    "& .sell-how-container": {
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
    },
    "& .sell-how-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .sell-how-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.5rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "3rem" },
    },
    "& .sell-how-text": {
        fontSize: "20px",
        lineHeight: "1.7",
        color: "rgb(75 85 99)",
        textAlign: "center",
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "80px",
    },
    "& .sell-how-steps-wrapper": {
        position: "relative",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        maxWidth: "80rem",
        marginTop: "2rem",
        marginLeft: "auto",
        marginRight: "auto",

        overflow: "hidden",
        display: "grid",
        maxWidth: "42rem",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gap: "2rem",
        "@bp4": {
            paddingLeft: "2rem",
            paddingRight: "2rem",
            maxWidth: "none",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "4rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
    },
    "& .sell-how-steps-item": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        "& h3": {
            marginBottom: "0",
            marginLeft: "0.125rem",
        },
        "& p": {
            lineHeight: "1.5",
            marginLeft: "0.125rem",
            color: "rgb(75 85 99)",
        },
        "& svg": {
            width: "2rem",
            height: "2rem",
            //padding: "0rem",
            //borderRadius: "50%",
            //fill: "$colors$primary",
            fill: "#fff",
            padding: "0.5rem",
            borderRadius: "0.5rem",
            backgroundColor: "$colors$primary",
        },
    },
    "& .sell-how-steps-step": {
        display: "flex",
        position: "relative",
        width: "100%",
    },
    "& .sell-how-steps-line": {
        width: "auto",
        height: "1px",
        position: "static",
        //backgroundColor: "$colors$borderColor",
        backgroundColor: "$colors$primary",
        marginLeft: "1rem",
        marginRight: "1rem",
        transform: "translate(0, 1.5rem)",
        flex: "1 1 auto",
    },
};
