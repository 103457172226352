import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { BgRemoverBanner } from "../../sections/bg_remover/BgRemoverBanner";
import { BgRemoverHow } from "../../sections/bg_remover/BgRemoverHow";
import { CallToAction } from "../../sections/CallToAction";
import { SellFaq } from "../../sections/SellFaq";
import { FeatureImageLeft } from "../../sections/FeatureImageLeft";
import { FeatureImageRight } from "../../sections/FeatureImageRight";

const bg_remover_faq_data = [
    {
        question: "How to remove background from image without Photoshop?",
        answer: "There's no need to invest in expensive photo editing software. Simply use Patternful.ai's tool to remove background from image in 3 simple steps: 1. Upload the image you want to edit. 2. Let AI remove the background. 3. Download the output.",
    },
    {
        question:
            "Can I use Patternful.ai's background remover for creating e-commerce photos?",
        answer: "Yes absolutely! Images edited using Patternful.ai's background remover can be utilized for both personal and commercial purposes. ",
    },
    {
        question:
            "How long does it take to remove the background from an image using the tool?",
        answer: "The processing time varies based on the image size, but typically, it only requires a few seconds to complete.",
    },
];

class BgRemoverLanding extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Background Remover</title>
                    <meta
                        name="description"
                        content="Get transparent background image using AI in just one click, perfect for your e-commerce, marketing, graphic design, and more."
                    />
                    <meta
                        name="keywords"
                        content="Background Remover, AI Background Remover, Background Remover APP, Background Remover Online, Image Processing, AI Image Editing, Patternful, Patternful AI"
                    />
                </Helmet>
                <BgRemoverBanner />
                <BgRemoverHow />
                <FeatureImageLeft
                    image={"/images/removebg/speed.webp"}
                    title={"Remove Background Automatically in Seconds"}
                    description={
                        "Stop wasting your time manually picking pixels with complicated and expensive software. Now you can use the Patternful AI background remover to get high quality cut outs in just a few seconds."
                    }
                />
                <FeatureImageRight
                    image={"/images/removebg/efficiency.webp"}
                    title={"Boost the efficiency of your workflow"}
                    description={
                        "Doing tedious repetitive work is a waste of time. When it comes to removing backgrounds, just upload your images and we'll do the rest."
                    }
                />
                <FeatureImageLeft
                    image={"/images/removebg/quality.webp"}
                    title={"Become an Artist"}
                    description={
                        "Create amazing designs for your pictures, banners, posters, greeting cards, product photos, social media & more."
                    }
                />
                <CallToAction
                    title={"Upload Image to Remove Background"}
                    subtitle={
                        "Get a transparent background for any image in just one click."
                    }
                    link={"/background-remover/app"}
                    cta={"Get Started"}
                />
                <SellFaq data={bg_remover_faq_data} />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(BgRemoverLanding));
