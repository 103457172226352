import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, message, Radio, Upload } from "antd";
import { Helmet } from "react-helmet";
import download from "downloadjs";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PH4, PImg, PText, PH2, PH1 } from "../../theme/BaseTheme";
import { refetchUser } from "../../../actions/auth";
import {
    upscaleImage,
    upscaleImageDownload,
    clearUpscaledImage,
} from "../../../actions/pattern";
import ChangePlanModal from "../../components/app/ChangePlanModal";
import PaymentConfirmModal from "../../components/app/PaymentConfirmModal";
import { SellFaq } from "../../sections/SellFaq";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import { upscaler_faq_data } from "../landing/UpscalerLanding";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

class UpscalerApp extends Component {
    canvasRef = React.createRef();

    constructor(props) {
        super(props);
        this.highlightRef = React.createRef();
        this.resultRef = React.createRef();
        this.state = {
            isLoading: true,
            brushSize: 30,
            isGenerating: false,
            isDownloading: false,
            uploadedImage: "",
            backgroundImage: "",
            upscaleValue: "2",
            fileList: [],
            isChangePlanModalOpen: false,
            isPaymentConfirmModalOpen: false,
            newPlan: "",
        };
        this.handleUpload = this.handleUpload.bind(this);
    }

    handleUpload = async ({ file }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        await this.setState({
            uploadedImage: file.url || file.preview,
        });
    };

    async componentDidMount() {
        await this.props.clearUpscaledImage();
        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Image Upscaler App</title>
                    <meta
                        name="description"
                        content="AI-powered image upscaler to enhance and upscale images without losing quality, perfect for making images sharper, clearer and bigger."
                    />
                    <meta
                        name="keywords"
                        content="Image Upscaler, AI Image Upscaler, Image Upscaler APP, Upscale Image Online, Image Processing, AI Image Editing, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="app-content">
                        <PBox className="app-content-title">
                            <PH1>AI Image Upscaler</PH1>
                            <PText>
                                Improve low resolution image and increase image
                                size.
                            </PText>
                        </PBox>
                        <PBox className="app-content-control">
                            <PBox className="remove-background-upload-image">
                                <PBox className="binary-mask-upload">
                                    <Upload.Dragger
                                        onChange={async (info) => {
                                            await this.handleUpload(info);
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                        showUploadList={false}
                                        listType="picture-card"
                                        maxCount={1}
                                        beforeUpload={async (file) => {
                                            const isJpgOrPng =
                                                file.type === "image/jpeg" ||
                                                file.type === "image/png";
                                            if (!isJpgOrPng) {
                                                message.error(
                                                    "You can only upload JPG/PNG file."
                                                );
                                            }
                                            const isLt4M =
                                                file.size / 1024 / 1024 < 4;
                                            if (!isLt4M) {
                                                message.error(
                                                    "Image must smaller than 4MB."
                                                );
                                            }
                                            return (
                                                (isJpgOrPng && isLt4M) ||
                                                Upload.LIST_IGNORE
                                            );
                                        }}
                                    >
                                        <PText>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#000000"
                                                viewBox="0 0 256 256"
                                            >
                                                <path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0ZM93.66,85.66,120,59.31V152a8,8,0,0,0,16,0V59.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,85.66Z"></path>
                                            </svg>
                                            <br />
                                            Click to upload your image,
                                            <br />
                                            or drag and drop your image into
                                            this area.
                                        </PText>
                                    </Upload.Dragger>
                                </PBox>
                                {this.state.uploadedImage !== "" && (
                                    <PBox
                                        className="remove-background-content"
                                        ref={this.highlightRef}
                                    >
                                        <PBox className="binary-mask-sketch-canva">
                                            <PImg
                                                src={this.state.uploadedImage}
                                            />
                                        </PBox>
                                        <PBox>
                                            <PH2>Upscale Options</PH2>
                                            <Radio.Group
                                                onChange={(e) => {
                                                    this.setState({
                                                        upscaleValue:
                                                            e.target.value,
                                                    });
                                                }}
                                                size="large"
                                                value={this.state.upscaleValue}
                                                optionType="button"
                                                buttonStyle="solid"
                                            >
                                                <Radio.Button value="2">
                                                    2x Upscaling
                                                </Radio.Button>
                                                <Radio.Button value="4">
                                                    <PBox className="upscale-option">
                                                        4x Upscaling{" "}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="#000000"
                                                            viewBox="0 0 256 256"
                                                            style={{
                                                                fill: "#d48806",
                                                                backgroundColor:
                                                                    "#ffe58f",
                                                                padding:
                                                                    "0.125rem 0.25rem",
                                                                borderRadius:
                                                                    "0.25rem",
                                                                width: "1rem",
                                                                height: "1rem",
                                                                marginLeft:
                                                                    "0.5rem",
                                                            }}
                                                        >
                                                            <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                                        </svg>
                                                    </PBox>
                                                </Radio.Button>
                                                {/*<Radio.Button value="8">
                                                    <PBox className="upscale-option">
                                                        8x Upscaling{" "}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="#000000"
                                                            viewBox="0 0 256 256"
                                                            style={{
                                                                fill: "#d48806",
                                                                backgroundColor:
                                                                    "#ffe58f",
                                                                padding:
                                                                    "0.125rem 0.25rem",
                                                                borderRadius:
                                                                    "0.25rem",
                                                                width: "1rem",
                                                                height: "1rem",
                                                                marginLeft:
                                                                    "0.5rem",
                                                            }}
                                                        >
                                                            <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                                        </svg>
                                                    </PBox>
                                                        </Radio.Button>*/}
                                            </Radio.Group>
                                            <PText>
                                                <br />
                                            </PText>
                                            <Button
                                                size="large"
                                                type="primary"
                                                loading={
                                                    this.state.isGenerating
                                                }
                                                onClick={async () => {
                                                    if (
                                                        !this.props.userAccount
                                                    ) {
                                                        window
                                                            .open(
                                                                "/sign-in",
                                                                "_blank"
                                                            )
                                                            .focus();
                                                    } else if (
                                                        this.props.userAccount
                                                            ?.credits === 0 &&
                                                        this.state
                                                            .upscaleValue !==
                                                            "2"
                                                    ) {
                                                        await this.setState({
                                                            isChangePlanModalOpen: true,
                                                        });
                                                    } else {
                                                        await this.setState({
                                                            isGenerating: true,
                                                        });
                                                        message.open({
                                                            type: "loading",
                                                            content:
                                                                "Upscaling the image. This can take a while.",
                                                            duration: 0,
                                                        });
                                                        try {
                                                            await this.props.upscaleImage(
                                                                this.state
                                                                    .uploadedImage,
                                                                this.state
                                                                    .upscaleValue
                                                            );
                                                            await this.resultRef.current.scrollIntoView(
                                                                {
                                                                    behavior:
                                                                        "smooth",
                                                                    block: "start",
                                                                }
                                                            );
                                                        } catch (error) {}
                                                        message.destroy();
                                                        await this.setState({
                                                            isGenerating: false,
                                                        });
                                                        await this.props.refetchUser();
                                                    }
                                                }}
                                            >
                                                Upscale
                                            </Button>
                                        </PBox>
                                    </PBox>
                                )}
                                {this.props.upscaledImage &&
                                    this.state.uploadedImage !== "" && (
                                        <PBox
                                            className="binary-mask-result-image"
                                            ref={this.resultRef}
                                        >
                                            <PH2>Upscale Result</PH2>
                                            <PBox className="remove-background-slider">
                                                <ImgComparisonSlider class="slider-with-animated-handle">
                                                    <img
                                                        slot="first"
                                                        width="100%"
                                                        src={
                                                            this.state
                                                                .uploadedImage
                                                        }
                                                    />
                                                    <img
                                                        slot="second"
                                                        width="100%"
                                                        src={
                                                            //keys.serverUrl +
                                                            //"/" +
                                                            this.props
                                                                .upscaledImage
                                                        }
                                                    />
                                                </ImgComparisonSlider>
                                                <PText className="caption-before">
                                                    Before
                                                </PText>
                                                <PText className="caption-after">
                                                    After
                                                </PText>
                                            </PBox>
                                        </PBox>
                                    )}
                            </PBox>

                            {this.props.upscaledImage &&
                                this.state.uploadedImage !== "" && (
                                    <PBox className="remove-background-download-buttons">
                                        <Button
                                            size="large"
                                            type="primary"
                                            loading={this.state.isDownloading}
                                            onClick={async () => {
                                                /*this.setState({
                                                    isDownloading: true,
                                                });
                                                await this.props.upscaleImageDownload(
                                                    this.props.upscaledImage
                                                );
                                                await this.props.refetchUser();
                                                this.setState({
                                                    isDownloading: false,
                                                });*/
                                                download(
                                                    this.props.upscaledImage,
                                                    `img.png`,
                                                    `image/png`
                                                );
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </PBox>
                                )}

                            <SellFaq data={upscaler_faq_data} />
                        </PBox>
                        {this.state.isChangePlanModalOpen && (
                            <ChangePlanModal
                                isChangePlanModalOpen={
                                    this.state.isChangePlanModalOpen
                                }
                                handleChangePlanModalOk={async () => {
                                    await this.setState({
                                        isPaymentConfirmModalOpen: true,
                                    });
                                    await this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                handleChangePlanModalCancel={() => {
                                    this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                setNewPlan={(value) => {
                                    this.setState({ newPlan: value });
                                }}
                            />
                        )}

                        {this.state.isPaymentConfirmModalOpen && (
                            <PaymentConfirmModal
                                newPlan={this.state.newPlan}
                                isPaymentConfirmModalOpen={
                                    this.state.isPaymentConfirmModalOpen
                                }
                                handlePaymentConfirmModalOk={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                                handlePaymentConfirmModalCancel={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                            />
                        )}
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    minHeight: "48rem",
    "& .app-content-title": {
        "& h1": {
            textAlign: "center",
        },
        "& p": {
            textAlign: "center",
        },
        margin: "4rem auto",
    },
    "& .app-content-control": {
        margin: "0 auto",
    },
    "& .remove-background-upload-image": {
        margin: "2rem auto 2rem auto",
        width: "64rem",
        textAlign: "center",

        "& .binary-mask-upload": {
            marginBottom: "4rem",
            "& svg": {
                width: "3rem",
                height: "3rem",
                fill: "$colors$primary",
            },
            "& span": {
                padding: "0",
                width: "100%",
                height: "16rem",
                display: "block",
                margin: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
        },
        "& .background-removed-image": {
            //maxWidth: "12rem",
            maxHeight: "14rem",
            margin: "auto 1rem",
        },
        "& h2": {
            marginBottom: "3rem",
        },
    },
    "& .remove-background-download-buttons": {
        margin: "5rem auto",
        display: "flex",
        justifyContent: "center",
        "& button": {
            margin: "0 0.5rem",
        },
    },
    "& .remove-background-content": {
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gap: "2rem",
        marginTop: "8rem",
        marginBottom: "4rem",
        "& button": {
            width: "100%",
        },
    },
    "& .binary-mask-sketch-canva": {
        border: "1px solid #9c9c9c",
        display: "block",
        "& img": {
            display: "block",
            margin: "auto",
            width: `calc(31rem - 2px)`,
        },
    },
    "& .binary-mask-brush-size": {
        textAlign: "left",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "1rem",
        "& h4": { marginLeft: "0.5rem" },
    },
    "& .binary-mask-result-image": {
        margin: "6rem auto 0 auto",
        "& img": {
            height: "40rem",
            margin: "0 auto",
        },
    },
    "& .upscale-option": {
        display: "flex",
        alignItems: "center",
    },
    "& .caption-before": {
        background: "#fff",
        border: "1px solid #c0c0c0",
        borderRadius: "12px",
        color: "#2e3452",
        opacity: "0.8",
        padding: "12px",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        lineHeight: "100%",
        left: "12px",
    },
    "& .caption-after": {
        background: "#fff",
        border: "1px solid #c0c0c0",
        borderRadius: "12px",
        color: "#2e3452",
        opacity: "0.8",
        padding: "12px",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        lineHeight: "100%",
        right: "12px",
    },
    "& .slider-with-animated-handle": {
        position: "relative",
        margin: "auto auto",
        //"& img": { maxHeight: "28rem" },
    },
    "& .remove-background-slider": {
        width: "60rem",
        height: "40rem",
        border: "1px solid $colors$borderColor",
        position: "relative",
        borderRadius: "0.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        "& img": {
            //width: "30rem",
            maxHeight: "40rem",
            margin: "0 auto",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        upscaledImage: state.patternReducer?.upscaleImage?.upscaledImage,
        uuid: state.patternReducer?.upscaleImage?.uuid,
    };
}

export default connect(mapStateToProps, {
    refetchUser,
    upscaleImage,
    upscaleImageDownload,
    clearUpscaledImage,
})(withRouter(UpscalerApp));
