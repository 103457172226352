import clsx from "clsx";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
} from "../../theme/BaseTheme";

export const TeeHow = () => {
    return (
        <PSection css={styles}>
            <PBox className="sell-how">
                <PBox className="sell-how-container">
                    <PText className="sell-how-subtitle">
                        Start Generating Tee Designs for Free
                    </PText>
                    <PH1 className="sell-how-title">
                        How Tee Design Generator Works
                    </PH1>

                    <PBox className="sell-how-steps-wrapper">
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-1"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>Compose Your Prompt</PH3>
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-2"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M88,24V16a8,8,0,0,1,16,0v8a8,8,0,0,1-16,0ZM16,104h8a8,8,0,0,0,0-16H16a8,8,0,0,0,0,16ZM124.42,39.16a8,8,0,0,0,10.74-3.58l8-16a8,8,0,0,0-14.31-7.16l-8,16A8,8,0,0,0,124.42,39.16Zm-96,81.69-16,8a8,8,0,0,0,7.16,14.31l16-8a8,8,0,1,0-7.16-14.31ZM219.31,184a16,16,0,0,1,0,22.63l-12.68,12.68a16,16,0,0,1-22.63,0L132.7,168,115,214.09c0,.1-.08.21-.13.32a15.83,15.83,0,0,1-14.6,9.59l-.79,0a15.83,15.83,0,0,1-14.41-11L32.8,52.92A16,16,0,0,1,52.92,32.8L213,85.07a16,16,0,0,1,1.41,29.8l-.32.13L168,132.69ZM208,195.31,156.69,144h0a16,16,0,0,1,4.93-26l.32-.14,45.95-17.64L48,48l52.2,159.86,17.65-46c0-.11.08-.22.13-.33a16,16,0,0,1,11.69-9.34,16.72,16.72,0,0,1,3-.28,16,16,0,0,1,11.3,4.69L195.31,208Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>Generate Your Designs</PH3>
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-3"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M197.58,129.06,146,110l-19-51.62a15.92,15.92,0,0,0-29.88,0L78,110l-51.62,19a15.92,15.92,0,0,0,0,29.88L78,178l19,51.62a15.92,15.92,0,0,0,29.88,0L146,178l51.62-19a15.92,15.92,0,0,0,0-29.88ZM137,164.22a8,8,0,0,0-4.74,4.74L112,223.85,91.78,169A8,8,0,0,0,87,164.22L32.15,144,87,123.78A8,8,0,0,0,91.78,119L112,64.15,132.22,119a8,8,0,0,0,4.74,4.74L191.85,144ZM144,40a8,8,0,0,1,8-8h16V16a8,8,0,0,1,16,0V32h16a8,8,0,0,1,0,16H184V64a8,8,0,0,1-16,0V48H152A8,8,0,0,1,144,40ZM248,88a8,8,0,0,1-8,8h-8v8a8,8,0,0,1-16,0V96h-8a8,8,0,0,1,0-16h8V72a8,8,0,0,1,16,0v8h8A8,8,0,0,1,248,88Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>Pick or Regenerate</PH3>
                            {/*<PH3>3. Generate Variations</PH3>
                            <PText>
                                Create similar pattern variations for a related,
                                unified, yet diverse collection.
                        </PText>*/}
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-4"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M200,48H179.31L165.66,34.34A8.07,8.07,0,0,0,160.05,32H96a8,8,0,0,0-5.66,2.34L76.69,48H56A16,16,0,0,0,40,64V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V64A16,16,0,0,0,200,48Zm-38.76,4.56L168,59.31V112L138.57,86.56ZM88,59.31l6.76-6.75,22.67,34L88,112ZM120,216H56V64H72v48a15.85,15.85,0,0,0,9.21,14.49A16.1,16.1,0,0,0,88,128a15.89,15.89,0,0,0,10.2-3.73.52.52,0,0,0,.11-.1L120,105.48ZM111,48h34.1L128,73.58ZM200,216H136V105.48l21.65,18.7a.52.52,0,0,0,.11.1A15.89,15.89,0,0,0,168,128a16.1,16.1,0,0,0,6.83-1.54A15.85,15.85,0,0,0,184,112V64h16Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>Export for Printing</PH3>
                            {/*<PH3>4. Edit and Export</PH3>
                            <PText>
                                Edit patterns, and download in multiple
                                dimensions, file types and resolutions.
                        </PText>*/}
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    backgroundColor: "rgb(238, 236, 255)",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-how": {
        padding: "4rem 1.5rem",
        maxWidth: "80rem",
        margin: "2rem auto",
        "@bp2": { marginTop: "2rem" },
        "@bp4": { paddingLeft: "4rem", paddingRight: "4rem" },
    },
    "& .sell-how-container": {
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
    },
    "& .sell-how-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .sell-how-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.5rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "3rem" },
    },
    "& .sell-how-text": {
        fontSize: "20px",
        lineHeight: "1.7",
        color: "rgb(75 85 99)",
        textAlign: "center",
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "80px",
    },
    "& .sell-how-steps-wrapper": {
        position: "relative",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        maxWidth: "80rem",
        marginTop: "2rem",
        marginLeft: "auto",
        marginRight: "auto",

        overflow: "hidden",
        display: "grid",
        maxWidth: "42rem",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gap: "2rem",
        "@bp4": {
            paddingLeft: "2rem",
            paddingRight: "2rem",
            maxWidth: "none",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "4rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
    },
    "& .sell-how-steps-item": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        "& h3": {
            marginBottom: "0",
            marginLeft: "0.125rem",
        },
        "& p": {
            lineHeight: "1.5",
            marginLeft: "0.125rem",
            color: "rgb(75 85 99)",
        },
        "& svg": {
            width: "2rem",
            height: "2rem",
            //padding: "0rem",
            //borderRadius: "50%",
            //fill: "$colors$primary",
            fill: "#fff",
            padding: "0.5rem",
            borderRadius: "0.5rem",
            backgroundColor: "$colors$primary",
        },
    },
    "& .sell-how-steps-step": {
        display: "flex",
        position: "relative",
        width: "100%",
    },
    "& .sell-how-steps-line": {
        width: "auto",
        height: "1px",
        position: "static",
        //backgroundColor: "$colors$borderColor",
        backgroundColor: "$colors$primary",
        marginLeft: "1rem",
        marginRight: "1rem",
        transform: "translate(0, 1.5rem)",
        flex: "1 1 auto",
    },
};
