import { PBox, PH1, PH2, PLink, PText } from "../theme/BaseTheme";

export const CallToAction = (props) => {
    return (
        <PBox css={styles}>
            <PBox className="call-to-action">
                <PH2>{props.title}</PH2>
                <PText>{props.subtitle}</PText>
                <PBox className="call-to-action-button">
                    <PLink href={props.link}>{props.cta}</PLink>
                </PBox>
            </PBox>
        </PBox>
    );
};

const styles = {
    padding: "0 1.5rem",
    marginTop: "8rem",
    marginBottom: "8rem",
    position: "relative",
    backgroundColor: "rgb(243 244 246)",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "@bp4": { padding: "0 2rem" },
    "& .call-to-action": {
        textAlign: "center",
        maxWidth: "48rem",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "2rem 0",
    },
    "& h2": {
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
        fontWeight: "700",
        color: "rgb(17 24 39)",
        maxWidth: "40rem",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "3rem" },
    },
    "& p": {
        color: "rgb(75 85 99)",
        lineHeight: "2rem",
        fontSize: "1.125rem",
        maxWidth: "48rem",
        marginTop: "1.5rem",
        marginLeft: "auto",
        marginRight: "auto",
    },
    "& .call-to-action-button": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2.5rem",
        "& a": {
            boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)",
            color: "#fff",
            fontWeight: "600",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "0.625rem 0.875rem",
            backgroundColor: "$colors$primary700",
            borderRadius: "0.375rem",
            textDecoration: "none",
            "&:hover": {
                backgroundColor: "$colors$primary500",
            },
        },
    },
};
