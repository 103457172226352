import { useState } from "react";
import { PBox, PSection, PText, PH1, PImg, PH2 } from "../../theme/BaseTheme";
import { keys } from "../../../config/keys";

export const PatternWhy = (props) => {
    const [displayImage, setDisplayImage] = useState(1);
    return (
        <PSection css={styles}>
            <PBox className="sell-why">
                <PBox className="sell-why-container">
                    <PText className="sell-why-subtitle">
                        Elevate Your Projects
                    </PText>
                    <PH1 className="sell-why-title">
                        Features That Empower Your Creativity
                    </PH1>

                    <PBox className="sell-why-reasons-wrapper">
                        <PBox>
                            {displayImage === 1 && (
                                <PImg
                                    alt="patternful ai"
                                    className={"sell-why-reasons-img"}
                                    src={keys.imageBaseUrl + "n1.webp"}
                                />
                            )}
                            {displayImage === 2 && (
                                <PImg
                                    alt="patternful ai"
                                    className={"sell-why-reasons-img"}
                                    src={keys.imageBaseUrl + "n2.webp"}
                                />
                            )}
                            {displayImage === 3 && (
                                <PImg
                                    alt="patternful ai"
                                    className={"sell-why-reasons-img"}
                                    src={keys.imageBaseUrl + "n3.webp"}
                                />
                            )}
                            {displayImage === 4 && (
                                <PImg
                                    alt="patternful ai"
                                    className={"sell-why-reasons-img"}
                                    src={keys.imageBaseUrl + "n4.webp"}
                                />
                            )}
                        </PBox>
                        <PBox className="sell-why-reasons">
                            {displayImage !== 1 && (
                                <PBox
                                    className="sell-why-reasons-item"
                                    onMouseEnter={() => {
                                        setDisplayImage(1);
                                    }}
                                >
                                    <PH2>Limitless Creativity</PH2>
                                    <PText>
                                        Discover limitless possibilities of
                                        patterns offered by AI that elevate your
                                        projects.
                                    </PText>
                                </PBox>
                            )}
                            {displayImage === 1 && (
                                <PBox
                                    className="sell-why-reasons-item-active"
                                    onMouseEnter={() => {
                                        setDisplayImage(1);
                                    }}
                                >
                                    <PH2>Limitless Creativity</PH2>
                                    <PText>
                                        Discover limitless possibilities of
                                        patterns offered by AI that elevate your
                                        projects.
                                    </PText>
                                </PBox>
                            )}
                            {displayImage !== 2 && (
                                <PBox
                                    className="sell-why-reasons-item"
                                    onMouseEnter={() => {
                                        setDisplayImage(2);
                                    }}
                                >
                                    <PH2>Text to Royalty-Free Patterns</PH2>
                                    <PText>
                                        Turn descriptions into diverse designs,
                                        perfect for commercial, personal, or
                                        creative projects.
                                    </PText>
                                </PBox>
                            )}
                            {displayImage === 2 && (
                                <PBox
                                    className="sell-why-reasons-item-active"
                                    onMouseEnter={() => {
                                        setDisplayImage(2);
                                    }}
                                >
                                    <PH2>Text to Royalty-Free Patterns</PH2>
                                    <PText>
                                        Turn descriptions into diverse designs,
                                        perfect for commercial, personal, or
                                        creative projects.
                                    </PText>
                                </PBox>
                            )}
                            {displayImage !== 3 && (
                                <PBox
                                    className="sell-why-reasons-item"
                                    onMouseEnter={() => {
                                        setDisplayImage(3);
                                    }}
                                >
                                    <PH2>Repeatable and Seamless</PH2>
                                    <PText>
                                        Generated patterns are guaranteed to be
                                        repeatable, without having any visual
                                        edges or seams.
                                    </PText>
                                </PBox>
                            )}
                            {displayImage === 3 && (
                                <PBox
                                    className="sell-why-reasons-item-active"
                                    onMouseEnter={() => {
                                        setDisplayImage(3);
                                    }}
                                >
                                    <PH2>Repeatable and Seamless</PH2>
                                    <PText>
                                        Generated patterns are guaranteed to be
                                        repeatable, without having any visual
                                        edges or seams.
                                    </PText>
                                </PBox>
                            )}
                            {displayImage !== 4 && (
                                <PBox
                                    className="sell-why-reasons-item"
                                    onMouseEnter={() => {
                                        setDisplayImage(4);
                                    }}
                                >
                                    <PH2>High Resolution</PH2>
                                    <PText>
                                        Upscale images to 2K or 4K resolutions
                                        for enhanced clarity and detail,
                                        suitable for various applications.
                                    </PText>
                                </PBox>
                            )}
                            {displayImage === 4 && (
                                <PBox
                                    className="sell-why-reasons-item-active"
                                    onMouseEnter={() => {
                                        setDisplayImage(4);
                                    }}
                                >
                                    <PH2>High Resolution</PH2>
                                    <PText>
                                        Upscale images to 2K or 4K resolutions
                                        for enhanced clarity and detail,
                                        suitable for various applications.
                                    </PText>
                                </PBox>
                            )}
                            {/*<PBox className="sell-why-reasons-item">
                                <PH2>Similar Variations</PH2>
                                <PText>
                                    Generate pattern variations for a related,
                                    unified, yet diverse collection. Great for
                                    branding, product ranges, or to provide
                                    clients with a range of cohesive options.
                                </PText>
    </PBox>*/}
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    "& .sell-why": {
        padding: "0 1.5rem",
        maxWidth: "80rem",
        margin: "8rem auto",
        "@bp2": { marginTop: "10rem" },
        "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    },
    "& .sell-why-container": {
        position: "relative",
    },
    "& .sell-why-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .sell-why-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "2.5rem" },
    },
    "& .sell-why-text": {
        fontSize: "20px",
        lineHeight: "1.7",
        color: "#293745",
        textAlign: "center",
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "80px",
    },
    "& .sell-why-reasons-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        gap: "2rem",
        alignItems: "center",
        maxWidth: "42rem",
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: "column",
        "@bp4": { flexDirection: "row", gap: "4rem", maxWidth: "56rem" },
    },
    "& .sell-why-reasons-img": {
        objectFit: "cover",
        borderRadius: "1rem",
        height: "42rem",
        aspectRatio: "3/4",
        width: "auto",
        margin: "1.5rem auto",
        border: "1px solid $colors$borderColor",
    },
    "& .sell-why-reasons": {
        width: "100%",
        "@bp4": { flex: "1 1 auto", maxWidth: "36rem" },
    },
    "& .sell-why-reasons-item": {
        padding: "0.5rem 1rem",
        margin: "0 2rem",
        borderBottom: "1px solid $colors$borderColor",
        "& h2": {
            color: "rgb(17 24 39)",
            fontWeight: "600",
            fontSize: "1.125rem",
            lineHeight: "1.5rem",
            width: "100%",
        },
        "& p": {
            color: "rgb(75 85 99)",
            lineHeight: "1.5rem",
            fontSize: "1rem",
            width: "100%",
            marginTop: "0.5rem",
        },
        "@bp4": { margin: "0" },
    },
    "& .sell-why-reasons-item-active": {
        padding: "0.5rem 1rem",
        margin: "0 2rem",
        borderBottom: "1px solid $colors$borderColor",
        backgroundColor: "$colors$lightPrimary",
        "& h2": {
            color: "$colors$primary",
            fontWeight: "600",
            fontSize: "1.125rem",
            lineHeight: "1.5rem",
            width: "100%",
        },
        "& p": {
            color: "$colors$primary",
            lineHeight: "1.5rem",
            fontSize: "1rem",
            width: "100%",
            marginTop: "0.5rem",
        },
        "@bp4": { margin: "0" },
    },
};
