import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { PERSIST, PURGE } from "redux-persist/es/constants";

import {
    createStateSyncMiddleware,
    initMessageListener,
} from "redux-state-sync";

import App from "./App";
import reducers from "./reducers";
import "./index.css";
import ReactGA from "react-ga4";

ReactGA.initialize("G-WGJG1N6BRN");

const persistConfig = {
    key: "root",
    storage,
    stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, reducers);

const reduxStateSyncConfig = {
    // Why adding these in the blacklist:
    // https://github.com/aohua/redux-state-sync/issues/53#issuecomment-597652537
    blacklist: [PERSIST, PURGE],
};
const middlewares = [createStateSyncMiddleware(reduxStateSyncConfig)];
const store = createStore(
    persistedReducer,
    {},
    applyMiddleware(reduxThunk, ...middlewares)
);
initMessageListener(store);

const persistor = persistStore(store);

// Remove logging messages in production
if (
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV !== "development"
) {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
