import { keyframes } from "@stitches/react";
import { PBox } from "../theme/BaseTheme";

// Google Fonts: Libre Barcode 128 Text
export const Loader = () => {
    return (
        <PBox css={styles}>
            <PBox className="loader"></PBox>
        </PBox>
    );
};

const rotate = keyframes({
    to: { transform: "translate(-50%, -50%) rotate(360deg)" },
});

const move = keyframes({
    to: { transform: "translateY(15px) rotate(45deg)" },
});

const styles = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    "& .loader": {
        margin: "auto auto",
        height: "80px",
        width: "80px",
        border: "7px solid #000",
        transform: "rotate(45deg)",
        borderRadius: "0 50% 50% 50%",
        position: "relative",
        animation: `${move} 0.5s linear infinite alternate-reverse`,
    },
    "& .loader::before": {
        content: "",
        position: "absolute",
        height: "55%",
        width: "55%",
        borderRadius: "50%",
        border: "7px solid transparent",
        borderTopColor: "#000",
        borderBottomColor: "#000",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        animation: `${rotate} 1s linear infinite`,
    },
};
