import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import AppRoutes from "./AppRoutes";
import "./App.css";
import { fetchUser } from "./actions/auth";
import history from "./history";
import ErrorBoundary from "./ErrorBoundary";

class App extends Component {
    componentDidMount() {
        this.props.fetchUser();
    }
    render() {
        return (
            <ErrorBoundary>
                <BrowserRouter history={history}>
                    <Helmet>
                        <title>Patternful AI</title>
                        <meta
                            name="description"
                            content="Patternful.ai is a platform offering AI-enhanced solutions and tools to generate and edit images that are ready-to-use for your brand, merchandise, or personal applications."
                        />
                        <meta
                            name="keywords"
                            content="Patternful, Patternful AI, AI Image Generation, AI Image Processing, AI Image Design, AI Image Editing, Seamless Pattern Generator, Mockup Generator, Background Remover, Binary Mask Generator, Image Upscaler, Digital Art, Graphic Design"
                        />
                    </Helmet>
                    <AppRoutes currentUser={this.props.userAuth} />
                </BrowserRouter>
            </ErrorBoundary>
        );
    }
}

function mapStateToProps(state) {
    console.log("state in app: ", state);
    return {
        userAuth: state.authReducer?.userAuth,
    };
}

export default connect(mapStateToProps, { fetchUser })(App);
