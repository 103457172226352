import { PBox, PContainer, PLink, PNav, PSection } from "../theme/BaseTheme";

export const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <PSection css={styles}>
            <PBox className="footer-wrapper">
                <PNav className="footer-nav">
                    <PBox className="footer-nav-item">
                        <PLink href="/pattern-gallery/1">Gallery</PLink>
                    </PBox>
                    <PBox className="footer-nav-item">
                        <PLink href="/pricing">Pricing</PLink>
                    </PBox>
                    <PBox className="footer-nav-item">
                        <PLink href="/terms">Terms</PLink>
                    </PBox>
                    <PBox className="footer-nav-item">
                        <PLink href="/privacy">Privacy</PLink>
                    </PBox>
                    <PBox className="footer-nav-item">
                        <PLink href="/blog">Blog</PLink>
                    </PBox>
                    {/*<PBox className="footer-nav-item">
                        <PLink href="/faqs">FAQ</PLink>
    </PBox>*/}
                    <PBox className="footer-nav-item">
                        <PLink href="/contact">Contact</PLink>
                    </PBox>
                    <PBox className="footer-nav-item">
                        <PLink href="/sitemap">Sitemap</PLink>
                    </PBox>
                    <PBox className="footer-nav-item">
                        <PLink
                            href="https://patternful.beehiiv.com/"
                            target="_blank"
                        >
                            Newsletter
                        </PLink>
                    </PBox>
                </PNav>
                <PBox className="footer-social">
                    <PLink
                        href="https://medium.com/@hello_83213/list/patternfulai-b427f6ac81f4"
                        target="_blank"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                        >
                            <path d="M72,64a64,64,0,1,0,64,64A64.07,64.07,0,0,0,72,64Zm0,112a48,48,0,1,1,48-48A48.05,48.05,0,0,1,72,176ZM184,64c-5.68,0-16.4,2.76-24.32,21.25C154.73,96.8,152,112,152,128s2.73,31.2,7.68,42.75C167.6,189.24,178.32,192,184,192s16.4-2.76,24.32-21.25C213.27,159.2,216,144,216,128s-2.73-31.2-7.68-42.75C200.4,66.76,189.68,64,184,64Zm0,112c-5.64,0-16-18.22-16-48s10.36-48,16-48,16,18.22,16,48S189.64,176,184,176ZM248,72V184a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Z"></path>
                        </svg>
                    </PLink>
                    <PLink
                        href="https://www.pinterest.com/phair_labs/_created"
                        target="_blank"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                        >
                            <path d="M216,112c0,22.57-7.9,43.2-22.23,58.11C180.39,184,162.25,192,144,192c-17.88,0-29.82-5.86-37.43-12L95.79,225.83A8,8,0,0,1,88,232a8.24,8.24,0,0,1-1.84-.21,8,8,0,0,1-6-9.62l32-136a8,8,0,0,1,15.58,3.66l-16.9,71.8C114,166,123.3,176,144,176c27.53,0,56-23.94,56-64A72,72,0,1,0,65.63,148a8,8,0,0,1-13.85,8A88,88,0,1,1,216,112Z"></path>
                        </svg>
                    </PLink>
                    <PLink href="https://twitter.com/PhairLabs" target="_blank">
                        <svg
                            viewBox="0 0 24 24"
                            class="oc se"
                            aria-hidden="true"
                        >
                            <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z"></path>
                        </svg>
                    </PLink>
                    <PLink
                        href="https://www.instagram.com/phair_labs/"
                        target="_blank"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                        >
                            <path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160ZM176,24H80A56.06,56.06,0,0,0,24,80v96a56.06,56.06,0,0,0,56,56h96a56.06,56.06,0,0,0,56-56V80A56.06,56.06,0,0,0,176,24Zm40,152a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V80A40,40,0,0,1,80,40h96a40,40,0,0,1,40,40ZM192,76a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z"></path>
                        </svg>
                    </PLink>
                    {/*<PLink
                        href="https://patternfulai.quora.com/"
                        target="_blank"
                        className="quora-link"
                    >
                        <svg
                            viewBox="0 0 48 48"
                            id="a"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                class="b"
                                fill="none"
                                stroke="rgb(156 163 175)"
                                stroke-width="2.7"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M40.6,37.6l4.3,4.6c.4,.4,.6,.8,.6,1.4,0,1.1-.9,1.9-1.9,1.9H24c-11.9,0-21.5-9.6-21.5-21.5S12.1,2.5,24,2.5s21.5,9.6,21.5,21.5c0,5.2-1.8,9.9-4.9,13.6h0Z"
                            />
                            <circle
                                class="b"
                                cx="24"
                                cy="24"
                                r="10"
                                fill="#fff"
                                stroke="rgb(156 163 175)"
                                stroke-width="2.7"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
</PLink>*/}
                </PBox>
                <PBox className="footer-copyright">
                    © {year} Phair Labs. All Rights Reserved.
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    borderTop: "1px solid $colors$borderColor",
    "& .footer-wrapper": {
        padding: "2rem 1.5rem 2rem 1.5rem",
        overflow: "hidden",
        maxWidth: "80rem",
        margin: "0 auto",
        "@bp2": {
            padding: "3rem 1.5rem 3rem 1.5rem",
        },
        "@bp4": {
            padding: "3rem 2rem 3rem 2rem",
        },
    },
    "& .footer-nav": {
        columns: "2",
        marginBottom: "-1.5rem",
        "@bp2": {
            justifyContent: "center",
            display: "flex",
        },
    },
    "& .footer-nav-item": {
        paddingBottom: "1.5rem",
        margin: "0 1.5rem",
        "& a": {
            color: "rgb(107 114 128)",
            lineHeight: "1.5rem",
            fontSize: "1rem",
            textDecoration: "none",
        },
        "& a:hover": {
            color: "rgb(75 85 99)",
        },
    },
    "& .footer-social": {
        display: "flex",
        justifyContent: "center",
        marginTop: "2.5rem",
        "& a": {
            margin: "0 0.75rem",
        },
        "& svg": {
            fill: "rgb(156 163 175)",
            width: "1.5rem",
            height: "1.5rem",
        },
        "& svg:hover": {
            fill: "rgb(75 85 99)",
        },
    },
    "& .quora-link": {
        "& svg": {
            stroke: "rgb(156 163 175)",
            width: "1.4rem",
            height: "1.4rem",
        },
        "& svg:hover": {
            "& path": { stroke: "rgb(75 85 99)" },
            "& circle": { stroke: "rgb(75 85 99)" },
        },
    },
    "& .footer-copyright": {
        color: "rgb(107 114 128)",
        lineHeight: "1.25rem",
        fontSize: "0.75rem",
        textAlign: "center",
        marginTop: "2.5rem",
    },
    /*"& .footer": {
        backgroundColor: "$colors$lightBackground",
        paddingTop: "40px",
        paddingBottom: "40px",
        color: "$colors$text",
    },
    "& .footer > div": { display: "flex", justifyContent: "space-between" },
    "& .footer-links > a": {
        paddingLeft: "10px",
        paddingRight: "10px",
        color: "$colors$text",
        fontWeight: "500",
        textDecoration: "none",
        fontSize: "1rem",
        "&:hover": { color: "$colors$link", fontWeight: "700" },
    },
    "& .copyright": { color: "$colors$text", fontSize: "1rem" },*/
};
