import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import download from "downloadjs";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { Button, Card, Image, message, Upload, Select, Badge, Tag } from "antd";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PH4, PH3, PImg, PText, PSpan } from "../../theme/BaseTheme";
import {
    fetchMockupTemplates,
    downloadEditedImage,
    uploadAndGenerateMockup,
    clearStoredMockup,
} from "../../../actions/pattern";
import { refetchUser } from "../../../actions/auth";
import ChangePlanModal from "../../components/app/ChangePlanModal";
import PaymentConfirmModal from "../../components/app/PaymentConfirmModal";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";

const mockupOptions = [
    { value: "technology", label: "Technology" },
    { value: "print", label: "Print" },
    { value: "packaging", label: "Packaging" },
    { value: "apparel", label: "Apparel" },
    { value: "home_and_living", label: "Home & Living" },
];

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

class MockupApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isApplyingMockup: false,
            mockupUUID: "",
            mockupCredits: 0,
            uploadedImage: "",
            fileList: [],
            isChangePlanModalOpen: false,
            isPaymentConfirmModalOpen: false,
            newPlan: "",
        };
        this.handlePreview = this.handlePreview.bind(this);
    }
    async componentDidMount() {
        await this.props.clearStoredMockup();
        await this.props.fetchMockupTemplates("print");
        await this.setState({ isLoading: false });
    }

    handlePreview = async ({ file }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            uploadedImage: file.url || file.preview,
        });
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Mockup Generator App</title>
                    <meta
                        name="description"
                        content="Create stunning mockups online with one click. Forget about spending hours learning how to use Photoshop. With just one click, you can produce beautiful and near-perfect mockup images."
                    />
                    <meta
                        name="keywords"
                        content="Mockup Generator, Mockup Generator APP, Mockup Online Generator, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="app-content">
                        <PBox className="app-content-control">
                            <Card title={"Mockup"}>
                                <PBox>
                                    1. Please select a mockup image to apply.
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        options={mockupOptions}
                                        onChange={async (value) => {
                                            await this.props.fetchMockupTemplates(
                                                value
                                            );
                                        }}
                                        defaultValue={"print"}
                                    />
                                    <PBox className="app-content-mockups">
                                        {this.props.mockup_templates?.map(
                                            (template, id) => {
                                                return (
                                                    <PBox className="app-content-mockups-item">
                                                        <PImg
                                                            alt="patternful ai"
                                                            className={clsx(
                                                                this.state
                                                                    .mockupUUID ===
                                                                    template?.uuid
                                                                    ? "mockup-img-active"
                                                                    : ""
                                                            )}
                                                            src={`https://s3.us-east-2.amazonaws.com/pattern.bucket.prod.mockups/${template?.uuid}.jpg`}
                                                            onClick={() => {
                                                                this.setState({
                                                                    mockupUUID:
                                                                        template?.uuid,
                                                                    mockupCredits:
                                                                        template?.credits,
                                                                });
                                                            }}
                                                        />
                                                        {template?.credits !==
                                                            0 && (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="#000000"
                                                                viewBox="0 0 256 256"
                                                            >
                                                                <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                                            </svg>
                                                        )}
                                                    </PBox>
                                                );
                                            }
                                        )}
                                    </PBox>
                                    <Button
                                        type="primary"
                                        ghost
                                        loading={this.state.isApplyingMockup}
                                        onClick={async () => {
                                            if (
                                                this.state.uploadedImage === ""
                                            ) {
                                                message.error(
                                                    "Please upload a design image."
                                                );
                                            } else {
                                                if (
                                                    this.state.mockupCredits !==
                                                        0 &&
                                                    !this.props.userAccount
                                                ) {
                                                    //this.props.history.push(
                                                    //</PBox>    "/sign-in"
                                                    //);
                                                    window
                                                        .open(
                                                            "/sign-in",
                                                            "_blank"
                                                        )
                                                        .focus();
                                                } else if (
                                                    this.state.mockupCredits !==
                                                        0 &&
                                                    this.props.userAccount
                                                        ?.credits === 0
                                                ) {
                                                    this.setState({
                                                        isChangePlanModalOpen: true,
                                                    });
                                                } else {
                                                    await this.setState({
                                                        isApplyingMockup: true,
                                                    });
                                                    message.open({
                                                        type: "loading",
                                                        content:
                                                            "Applying the pattern to template.",
                                                        duration: 0,
                                                    });
                                                    await this.props.uploadAndGenerateMockup(
                                                        this.state
                                                            .uploadedImage,
                                                        this.state.mockupUUID,
                                                        this.state.mockupCredits
                                                    );
                                                    message.destroy();
                                                    await this.props.refetchUser();
                                                    await this.setState({
                                                        isApplyingMockup: false,
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        Apply
                                    </Button>{" "}
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={() => {
                                            download(
                                                this.props.mockup,
                                                `img.png`,
                                                `image/png`
                                            );
                                        }}
                                    >
                                        Download
                                    </Button>
                                </PBox>
                            </Card>
                        </PBox>
                        <PBox className="app-content-crop-and-mockup">
                            <PBox className="app-content-crop">
                                <PBox className="app-content-mockup-display">
                                    <PBox className="app-content-mockup-and-pattern">
                                        {/*<PImg src={this.props.pattern?.url} />*/}
                                        {this.state.mockupUUID !== "" && (
                                            <PImg
                                                src={`https://s3.us-east-2.amazonaws.com/pattern.bucket.prod.mockups/${this.state.mockupUUID}.jpg`}
                                            />
                                        )}
                                        <Upload
                                            onChange={this.handlePreview}
                                            showUploadList={false}
                                            listType="picture-card"
                                            maxCount={1}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng =
                                                    file.type ===
                                                        "image/jpeg" ||
                                                    file.type === "image/png";
                                                if (!isJpgOrPng) {
                                                    message.error(
                                                        "You can only upload JPG/PNG file."
                                                    );
                                                }
                                                const isLt2M =
                                                    file.size / 1024 / 1024 < 2;
                                                if (!isLt2M) {
                                                    message.error(
                                                        "Image must smaller than 2MB."
                                                    );
                                                }
                                                return (
                                                    (isJpgOrPng && isLt2M) ||
                                                    Upload.LIST_IGNORE
                                                );
                                            }}
                                        >
                                            {this.state.uploadedImage ? (
                                                <PImg
                                                    src={
                                                        this.state.uploadedImage
                                                    }
                                                />
                                            ) : (
                                                <>
                                                    2. Upload <br /> Your Design
                                                </>
                                            )}
                                        </Upload>
                                    </PBox>
                                    <PBox className="app-content-mockup-applied">
                                        <Image src={this.props.mockup} />
                                    </PBox>
                                </PBox>
                            </PBox>
                        </PBox>
                        {this.state.isChangePlanModalOpen && (
                            <ChangePlanModal
                                isChangePlanModalOpen={
                                    this.state.isChangePlanModalOpen
                                }
                                handleChangePlanModalOk={async () => {
                                    await this.setState({
                                        isPaymentConfirmModalOpen: true,
                                    });
                                    await this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                handleChangePlanModalCancel={() => {
                                    this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                setNewPlan={(value) => {
                                    this.setState({ newPlan: value });
                                }}
                            />
                        )}

                        {this.state.isPaymentConfirmModalOpen && (
                            <PaymentConfirmModal
                                newPlan={this.state.newPlan}
                                isPaymentConfirmModalOpen={
                                    this.state.isPaymentConfirmModalOpen
                                }
                                handlePaymentConfirmModalOk={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                                handlePaymentConfirmModalCancel={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                            />
                        )}
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    "& .app-content": {
        display: "grid",
        gap: "1rem",
        alignItems: "flex-start",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "@bp4": {
            gap: "2rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
        padding: "3rem 2rem",
        //maxWidth: "80rem",
        margin: "0 auto",
    },
    "& .app-content-control": {
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "& h3": {
            marginTop: 0,
        },
    },
    "& .app-content-crop-and-mockup": {
        "@bp4": {
            gridColumn: "span 3 / span 3",
        },
    },
    "& .app-content-mockups": {
        margin: "1rem 0",
        //border: "1px solid $colors$borderColor",
        overflow: "scroll",
        height: "500px",
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "& .app-content-mockups-item": {
            position: "relative",
            "& img": {
                //border: "1px solid $colors$borderColor",
                maxWidth: `calc(100% - 1rem)`,
                //maxHeight: "12rem",
                margin: "0.5rem auto",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
            },
            "& svg": {
                position: "absolute",
                top: "5%",
                right: "5%",
                fill: "#d48806",
                backgroundColor: "#ffe58f",
                padding: "0.125rem 0.25rem",
                borderRadius: "0.25rem",
                width: "1rem",
                height: "1rem",
            },
        },
    },
    "& .mockup-img-active": {
        border: "0.25rem solid $colors$primary",
        padding: "0.25rem",
    },
    "& .app-content-mockup-display": {
        border: "1px solid $colors$borderColor",
        padding: "1rem",
        borderRadius: "0.5rem",
    },
    "& .app-content-mockup-and-pattern": {
        display: "flex",
        justifyContent: "start",
        "& span": { display: "block" },
        "& img": {
            maxHeight: "6rem",
            maxWidth: "6rem",
            margin: "0 0.5rem",
            borderRadius: "0.5rem",
        },
    },
    "& .app-content-mockup-applied": {
        display: "flex",
        justifyContent: "center",
        "& img": {
            height: "560px",
            margin: "0.5rem auto",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        mockup: state.patternReducer?.generateMockup?.mockup,
        mockup_templates:
            state.patternReducer?.fetchMockupTemplates?.mockup_templates,
    };
}

export default connect(mapStateToProps, {
    fetchMockupTemplates,
    downloadEditedImage,
    uploadAndGenerateMockup,
    clearStoredMockup,
    refetchUser,
})(withRouter(MockupApp));
