/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useWindowSize } from "react-use";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import inpaint from "../../../tools/inpaint-web/inpainting";
import { loadingOnnxruntime } from "../../../tools/inpaint-web/util";
import { downloadImage, loadImage, useImage } from "./utils";
import { DownloadOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Tooltip, Slider, Progress, Modal, Card } from "antd";
import { spendCredits } from "../../../actions/auth";
import ChangePlanModal from "./ChangePlanModal";
import PaymentConfirmModal from "./PaymentConfirmModal";

const m = {
    $schema: "https://inlang.com/schema/inlang-message-format",
    drop_zone: "Click or drag here",
    try_it_images: "Try it:",
    feedback: "About me",
    start_new: "Start new",
    bruch_size: "Brush Size",
    original: "Original",
    upscale: "4x-upscaling",
    download: "Download",
    undo: "Undo",
    inpaint_model_download_message:
        "Need to download a 30MB model file, please wait patiently...",
    upscaleing_model_download_message:
        "Need to download a 70MB model file, please wait patiently...",
};

loadingOnnxruntime();

function drawLines(ctx, lines, color = "rgba(109, 40, 217, 0.4)") {
    ctx.strokeStyle = color;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    lines.forEach((line) => {
        if (!line?.pts.length || !line.size) {
            return;
        }
        ctx.lineWidth = line.size;
        ctx.beginPath();
        ctx.moveTo(line.pts[0].x, line.pts[0].y);
        line.pts.forEach((pt) => ctx.lineTo(pt.x, pt.y));
        ctx.stroke();
    });
}

const BRUSH_HIDE_ON_SLIDER_CHANGE_TIMEOUT = 2000;
const RemoveObjectEditor = (props) => {
    const { file } = props;
    const [brushSize, setBrushSize] = useState(60);
    const [original, isOriginalLoaded] = useImage(file);
    const [renders, setRenders] = useState([]);
    const [context, setContext] = useState();
    const [maskCanvas] = useState(() => {
        return document.createElement("canvas");
    });
    const [lines, setLines] = useState([{ pts: [], src: "" }]);
    const brushRef = useRef(null);
    const [showBrush, setShowBrush] = useState(false);
    const [hideBrushTimeout, setHideBrushTimeout] = useState(0);
    const [showOriginal, setShowOriginal] = useState(false);
    const [isInpaintingLoading, setIsProcessingLoading] = useState(false);
    const [generateProgress, setGenerateProgress] = useState(0);
    const modalRef = useRef(null);
    const [separator, setSeparator] = useState();
    const [useSeparator, setUseSeparator] = useState(false);
    const [originalImg, setOriginalImg] = useState();
    const [separatorLeft, setSeparatorLeft] = useState(0);
    const historyListRef = useRef(null);
    const isBrushSizeChange = useRef(false);
    const scaledBrushSize = useMemo(() => brushSize, [brushSize]);
    const canvasDiv = useRef(null);
    const [downloaded, setDownloaded] = useState(true);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const windowSize = useWindowSize();
    const [isChangePlanModalOpen, setIsChangePlanModalOpen] = useState(false);
    const [isPaymentConfirmModalOpen, setIsPaymentConfirmModalOpen] =
        useState(false);
    const [newPlan, setNewPlan] = useState("");

    const draw = useCallback(
        (index = -1) => {
            if (!context) {
                return;
            }
            context.clearRect(
                0,
                0,
                context.canvas.width,
                context.canvas.height
            );
            const currRender =
                renders[index === -1 ? renders.length - 1 : index] ?? original;
            const { canvas } = context;

            const divWidth = canvasDiv.current?.offsetWidth;
            const divHeight = canvasDiv.current?.offsetHeight;

            // 计算宽高比
            const imgAspectRatio = currRender.width / currRender.height;
            const divAspectRatio = divWidth / divHeight;

            let canvasWidth;
            let canvasHeight;

            // 比较宽高比以决定如何缩放
            if (divAspectRatio > imgAspectRatio) {
                // div 较宽，基于高度缩放
                canvasHeight = divHeight;
                canvasWidth =
                    currRender.width * (divHeight / currRender.height);
            } else {
                // div 较窄，基于宽度缩放
                canvasWidth = divWidth;
                canvasHeight =
                    currRender.height * (divWidth / currRender.width);
            }

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            if (currRender?.src) {
                context.drawImage(
                    currRender,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
            } else {
                context.drawImage(original, 0, 0, canvas.width, canvas.height);
            }
            const currentLine = lines[lines.length - 1];
            drawLines(context, [currentLine]);
        },
        [context, lines, original, renders]
    );

    const refreshCanvasMask = useCallback(() => {
        if (!context?.canvas.width || !context?.canvas.height) {
            throw new Error("canvas has invalid size");
        }
        maskCanvas.width = context?.canvas.width;
        maskCanvas.height = context?.canvas.height;
        const ctx = maskCanvas.getContext("2d");
        if (!ctx) {
            throw new Error("could not retrieve mask canvas");
        }
        // Just need the finishing touch
        const line = lines.slice(-1)[0];
        if (line) drawLines(ctx, [line], "white");
    }, [context?.canvas.height, context?.canvas.width, lines, maskCanvas]);

    // Draw once the original image is loaded
    useEffect(() => {
        if (!context?.canvas) {
            return;
        }
        if (isOriginalLoaded) {
            draw();
        }
    }, [context?.canvas, draw, original, isOriginalLoaded, windowSize]);

    // Handle mouse interactions
    useEffect(() => {
        const canvas = context?.canvas;
        if (!canvas) {
            return;
        }
        const onPaint = (px, py) => {
            const currLine = lines[lines.length - 1];
            currLine.pts.push({ x: px, y: py });
            draw();
        };
        const onMouseDrag = (ev) => {
            const px = ev.offsetX - canvas.offsetLeft;
            const py = ev.offsetY - canvas.offsetTop;
            onPaint(px, py);
        };

        const onPointerUp = async () => {
            if (!original.src || showOriginal) {
                return;
            }
            if (lines.slice(-1)[0]?.pts.length === 0) {
                return;
            }
            const loading = onloading();
            canvas.removeEventListener("mousemove", onMouseDrag);
            canvas.removeEventListener("mouseup", onPointerUp);
            refreshCanvasMask();
            try {
                const start = Date.now();
                console.log("inpaint_start");
                // each time based on the last result, the first is the original
                const newFile = renders.slice(-1)[0] ?? file;
                const res = await inpaint(newFile, maskCanvas.toDataURL());
                if (!res) {
                    throw new Error("empty response");
                }
                // TODO: fix the render if it failed loading
                const newRender = new Image();
                newRender.dataset.id = Date.now().toString();
                await loadImage(newRender, res);
                renders.push(newRender);
                lines.push({ pts: [], src: "" });
                setRenders([...renders]);
                setLines([...lines]);
                console.log("inpaint_processed", {
                    duration: Date.now() - start,
                });
            } catch (e) {
                console.log("inpaint_failed", {
                    error: e,
                });
                // eslint-disable-next-line
                alert(e.message ? e.message : e.toString());
            }
            if (historyListRef.current) {
                const { scrollWidth, clientWidth } = historyListRef.current;
                if (scrollWidth > clientWidth) {
                    historyListRef.current.scrollTo(scrollWidth, 0);
                }
            }
            loading.close();
            draw();
        };

        const onTouchMove = (ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            const currLine = lines[lines.length - 1];
            const coords = canvas.getBoundingClientRect();
            currLine.pts.push({
                x: ev.touches[0].clientX - coords.x,
                y: ev.touches[0].clientY - coords.y,
            });
            draw();
        };
        const onPointerStart = () => {
            if (!original.src || showOriginal) {
                return;
            }
            const currLine = lines[lines.length - 1];
            currLine.size = brushSize;
            canvas.addEventListener("mousemove", onMouseDrag);
            canvas.addEventListener("mouseup", onPointerUp);
            // onPaint(e)
        };

        canvas.addEventListener("touchstart", onPointerStart);
        canvas.addEventListener("touchmove", onTouchMove);
        canvas.addEventListener("touchend", onPointerUp);
        canvas.addEventListener(
            "contextmenu",
            function (e) {
                if (e.button === 2) {
                    e.preventDefault();
                    return false;
                }
            },
            false
        );
        canvas.onmouseenter = () => {
            window.clearTimeout(hideBrushTimeout);
            setShowBrush(true && !showOriginal);
        };
        canvas.onmouseleave = () => setShowBrush(false);
        canvas.onmousedown = onPointerStart;

        return () => {
            canvas.removeEventListener("mousemove", onMouseDrag);
            canvas.removeEventListener("mouseup", onPointerUp);
            canvas.removeEventListener("touchstart", onPointerStart);
            canvas.removeEventListener("touchmove", onTouchMove);
            canvas.removeEventListener("touchend", onPointerUp);
            canvas.removeEventListener("contextmenu", function (e) {
                if (e.button === 2) {
                    e.preventDefault();
                    return false;
                }
            });
            canvas.onmouseenter = null;
            canvas.onmouseleave = null;
            canvas.onmousedown = null;
        };
    }, [
        brushSize,
        context,
        file,
        draw,
        lines,
        refreshCanvasMask,
        maskCanvas,
        original.src,
        renders,
        showOriginal,
        hideBrushTimeout,
    ]);

    useEffect(() => {
        if (!separator || !originalImg) return;

        const separatorMove = (ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            if (context?.canvas) {
                const { width } = context?.canvas;
                const canvasRect = context?.canvas.getBoundingClientRect();
                const separatorOffsetLeft = ev.pageX - canvasRect.left;
                if (separatorOffsetLeft <= width && separatorOffsetLeft >= 0) {
                    setSeparatorLeft(separatorOffsetLeft);
                } else if (separatorOffsetLeft < 0) {
                    setSeparatorLeft(0);
                } else if (separatorOffsetLeft > width) {
                    setSeparatorLeft(width);
                }
            }
        };

        const separatorDown = () => {
            window.addEventListener("mousemove", separatorMove);
            setUseSeparator(true);
        };

        const separatorUp = () => {
            window.removeEventListener("mousemove", separatorMove);
            setUseSeparator(false);
        };

        separator.addEventListener("mousedown", separatorDown);
        window.addEventListener("mouseup", separatorUp);

        return () => {
            separator.removeEventListener("mousedown", separatorDown);
            window.removeEventListener("mouseup", separatorUp);
        };
    }, [separator, context]);

    function download() {
        console.log(props);
        if (!props.userAccount) {
            window.open("/sign-in", "_blank").focus();
        } else if (props.userAccount?.credits === 0) {
            setIsChangePlanModalOpen(true);
        } else {
            const currRender = renders.at(-1) ?? original;
            downloadImage(currRender.currentSrc, "IMG");
            props.spendCredits(1);
        }
    }

    const undo = useCallback(async () => {
        const l = lines;
        l.pop();
        l.pop();
        setLines([...l, { pts: [], src: "" }]);
        const r = renders;
        r.pop();
        setRenders([...r]);
    }, [lines, renders]);

    useEffect(() => {
        const handler = (event) => {
            if (!renders.length) {
                return;
            }
            const isCmdZ =
                (event.metaKey || event.ctrlKey) && event.key === "z";
            if (isCmdZ) {
                event.preventDefault();
                undo();
            }
        };
        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [renders, undo]);

    const backTo = useCallback(
        (index) => {
            lines.splice(index + 1);
            setLines([...lines, { pts: [], src: "" }]);
            renders.splice(index + 1);
            setRenders([...renders]);
        },
        [renders, lines]
    );

    const History = useMemo(
        () =>
            renders.map((render, index) => {
                return (
                    <div
                        key={render.dataset.id}
                        style={{
                            position: "relative",
                            display: "inline-block",
                            flexShrink: 0,
                        }}
                    >
                        <Tooltip
                            placement="bottom"
                            title="Back here. Any modifications made beyond this point will not be saved."
                        >
                            <img
                                src={render.src}
                                alt="render"
                                className="rounded-sm"
                                style={{
                                    height: "90px",
                                    borderRadius: "0.125rem",
                                    border: "2px solid white",
                                }}
                            />
                            <Button
                                className="hover:opacity-100 opacity-0 cursor-pointer rounded-sm"
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "0.125rem",
                                    cursor: "pointer",
                                    opacity: 0,
                                }}
                                onClick={() => backTo(index)}
                                onMouseEnter={() => draw(index)}
                                onMouseLeave={() => draw(renders.length - 1)}
                            >
                                <div
                                    style={{
                                        color: "#fff",
                                        fontSize: "12px",
                                        textAlign: "center",
                                    }}
                                >
                                    Back here
                                </div>
                            </Button>
                        </Tooltip>
                    </div>
                );
            }),
        [renders, backTo]
    );

    const handleSliderStart = () => {
        setShowBrush(true);
    };
    const handleSliderChange = (sliderValue) => {
        if (!isBrushSizeChange.current) {
            isBrushSizeChange.current = true;
        }
        if (brushRef.current) {
            const x =
                document.documentElement.clientWidth / 2 - scaledBrushSize / 2;
            const y =
                document.documentElement.clientHeight / 2 - scaledBrushSize / 2;

            brushRef.current.style.transform = `translate3d(${x}px, ${y}px, 0)`;
        }
        setBrushSize(sliderValue);
        window.clearTimeout(hideBrushTimeout);
        setHideBrushTimeout(
            window.setTimeout(() => {
                setShowBrush(false);
            }, BRUSH_HIDE_ON_SLIDER_CHANGE_TIMEOUT)
        );
    };

    const onloading = useCallback(() => {
        setIsProcessingLoading(true);
        setGenerateProgress(0);
        const progressTimer = window.setInterval(() => {
            setGenerateProgress((p) => {
                if (p < 90) return p + 10 * Math.random();
                if (p >= 90 && p < 99) return p + 1 * Math.random();
                // Do not hide the progress bar after 99%,cause sometimes long time progress
                // window.setTimeout(() => setIsInpaintingLoading(false), 500)
                return p;
            });
        }, 1000);
        return {
            close: () => {
                clearInterval(progressTimer);
                setGenerateProgress(100);
                setIsProcessingLoading(false);
            },
        };
    }, []);

    return (
        <div
            className={[
                "flex flex-col items-center h-full justify-between",
                isInpaintingLoading
                    ? "animate-pulse-fast pointer-events-none"
                    : "",
            ].join(" ")}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
            }}
        >
            {/* History */}
            <div
                ref={historyListRef}
                style={{
                    height: "116px",
                    flexShrink: "0",
                    padding: "0.75rem",
                    marginTop: "6rem",
                    borderRadius: "0.25rem",
                    borderWidth: "1px",
                    display: "flex",
                    width: "100%",
                    maxWidth: "56rem",
                    marginLeft: "1.25rem",
                    flexDirection: "row",
                    overflowX: "scroll",
                }}
                className={[
                    "flex-shrink-0",
                    "mt-4 border p-3 rounded",
                    "flex items-left w-full max-w-4xl",
                    "space-y-0 flex-row space-x-5",
                    "scrollbar-thin scrollbar-thumb-black scrollbar-track-primary overflow-x-scroll",
                ].join(" ")}
            >
                {History}
            </div>
            {/* Canva */}
            <div
                className={[
                    "flex-grow",
                    "flex justify-center",
                    "my-2",
                    "relative",
                ].join(" ")}
                style={{
                    width: "70vw",
                    height: "600px",
                    display: "flex",
                    flexGrow: "1",
                    justifyContent: "center",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    position: "relative",
                }}
                ref={canvasDiv}
            >
                <div className="relative" style={{ position: "relative" }}>
                    <canvas
                        id="object-remover-canvas"
                        className="rounded-sm"
                        style={{
                            borderRadius: "0.125rem",
                            cursor: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="${scaledBrushSize}px" height="${scaledBrushSize}px" viewBox="0 0 48 48" fill="none"><circle cx="24" cy="24" r="23.5" fill="%236729F3" fill-opacity="0.43" stroke="%23F8F8F8"/></svg>') ${
                                scaledBrushSize / 2
                            } ${scaledBrushSize / 2}, auto`,
                        }}
                        ref={(r) => {
                            if (r && !context) {
                                const ctx = r.getContext("2d");
                                if (ctx) {
                                    setContext(ctx);
                                }
                            }
                        }}
                    />
                    {isInpaintingLoading && (
                        <div
                            style={{
                                zIndex: "10",
                                backgroundColor: "#ffffff",
                                position: "absolute",
                                "--bg-opacity": "0.8",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            className="z-10 bg-white absolute bg-opacity-80 top-0 left-0 right-0 bottom-0  h-full w-full flex justify-center items-center"
                        >
                            <div
                                ref={modalRef}
                                style={{
                                    marginTop: "1.25rem",
                                    width: "80%",
                                    fontSize: "1.25rem",
                                    lineHeight: "1.75rem",
                                    "@media (min-width: 640px)": {
                                        width: "50%",
                                    },
                                }}
                                className="text-xl space-y-5 w-4/5 sm:w-1/2"
                            >
                                <p>
                                    It is being processed, please be patient...
                                </p>
                                <Progress percent={generateProgress} />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {!downloaded && (
                <Modal>
                    <div
                        className="text-xl space-y-5"
                        style={{
                            marginTop: "1.25rem",
                            fontSize: "1.25rem",
                            lineHeight: "1.75rem",
                        }}
                    >
                        <p>{m.upscaleing_model_download_message}</p>
                        <Progress percent={downloadProgress} />
                    </div>
                </Modal>
            )}
            {/* Toolbar */}
            <Card style={{ width: "100%" }}>
                <h4 style={{ textAlign: "left" }}>Brush Size: {brushSize}</h4>
                <Slider
                    label={m.bruch_size}
                    min={10}
                    max={200}
                    value={brushSize}
                    onChange={handleSliderChange}
                    onStart={handleSliderStart}
                />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "2rem",
                    }}
                >
                    {renders.length > 0 && (
                        <Button
                            onClick={undo}
                            icon={<UndoOutlined />}
                            style={{ marginRight: "1rem" }}
                        >
                            {m.undo}
                        </Button>
                    )}
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={download}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {m.download}{" "}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#000000"
                            viewBox="0 0 256 256"
                            style={{
                                fill: "#d48806",
                                backgroundColor: "#ffe58f",
                                padding: "0.125rem 0.25rem",
                                borderRadius: "0.25rem",
                                width: "1rem",
                                height: "1rem",
                                marginLeft: "0.5rem",
                            }}
                        >
                            <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                        </svg>
                    </Button>
                </div>
            </Card>
            {isChangePlanModalOpen && (
                <ChangePlanModal
                    isChangePlanModalOpen={isChangePlanModalOpen}
                    handleChangePlanModalOk={async () => {
                        await setIsPaymentConfirmModalOpen(true);
                        await setIsChangePlanModalOpen(false);
                    }}
                    handleChangePlanModalCancel={() => {
                        setIsChangePlanModalOpen(false);
                    }}
                    setNewPlan={(value) => {
                        setNewPlan(value);
                    }}
                />
            )}

            {isPaymentConfirmModalOpen && (
                <PaymentConfirmModal
                    newPlan={newPlan}
                    isPaymentConfirmModalOpen={isPaymentConfirmModalOpen}
                    handlePaymentConfirmModalOk={() =>
                        setIsPaymentConfirmModalOpen(false)
                    }
                    handlePaymentConfirmModalCancel={() =>
                        setIsPaymentConfirmModalOpen(false)
                    }
                />
            )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
    };
}

export default connect(mapStateToProps, {
    spendCredits,
})(withRouter(RemoveObjectEditor));
