import { combineReducers } from "redux";

const userAuth = (state = {}, action) => {
    switch (action.type) {
        case "SIGN_IN_SUCCESS":
            return {
                isLoggedIn: true,
                userAccount: action.payload,
            };
        case "SIGN_IN_FAIL":
            return {
                isLoggedIn: false,
                userAccount: null,
            };
        case "SIGN_UP_SUCCESS":
            return {
                isLoggedIn: false,
                userAccount: action.payload,
            };
        case "SIGN_UP_FAIL":
            return {
                isLoggedIn: false,
                userAccount: action.payload,
            };
        case "SIGN_OUT_SUCCESS":
            return {
                isLoggedIn: false,
                userAccount: null,
            };
        default:
            return state;
    }
};

const verifyToken = (state = {}, action) => {
    switch (action.type) {
        case "VERIFY_TOKEN_SUCCESS":
            return { userEmail: action.payload };
        default:
            return state;
    }
};

export default combineReducers({ userAuth, verifyToken });
