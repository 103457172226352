import clsx from "clsx";
import {
    PContainer,
    PSection,
    PBox,
    PH1,
    PText,
    PH3,
    PImg,
} from "../../theme/BaseTheme";

export const ObjectRemoverHow = () => {
    return (
        <PSection css={styles}>
            <PBox className="sell-how">
                <PBox className="sell-how-container">
                    <PText className="sell-how-subtitle">
                        Start Creating Profession Images
                    </PText>
                    <PH1 className="sell-how-title">How to Remove Objects</PH1>

                    <PBox className="sell-how-steps-wrapper">
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-1"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-90.34-5.66a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32L144,147.31V208a8,8,0,0,0,16,0V147.31l18.34,18.35a8,8,0,0,0,11.32-11.32Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>1. Upload Designs</PH3>
                            <PText>Upload an image you want to edit.</PText>
                            <PImg src={"/images/object-remover/step-1.jpg"} />
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-2"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M208,32H80A16,16,0,0,0,64,48V64H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V192h16a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM80,48H208v69.38l-16.7-16.7a16,16,0,0,0-22.62,0L93.37,176H80Zm96,160H48V80H64v96a16,16,0,0,0,16,16h96Zm32-32H116l64-64,28,28v36Zm-88-64A24,24,0,1,0,96,88,24,24,0,0,0,120,112Zm0-32a8,8,0,1,1-8,8A8,8,0,0,1,120,80Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>2. Highlight Objects</PH3>
                            <PText>Brush to highlight objects to remove.</PText>
                            <PImg src={"/images/object-remover/step-2.jpg"} />
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-3"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M169.64,134.33l44.77-19.46A16,16,0,0,0,213,85.07L52.92,32.8A16,16,0,0,0,32.8,52.92L85.07,213a15.83,15.83,0,0,0,14.41,11l.79,0a15.83,15.83,0,0,0,14.6-9.59h0l19.46-44.77L184,219.31a16,16,0,0,0,22.63,0l12.68-12.68a16,16,0,0,0,0-22.63Zm-69.48,73.76.06-.05Zm95.15-.09-49.66-49.67a16,16,0,0,0-26,4.94l-19.42,44.65L48,48l159.87,52.21-44.64,19.41a16,16,0,0,0-4.94,26L208,195.31ZM88,24V16a8,8,0,0,1,16,0v8a8,8,0,0,1-16,0ZM8,96a8,8,0,0,1,8-8h8a8,8,0,0,1,0,16H16A8,8,0,0,1,8,96ZM120.85,28.42l8-16a8,8,0,0,1,14.31,7.16l-8,16a8,8,0,1,1-14.31-7.16Zm-81.69,96a8,8,0,0,1-3.58,10.74l-16,8a8,8,0,0,1-7.16-14.31l16-8A8,8,0,0,1,39.16,124.42Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>3. Remove Objects</PH3>
                            <PText>Let AI remove unwanted objects.</PText>
                            <PImg src={"/images/object-remover/step-3.jpg"} />
                        </PBox>
                        <PBox
                            className={clsx(
                                "sell-how-steps-item",
                                "sell-how-steps-item-4"
                            )}
                        >
                            <PBox className="sell-how-steps-step">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                    viewBox="0 0 256 256"
                                >
                                    <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-42.34-61.66a8,8,0,0,1,0,11.32l-24,24a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L120,164.69V120a8,8,0,0,1,16,0v44.69l10.34-10.35A8,8,0,0,1,157.66,154.34Z"></path>
                                </svg>
                                <PBox className="sell-how-steps-line"></PBox>
                            </PBox>
                            <PH3>4. Download Image</PH3>
                            <PText>Download the generated image.</PText>
                            <PImg src={"/images/object-remover/step-4.jpg"} />
                        </PBox>
                    </PBox>
                </PBox>
            </PBox>
        </PSection>
    );
};

const styles = {
    backgroundColor: "rgb(238, 236, 255)",
    fontFamily:
        "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    "& .sell-how": {
        padding: "4rem 1.5rem",
        maxWidth: "80rem",
        margin: "0rem auto",
        //"@bp2": { marginTop: "8rem" },
        "@bp4": {
            paddingLeft: "4rem",
            paddingRight: "4rem",
        },
    },
    "& .sell-how-container": {
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
    },
    "& .sell-how-subtitle": {
        color: "$colors$primary",
        lineHeight: "1.75rem",
        fontWeight: "600",
        fontSize: "1rem",
        textAlign: "center",
    },
    "& .sell-how-title": {
        color: "rgb(17 24 39)",
        fontWeight: "700",
        fontSize: "1.875rem",
        lineHeight: "2.5rem",
        marginTop: "0.5rem",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "@bp2": { fontSize: "2.25rem", lineHeight: "3rem" },
    },
    "& .sell-how-text": {
        fontSize: "20px",
        lineHeight: "1.7",
        color: "rgb(75 85 99)",
        textAlign: "center",
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "80px",
    },
    "& .sell-how-steps-wrapper": {
        position: "relative",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        maxWidth: "80rem",
        marginTop: "2rem",
        marginLeft: "auto",
        marginRight: "auto",

        overflow: "hidden",
        display: "grid",
        maxWidth: "42rem",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gap: "2rem",
        "@bp4": {
            paddingLeft: "2rem",
            paddingRight: "2rem",
            maxWidth: "none",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "4rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
    },
    "& .sell-how-steps-item": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        "& h3": {
            marginBottom: "0",
            marginLeft: "0.125rem",
        },
        "& p": {
            lineHeight: "1.5",
            marginLeft: "0.125rem",
            color: "rgb(75 85 99)",
        },
        "& svg": {
            width: "2rem",
            height: "2rem",
            //padding: "0rem",
            //borderRadius: "50%",
            //fill: "$colors$primary",
            fill: "#fff",
            padding: "0.5rem",
            borderRadius: "0.5rem",
            backgroundColor: "$colors$primary",
        },
        "& img": {
            width: "100%",
            marginTop: "1.5rem",
        },
    },
    "& .sell-how-steps-step": {
        display: "flex",
        position: "relative",
        width: "100%",
    },
    "& .sell-how-steps-line": {
        width: "auto",
        height: "1px",
        position: "static",
        //backgroundColor: "$colors$borderColor",
        backgroundColor: "$colors$primary",
        marginLeft: "1rem",
        marginRight: "1rem",
        transform: "translate(0, 1.5rem)",
        flex: "1 1 auto",
    },
};
