import {
    PBox,
    PH1,
    PH2,
    PImg,
    PLi,
    POl,
    PText,
    PUl,
} from "../../../theme/BaseTheme";
import { BlogPostLayout } from "../BlogPostLayout";

export const HowToAlignMultiplePatterns = () => {
    return (
        <BlogPostLayout category="Tutorials">
            <PH1>
                How can I easily align multiple seamless patterns in
                Patternful.ai
            </PH1>
            <PBox className="blog-hero">
                <PText>Patternful Team | Mar 19, 2024 | 2 min read</PText>
            </PBox>

            <PBox className="blog-banner-img">
                <PImg
                    src={
                        "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*ZCabx35IVPxfKax8-JxSgw.png"
                    }
                />
            </PBox>
            <PText>
                Aligning multiple seamless patterns in Patternful.ai is easy —
                we've taken care of it for you!
            </PText>
            <PText>Step 1: Generate your perfect pattern.</PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1360/format:webp/1*p89cOm1Pz4lEsC-SY0m8Jw.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                Step 2: Once you're happy with your design, just hit the
                download button.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*jjNeAJh4o4Oz2NeC2WgoPA.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                Step 3: Choose your image size. The default size is 1024px by
                1024px. Opting for a 4096px by 4096px download will arrange 16
                patterns across 4 rows and 4 columns.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*njXtESWq6cUvmSdeYeOhNw.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                Patternful.ai also provides extensive sizing options to suit
                various needs. On the Edit and Download page, you can pick from
                predefined sizes or enter custom dimensions.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*-ry4HTxLlMMkkxNKQB9ANQ.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                With predefined sizes, you have over 100 options across 9
                categories: Office, Apparel & Bags, Tech, Wall Art, Furniture,
                Bed & Bath, Outdoor & Lifestyle, Home Decor, and Tabletop. For
                instance, selecting Tech - iPhone Cases adjusts the output to
                1300px by 2000px. The pattern will be aligned multiple times and
                cropped into this size.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*1QkK2dM0Trdu7RoboAWXng.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                If you prefer, custom dimensions let you specify the exact size
                you need for your download.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*Xle_MwUiji_a-ef6cipE7w.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                And that's it — you're ready to create amazing patterns. Enjoy!
            </PText>
        </BlogPostLayout>
    );
};
