import { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Form, Input, Space, Button, Radio } from "antd";
import { PText, PH4, PBox } from "../../theme/BaseTheme";
import { shareProduct, fetchShareProduct } from "../../../actions/pattern";
import { Loader } from "../Loader";

class SettingsModal extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, share: false, url: "" };
        this.onFinish = this.onFinish.bind(this);
    }

    async componentDidMount() {
        await this.props.fetchShareProduct(this.props.patternUUID);
        await this.setState({
            share: !this.props.share?.is_product_hidden,
            url: this.props.share?.product_share_link,
        });
        await this.setState({
            isLoading: false,
        });
    }

    onFinish = async (values) => {
        await this.props.shareProduct(
            this.props.patternUUID,
            this.state.share,
            this.state.url
        );
        await this.props.handleSettingsModalOk();
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Modal
                title={"Settings"}
                open={this.props.isSettingsModalOpen}
                onOk={async () => {
                    this.props.handleSettingsModalOk();
                }}
                onCancel={this.props.handleSettingsModalCancel}
                okText={"Submit"}
                footer={null}
            >
                <PBox css={styles}>
                    <Form
                        layout="vertical"
                        onFinish={this.onFinish}
                        autoComplete="off"
                    >
                        <PText>
                            1. Share this pattern in our product gallery.
                        </PText>
                        <Radio.Group
                            options={[
                                {
                                    label: "Share",
                                    value: true,
                                },
                                {
                                    label: "Hide",
                                    value: false,
                                },
                            ]}
                            value={this.state.share}
                            onChange={(e) => {
                                this.setState({ share: e.target.value });
                            }}
                        />
                        <PBox>
                            <br />
                        </PBox>
                        <Form.Item
                            name="url"
                            label="2. Share the link to your product."
                            rules={[
                                {
                                    type: "url",
                                    message: "Please enter a valid URL.",
                                },
                            ]}
                        >
                            <Input
                                defaultValue={this.state.url}
                                value={this.state.url}
                                placeholder="Link to your product"
                                onChange={(e) => {
                                    this.setState({ url: e.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </PBox>
            </Modal>
        );
    }
}

const styles = {
    margin: "2rem 0 0 0",
};

function mapStateToProps(state) {
    return {
        share: state.patternReducer?.fetchShareProduct?.share,
    };
}

export default connect(mapStateToProps, { shareProduct, fetchShareProduct })(
    withRouter(SettingsModal)
);
