import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Radio, message } from "antd";
import { PText, PH4, PBox } from "../../theme/BaseTheme";
import { upscalePatternImage } from "../../../actions/pattern";
import { upscaleTeeImage } from "../../../actions/tee";

export const UpscaleModal = (props) => {
    const [upscaleValue, setUpscaleValue] = useState("2");
    const [isProcessing, setIsProcessing] = useState(false);

    return (
        <Modal
            title="Upscale Image"
            open={props.isUpscaleModalOpen}
            onOk={async () => {
                await setIsProcessing(true);
                message.open({
                    type: "loading",
                    content: "Upscaling the pattern. This can take a while.",
                    duration: 0,
                });
                if (props.generatorType === "Tee") {
                    await props.upscaleTeeImage(
                        props.patternUUID,
                        upscaleValue
                    );
                } else {
                    await props.upscalePatternImage(
                        props.patternUUID,
                        upscaleValue
                    );
                }
                message.destroy();
                await setIsProcessing(false);
                props.handleUpscaleModalOk();
            }}
            onCancel={props.handleUpscaleModalCancel}
            okText={"Upscale"}
            okButtonProps={{ loading: isProcessing }}
        >
            <PH4
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                Upscale image to higher resolution{" "}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                    viewBox="0 0 256 256"
                    style={{
                        fill: "#d48806",
                        backgroundColor: "#ffe58f",
                        padding: "0.125rem 0.25rem",
                        borderRadius: "0.25rem",
                        width: "1rem",
                        height: "1rem",
                        marginLeft: "0.25rem",
                    }}
                >
                    <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                </svg>
            </PH4>
            <Radio.Group
                options={[
                    {
                        label: "x2 (2K resolution, 2048px x 2048px)",
                        value: "2",
                    },
                    {
                        label: "x4 (4K resolution, 4096px x 4096px)",
                        value: "4",
                    },
                ]}
                value={upscaleValue}
                onChange={(e) => {
                    setUpscaleValue(e.target.value);
                }}
            />
            <PText>
                <br />
            </PText>
        </Modal>
    );
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {
    upscalePatternImage,
    upscaleTeeImage,
})(withRouter(UpscaleModal));
