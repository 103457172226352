import {
    PBox,
    PH1,
    PH2,
    PImg,
    PLi,
    POl,
    PText,
    PUl,
} from "../../../theme/BaseTheme";
import { BlogPostLayout } from "../BlogPostLayout";

export const HowToCreateMockupImages = () => {
    return (
        <BlogPostLayout category="Tutorials">
            <PH1>
                How to create mockup images for seamless patterns in
                Patternful.ai
            </PH1>
            <PBox className="blog-hero">
                <PText>Patternful Team | Mar 27, 2024 | 2 min read</PText>
            </PBox>

            <PBox className="blog-banner-img">
                <PImg
                    src={
                        "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*kGyMw6a5KPF1nOsUuUIBJQ.png"
                    }
                />
            </PBox>
            <PText>
                Mockup images are essential for presenting designs in a
                realistic context, demonstrating how the end product will look.
                With Patternful.ai, producing these mockup images becomes
                straightforward once you've crafted your pattern.
            </PText>
            <PText>
                <b>Step 1:</b> Craft your ideal pattern. Simply enter the text
                prompt of your desired pattern, and click Generate.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*pZY6oIWuQn46Q5i-MmL6GA.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                <b>Step 2:</b> Once you are satisfied with your pattern, simply
                click on the Edit and Mockup button.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1300/format:webp/1*ddcFk6034BAr4xnopxKhjA.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                <b>Step 3:</b> On the following page, select the Mockup tab.
                Then pick your preferred mockup image and hit apply. If you are
                happy with the outcome, download the image to save it on your
                device.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:2000/format:webp/1*IfLlt28z2h0NxiXEO8plCg.png"
                }
                style={{
                    maxWidth: "720px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>And there you have it! Happy designing.</PText>
        </BlogPostLayout>
    );
};
