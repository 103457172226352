import { Component, useEffect } from "react";
import { Modal } from "antd";
import { PBox, PText } from "../../theme/BaseTheme";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    createSubscription,
    updateSubscription,
    previewProration,
} from "../../../actions/subscription";
import { refetchUser } from "../../../actions/auth";
import { Loader } from "../Loader";

class PaymentConfirmModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isProcessing: false,
        };
    }

    async componentDidMount() {
        if (this.props.userAccount?.plan !== "free") {
            await this.props.previewProration(this.props.newPlan);
        }
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Modal
                title={"Confirm Your Change"}
                open={this.props.isPaymentConfirmModalOpen}
                onOk={async () => {
                    if (this.props.proration?.downgrade_plan !== "same") {
                        await this.setState({ isProcessing: true });
                        if (this.props.userAccount?.plan === "free") {
                            await this.props.createSubscription(
                                this.props.newPlan
                            );
                        } else {
                            await this.props.updateSubscription(
                                this.props.newPlan
                            );
                        }
                        await this.props.refetchUser();
                        await this.setState({ isProcessing: false });
                    }
                    this.props.handlePaymentConfirmModalOk();
                }}
                onCancel={this.props.handlePaymentConfirmModalCancel}
                okText={"Confirm"}
                okButtonProps={{ loading: this.state.isProcessing }}
            >
                <PBox css={styles}>
                    <PText>
                        <b>Change to: {this.props.newPlan} plan</b>
                    </PText>
                    {this.props.proration?.downgrade_plan === "false" && (
                        <>
                            <PText>
                                Prorated charge for the remaining cycle: $
                                {parseInt(
                                    this.props.proration?.proration_subtotal
                                ) / 100}
                                .
                            </PText>
                            <PText>
                                After clicking Confirm, the charge will be
                                applied instantly, and your plan will be
                                upgraded right away.
                            </PText>
                        </>
                    )}
                    {this.props.proration?.downgrade_plan === "true" && (
                        <>
                            <PText>
                                Your change of plan will take effect at the end
                                of the billing cycle, and you will still be able
                                to use your remaining credits until the end of
                                the monthly billing cycle.
                            </PText>
                        </>
                    )}
                    {this.props.proration?.downgrade_plan === "same" && (
                        <>
                            <PText>
                                You are current subscribed to{" "}
                                {this.props.newPlan} plan.
                            </PText>
                        </>
                    )}
                    <PText>
                        <br />
                    </PText>
                </PBox>
            </Modal>
        );
    }
}

const styles = {};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        proration: state.subscriptionReducer?.previewProration?.proration,
    };
}

export default connect(mapStateToProps, {
    createSubscription,
    updateSubscription,
    previewProration,
    refetchUser,
})(withRouter(PaymentConfirmModal));
