import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, message, Upload } from "antd";
import download from "downloadjs";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PH4, PH3, PImg, PText, PSpan, PH2 } from "../../theme/BaseTheme";
import { refetchUser } from "../../../actions/auth";
import { removeBackground } from "../../../actions/pattern";
import { clearStoredRemoveBackgroundImage } from "../../../actions/pattern";
import { removeBackgroundDownloadHD } from "../../../actions/pattern";
import ChangePlanModal from "../../components/app/ChangePlanModal";
import PaymentConfirmModal from "../../components/app/PaymentConfirmModal";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

class BgRemoverApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isRemovingBackground: false,
            mockupUUID: "",
            mockupCredits: 0,
            uploadedImage: "",
            fileList: [],
            isChangePlanModalOpen: false,
            isPaymentConfirmModalOpen: false,
            newPlan: "",
        };
        this.handleUpload = this.handleUpload.bind(this);
    }
    async componentDidMount() {
        //await this.props.clearStoredRemoveBackgroundImage();
        await this.setState({ isLoading: false });
    }

    handleUpload = async ({ file }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        await this.setState({
            uploadedImage: file.url || file.preview,
        });
        if (file.status === "uploading" && file.percent == 100) {
            await this.setState({ isRemovingBackground: true });
            message.open({
                type: "loading",
                content: "Removing background.",
                duration: 0,
            });
            await this.props.removeBackground(this.state.uploadedImage);
            message.destroy();
            await this.setState({ isRemovingBackground: false });
        }
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Background Remover App</title>
                    <meta
                        name="description"
                        content="Get transparent background image using AI in just one click, perfect for your e-commerce, marketing, graphic design, and more."
                    />
                    <meta
                        name="keywords"
                        content="Background Remover, AI Background Remover, Background Remover APP, Background Remover Online, Image Processing, AI Image Editing, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="app-content">
                        <PBox className="app-content-control">
                            <PBox className="remove-background-upload-image">
                                <PH2>
                                    Upload your image to remove background.
                                </PH2>
                                <PBox className="remove-background-content">
                                    <PBox>
                                        <Upload.Dragger
                                            onChange={this.handleUpload}
                                            showUploadList={false}
                                            listType="picture-card"
                                            maxCount={1}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng =
                                                    file.type ===
                                                        "image/jpeg" ||
                                                    file.type === "image/png";
                                                if (!isJpgOrPng) {
                                                    message.error(
                                                        "You can only upload JPG/PNG file."
                                                    );
                                                }
                                                const isLt8M =
                                                    file.size / 1024 / 1024 < 8;
                                                if (!isLt8M) {
                                                    message.error(
                                                        "Image must smaller than 8MB."
                                                    );
                                                }
                                                return (
                                                    (isJpgOrPng && isLt8M) ||
                                                    Upload.LIST_IGNORE
                                                );
                                            }}
                                        >
                                            {this.props.image && (
                                                <PImg
                                                    className="background-removed-image"
                                                    src={this.props.image}
                                                />
                                            )}
                                            {!this.props.image && (
                                                <>Upload Image</>
                                            )}
                                        </Upload.Dragger>
                                    </PBox>
                                    <PBox className="remove-background-slider">
                                        <ImgComparisonSlider class="slider-with-animated-handle">
                                            <img
                                                slot="first"
                                                width="100%"
                                                src={this.props.uploadedImage}
                                            />
                                            <img
                                                slot="second"
                                                width="100%"
                                                src={this.props.image}
                                            />
                                        </ImgComparisonSlider>
                                        <PText className="caption-before">
                                            Before
                                        </PText>
                                        <PText className="caption-after">
                                            After
                                        </PText>
                                    </PBox>
                                </PBox>
                            </PBox>
                            <PBox className="remove-background-download-buttons">
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={() => {
                                        download(
                                            this.props.image,
                                            `img.png`,
                                            `image/png`
                                        );
                                    }}
                                >
                                    Download (512px x 512px)
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={async () => {
                                        if (!this.props.userAccount) {
                                            window
                                                .open("/sign-in", "_blank")
                                                .focus();
                                        } else if (
                                            this.props.userAccount?.credits ===
                                            0
                                        ) {
                                            await this.setState({
                                                isChangePlanModalOpen: true,
                                            });
                                        } else {
                                            await this.props.removeBackgroundDownloadHD(
                                                this.props.uuid
                                            );
                                        }
                                    }}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Download HD{" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                        viewBox="0 0 256 256"
                                        style={{
                                            fill: "#d48806",
                                            backgroundColor: "#ffe58f",
                                            padding: "0.125rem 0.25rem",
                                            borderRadius: "0.25rem",
                                            width: "1rem",
                                            height: "1rem",
                                            marginLeft: "0.5rem",
                                        }}
                                    >
                                        <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                    </svg>
                                </Button>
                            </PBox>
                        </PBox>
                        {this.state.isChangePlanModalOpen && (
                            <ChangePlanModal
                                isChangePlanModalOpen={
                                    this.state.isChangePlanModalOpen
                                }
                                handleChangePlanModalOk={async () => {
                                    await this.setState({
                                        isPaymentConfirmModalOpen: true,
                                    });
                                    await this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                handleChangePlanModalCancel={() => {
                                    this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                setNewPlan={(value) => {
                                    this.setState({ newPlan: value });
                                }}
                            />
                        )}

                        {this.state.isPaymentConfirmModalOpen && (
                            <PaymentConfirmModal
                                newPlan={this.state.newPlan}
                                isPaymentConfirmModalOpen={
                                    this.state.isPaymentConfirmModalOpen
                                }
                                handlePaymentConfirmModalOk={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                                handlePaymentConfirmModalCancel={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                            />
                        )}
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    minHeight: "48rem",
    "& .app-content-control": {
        margin: "0 auto",
    },
    "& .remove-background-upload-image": {
        margin: "6rem auto 2rem auto",
        width: "64rem",
        textAlign: "center",
        "& span": {
            padding: "0",
            width: "30rem",
            height: "30rem",
            display: "block",
            margin: "0",
        },
        "& .background-removed-image": {
            maxWidth: "28rem",
            maxHeight: "28rem",
            margin: "0 1rem 1rem 1rem",
        },
        "& h2": {
            marginBottom: "3rem",
        },
    },
    "& .remove-background-download-buttons": {
        margin: "3rem auto",
        display: "flex",
        justifyContent: "center",
        "& button": {
            margin: "0 0.5rem",
        },
    },
    "& .remove-background-content": {
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        //gap: "2rem",
    },
    "& .caption-before": {
        background: "#fff",
        border: "1px solid #c0c0c0",
        borderRadius: "12px",
        color: "#2e3452",
        opacity: "0.8",
        padding: "12px",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        lineHeight: "100%",
        left: "12px",
    },
    "& .caption-after": {
        background: "#fff",
        border: "1px solid #c0c0c0",
        borderRadius: "12px",
        color: "#2e3452",
        opacity: "0.8",
        padding: "12px",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        lineHeight: "100%",
        right: "12px",
    },
    "& .slider-with-animated-handle": {
        position: "relative",
        margin: "auto auto",
        "& img": { maxHeight: "28rem" },
    },
    "& .remove-background-slider": {
        width: "30rem",
        height: "30rem",
        border: "1px solid $colors$borderColor",
        position: "relative",
        borderRadius: "0.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        image: state.patternReducer?.removeBackground?.image,
        uuid: state.patternReducer?.removeBackground?.uuid,
        uploadedImage: state.patternReducer?.removeBackground?.uploadedImage,
    };
}

export default connect(mapStateToProps, {
    refetchUser,
    removeBackground,
    clearStoredRemoveBackgroundImage,
    removeBackgroundDownloadHD,
})(withRouter(BgRemoverApp));
