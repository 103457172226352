import { BlogSection } from "./BlogSection";
import { BlogLayout } from "./BlogLayout";
import { BlogPosts } from "../../../data/blogs";

export const BlogHome = () => {
    return (
        <BlogLayout>
            <BlogSection
                title="Patternful Blog"
                subtitle="Latest news, trends, resources, and tools."
                posts={BlogPosts}
            />
        </BlogLayout>
    );
};
