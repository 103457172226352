import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, message, Slider, Upload } from "antd";
import { ReactSketchCanvas } from "react-sketch-canvas";
import download from "downloadjs";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PH4, PImg, PText, PH2, PH1 } from "../../theme/BaseTheme";
import { refetchUser } from "../../../actions/auth";
import ChangePlanModal from "../../components/app/ChangePlanModal";
import PaymentConfirmModal from "../../components/app/PaymentConfirmModal";
import { SellFaq } from "../../sections/SellFaq";
import {
    removeObjects,
    clearStoredObjectsRemovedImage,
} from "../../../actions/pattern";
import { object_remover_faq_data } from "../landing/ObjectRemoverLanding";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

class ObjectRemoverApp extends Component {
    canvasRef = React.createRef();

    constructor(props) {
        super(props);
        this.highlightRef = React.createRef();
        this.resultRef = React.createRef();
        this.state = {
            isLoading: true,
            brushSize: 30,
            isGenerating: false,
            isDownloading: false,
            eraseMode: false,
            uploadedImage: "",
            uploadedImageWidth: 0,
            uploadedImageHeight: 0,
            backgroundImage: "",
            fileList: [],
            isChangePlanModalOpen: false,
            isPaymentConfirmModalOpen: false,
            newPlan: "",
        };
        this.handleUpload = this.handleUpload.bind(this);
        this.handleUploadDemo = this.handleUploadDemo.bind(this);
        this.handleUndoClick = this.handleUndoClick.bind(this);
        this.handleRedoClick = this.handleRedoClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
    }
    handleUndoClick = () => {
        this.canvasRef.current?.undo();
    };

    handleRedoClick = () => {
        this.canvasRef.current?.redo();
    };
    handleResetClick = () => {
        this.canvasRef.current?.resetCanvas();
    };
    handleClearClick = () => {
        this.canvasRef.current?.clearCanvas();
    };

    handleUpload = async ({ file }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        await this.setState({
            uploadedImage: file.url || file.preview,
            backgroundImage: file.url || file.preview,
        });
        await this.props.clearStoredObjectsRemovedImage();
        await this.handleClearClick();
    };

    handleUploadDemo = async (demoURL, demoName, demoType) => {
        let blob = await fetch(demoURL)
            .then((r) => r.blob())
            .then(
                (blobFile) =>
                    new File([blobFile], demoName, {
                        type: demoType,
                    })
            );
        let filePreview = await getBase64(blob);
        await this.setState({
            uploadedImage: filePreview,
            backgroundImage: filePreview,
        });
        await this.props.clearStoredObjectsRemovedImage();
        await this.handleClearClick();
    };

    async componentDidMount() {
        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Object Remover App</title>
                    <meta
                        name="description"
                        content="Segment and generate binary masks. AI-powered segmentation and generation of binary object masks, for your online business, marketing, or just for fun!"
                    />
                    <meta
                        name="keywords"
                        content="Binary Mask Generator, Segmentation Mask Generator, Binary Mask Generator APP, Binary Mask Generator Online, Image Segmentation, AI Image Editing, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="app-content">
                        <PH1>AI Object Remover</PH1>
                        <PBox className="app-content-control">
                            <PBox className="remove-background-upload-image">
                                <PBox className="binary-mask-upload">
                                    <Upload.Dragger
                                        onChange={async (info) => {
                                            await this.handleUpload(info);
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                        showUploadList={false}
                                        listType="picture-card"
                                        maxCount={1}
                                        beforeUpload={async (file) => {
                                            const reader = new FileReader();
                                            await reader.readAsDataURL(file);
                                            await reader.addEventListener(
                                                "load",
                                                async (event) => {
                                                    const _loadedImageUrl =
                                                        event.target.result;
                                                    const image =
                                                        document.createElement(
                                                            "img"
                                                        );
                                                    image.src = _loadedImageUrl;
                                                    await image.addEventListener(
                                                        "load",
                                                        async () => {
                                                            const {
                                                                width,
                                                                height,
                                                            } = image;
                                                            // set image width and height to your state here
                                                            await this.setState(
                                                                {
                                                                    uploadedImageWidth:
                                                                        width,
                                                                    uploadedImageHeight:
                                                                        height,
                                                                }
                                                            );
                                                            await console.log(
                                                                "width: ",
                                                                width,
                                                                " height: ",
                                                                height
                                                            );
                                                        }
                                                    );
                                                }
                                            );
                                            const isJpgOrPng =
                                                file.type === "image/jpeg" ||
                                                file.type === "image/png";
                                            if (!isJpgOrPng) {
                                                message.error(
                                                    "You can only upload JPG/PNG file."
                                                );
                                            }
                                            const isLt8M =
                                                file.size / 1024 / 1024 < 8;
                                            if (!isLt8M) {
                                                message.error(
                                                    "Image must smaller than 8MB."
                                                );
                                            }
                                            return (
                                                (isJpgOrPng && isLt8M) ||
                                                Upload.LIST_IGNORE
                                            );
                                        }}
                                    >
                                        {this.state.uploadedImage !== "" && (
                                            <PImg
                                                className="background-removed-image"
                                                src={this.state.uploadedImage}
                                            />
                                        )}
                                        {this.state.uploadedImage === "" && (
                                            <PText>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="#000000"
                                                    viewBox="0 0 256 256"
                                                >
                                                    <path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0ZM93.66,85.66,120,59.31V152a8,8,0,0,0,16,0V59.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,85.66Z"></path>
                                                </svg>
                                                <br />
                                                Click to upload your image,
                                                <br />
                                                or drag and drop your image into
                                                this area.
                                            </PText>
                                        )}
                                    </Upload.Dragger>
                                </PBox>
                                <PBox className="binary-mask-demo-images">
                                    <PH4>Try demo images:</PH4>
                                    <PImg
                                        src={
                                            "/images/object-remover/demo-1.jpg"
                                        }
                                        onClick={async () => {
                                            await this.handleUploadDemo(
                                                "/images/object-remover/demo-1.jpg",
                                                "object-remover-demo-1.jpg",
                                                "image/jpeg"
                                            );
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                    />
                                    <PImg
                                        src={
                                            "/images/object-remover/demo-2.jpg"
                                        }
                                        onClick={async () => {
                                            await this.handleUploadDemo(
                                                "/images/object-remover/demo-2.jpg",
                                                "object-remover-demo-2.jpg",
                                                "image/jpeg"
                                            );
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                    />
                                    <PImg
                                        src={
                                            "/images/object-remover/demo-3.jpg"
                                        }
                                        onClick={async () => {
                                            await this.handleUploadDemo(
                                                "/images/object-remover/demo-3.jpg",
                                                "object-remover-demo-3.jpg",
                                                "image/jpeg"
                                            );
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                    />
                                    <PImg
                                        src={
                                            "/images/object-remover/demo-4.jpg"
                                        }
                                        onClick={async () => {
                                            await this.handleUploadDemo(
                                                "/images/object-remover/demo-4.jpg",
                                                "object-remover-demo-4.jpg",
                                                "image/jpeg"
                                            );
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                    />
                                </PBox>
                                {this.state.backgroundImage !== "" && (
                                    <PBox
                                        className="remove-background-content"
                                        ref={this.highlightRef}
                                    >
                                        <PBox>
                                            <PBox className="binary-mask-sketch-canva">
                                                <ReactSketchCanvas
                                                    ref={this.canvasRef}
                                                    style={{
                                                        cursor: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="${
                                                            this.state.brushSize
                                                        }px" height="${
                                                            this.state.brushSize
                                                        }px" viewBox="0 0 48 48" fill="none"><circle cx="24" cy="24" r="23.5" fill="%236729F3" fill-opacity="0.43" stroke="%23F8F8F8"/></svg>') ${
                                                            this.state
                                                                .brushSize / 2
                                                        } ${
                                                            this.state
                                                                .brushSize / 2
                                                        }, auto`,
                                                    }}
                                                    width={`calc(31rem - 2px)`}
                                                    //height={`calc((31rem - 2px) / ${this.state.uploadedImageWidth} * ${this.state.uploadedImageHeight})`}
                                                    height={`calc(31rem - 2px)`}
                                                    strokeWidth={
                                                        this.state.brushSize
                                                    }
                                                    strokeColor={
                                                        "rgba(109, 40, 217, 0.4)"
                                                    }
                                                    backgroundImage={
                                                        this.state
                                                            .backgroundImage
                                                    }
                                                    preserveBackgroundImageAspectRatio={
                                                        "xMidYMid"
                                                    }
                                                    exportWithBackgroundImage={
                                                        false
                                                    }
                                                />
                                            </PBox>
                                        </PBox>
                                        <PBox>
                                            <PH2 className="binary-mask-brush-title">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="#000000"
                                                    viewBox="0 0 256 256"
                                                >
                                                    <path d="M232,32a8,8,0,0,0-8-8c-44.08,0-89.31,49.71-114.43,82.63A60,60,0,0,0,32,164c0,30.88-19.54,44.73-20.47,45.37A8,8,0,0,0,16,224H92a60,60,0,0,0,57.37-77.57C182.3,121.31,232,76.08,232,32ZM92,208H34.63C41.38,198.41,48,183.92,48,164a44,44,0,1,1,44,44Zm32.42-94.45q5.14-6.66,10.09-12.55A76.23,76.23,0,0,1,155,121.49q-5.9,4.94-12.55,10.09A60.54,60.54,0,0,0,124.42,113.55Zm42.7-2.68a92.57,92.57,0,0,0-22-22c31.78-34.53,55.75-45,69.9-47.91C212.17,55.12,201.65,79.09,167.12,110.87Z"></path>
                                                </svg>
                                                Brush to highlight the target
                                                object
                                            </PH2>
                                            <PBox className="binary-mask-brush-size">
                                                <PH4>Brush Size:</PH4>
                                                <PBox className="binary-mask-sketch-buttons">
                                                    <PBox
                                                        className="binary-mask-sketch-button"
                                                        onClick={
                                                            this.handleUndoClick
                                                        }
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="#000000"
                                                            viewBox="0 0 256 256"
                                                        >
                                                            <path d="M232,144a64.07,64.07,0,0,1-64,64H80a8,8,0,0,1,0-16h88a48,48,0,0,0,0-96H51.31l34.35,34.34a8,8,0,0,1-11.32,11.32l-48-48a8,8,0,0,1,0-11.32l48-48A8,8,0,0,1,85.66,45.66L51.31,80H168A64.07,64.07,0,0,1,232,144Z"></path>
                                                        </svg>
                                                    </PBox>
                                                    <PBox
                                                        className="binary-mask-sketch-button"
                                                        onClick={
                                                            this.handleRedoClick
                                                        }
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="#000000"
                                                            viewBox="0 0 256 256"
                                                        >
                                                            <path d="M170.34,130.34,204.69,96H88a48,48,0,0,0,0,96h88a8,8,0,0,1,0,16H88A64,64,0,0,1,88,80H204.69L170.34,45.66a8,8,0,0,1,11.32-11.32l48,48a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32-11.32Z"></path>
                                                        </svg>
                                                    </PBox>
                                                    <PBox
                                                        className="binary-mask-sketch-button"
                                                        onClick={
                                                            this
                                                                .handleResetClick
                                                        }
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="#000000"
                                                            viewBox="0 0 256 256"
                                                        >
                                                            <path d="M197.67,186.37a8,8,0,0,1,0,11.29C196.58,198.73,170.82,224,128,224c-37.39,0-64.53-22.4-80-39.85V208a8,8,0,0,1-16,0V160a8,8,0,0,1,8-8H88a8,8,0,0,1,0,16H55.44C67.76,183.35,93,208,128,208c36,0,58.14-21.46,58.36-21.68A8,8,0,0,1,197.67,186.37ZM216,40a8,8,0,0,0-8,8V71.85C192.53,54.4,165.39,32,128,32,85.18,32,59.42,57.27,58.34,58.34a8,8,0,0,0,11.3,11.34C69.86,69.46,92,48,128,48c35,0,60.24,24.65,72.56,40H168a8,8,0,0,0,0,16h48a8,8,0,0,0,8-8V48A8,8,0,0,0,216,40Z"></path>
                                                        </svg>
                                                    </PBox>
                                                </PBox>
                                            </PBox>
                                            <Slider
                                                defaultValue={
                                                    this.state.brushSize
                                                }
                                                value={this.state.brushSize}
                                                onChange={(value) => {
                                                    this.setState({
                                                        brushSize: value,
                                                    });
                                                }}
                                                min={2}
                                                max={80}
                                            />
                                            <PText>
                                                <br />
                                            </PText>
                                            <PBox className="binary-mask-button">
                                                <Button
                                                    type="primary"
                                                    loading={
                                                        this.state.isGenerating
                                                    }
                                                    onClick={async () => {
                                                        if (
                                                            !this.props
                                                                .userAccount
                                                        ) {
                                                            window
                                                                .open(
                                                                    "/sign-in",
                                                                    "_blank"
                                                                )
                                                                .focus();
                                                        } else if (
                                                            this.props
                                                                .userAccount
                                                                ?.credits === 0
                                                        ) {
                                                            await this.setState(
                                                                {
                                                                    isChangePlanModalOpen: true,
                                                                }
                                                            );
                                                        } else {
                                                            var exportedPaths =
                                                                await this.canvasRef.current.exportPaths();
                                                            if (
                                                                exportedPaths.length ===
                                                                0
                                                            ) {
                                                                message.error(
                                                                    "Please highlight the objects to remove."
                                                                );
                                                            } else {
                                                                await this.setState(
                                                                    {
                                                                        isGenerating: true,
                                                                    }
                                                                );
                                                                message.open({
                                                                    type: "loading",
                                                                    content:
                                                                        "Removing objects. This can take a while.",
                                                                    duration: 0,
                                                                });
                                                                try {
                                                                    await this.setState(
                                                                        {
                                                                            backgroundImage:
                                                                                "0",
                                                                        }
                                                                    );
                                                                    var maskData =
                                                                        await this.canvasRef.current.exportImage(
                                                                            "png",
                                                                            {
                                                                                width: this
                                                                                    .state
                                                                                    .uploadedImageWidth,
                                                                                height: this
                                                                                    .state
                                                                                    .uploadedImageHeight,
                                                                            }
                                                                        );
                                                                    await this.setState(
                                                                        {
                                                                            backgroundImage:
                                                                                this
                                                                                    .state
                                                                                    .uploadedImage,
                                                                        }
                                                                    );
                                                                    await this.props.removeObjects(
                                                                        this
                                                                            .state
                                                                            .uploadedImage,
                                                                        maskData
                                                                    );
                                                                    await this.resultRef.current.scrollIntoView(
                                                                        {
                                                                            behavior:
                                                                                "smooth",
                                                                            block: "start",
                                                                        }
                                                                    );
                                                                    await this.props.refetchUser();
                                                                } catch (error) {}
                                                                message.destroy();
                                                                await this.setState(
                                                                    {
                                                                        isGenerating: false,
                                                                    }
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    Remove{" "}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="#000000"
                                                        viewBox="0 0 256 256"
                                                        style={{
                                                            fill: "#d48806",
                                                            backgroundColor:
                                                                "#ffe58f",
                                                            padding:
                                                                "0.125rem 0.25rem",
                                                            borderRadius:
                                                                "0.25rem",
                                                            width: "1rem",
                                                            height: "1rem",
                                                            marginLeft:
                                                                "0.5rem",
                                                        }}
                                                    >
                                                        <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                                    </svg>
                                                </Button>
                                            </PBox>
                                        </PBox>
                                    </PBox>
                                )}
                                {this.props.image &&
                                    this.state.uploadedImage !== "" && (
                                        <PBox
                                            className="binary-mask-result-image"
                                            ref={this.resultRef}
                                        >
                                            <PH2>Result Image</PH2>
                                            <PImg src={this.props.image} />
                                        </PBox>
                                    )}
                            </PBox>

                            {this.props.image &&
                                this.state.uploadedImage !== "" && (
                                    <PBox className="remove-background-download-buttons">
                                        <Button
                                            type="primary"
                                            loading={this.state.isDownloading}
                                            onClick={async () => {
                                                download(
                                                    this.props.image,
                                                    `img.png`,
                                                    `image/png`
                                                );
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </PBox>
                                )}

                            <SellFaq data={object_remover_faq_data} />
                        </PBox>
                        {this.state.isChangePlanModalOpen && (
                            <ChangePlanModal
                                isChangePlanModalOpen={
                                    this.state.isChangePlanModalOpen
                                }
                                handleChangePlanModalOk={async () => {
                                    await this.setState({
                                        isPaymentConfirmModalOpen: true,
                                    });
                                    await this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                handleChangePlanModalCancel={() => {
                                    this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                setNewPlan={(value) => {
                                    this.setState({ newPlan: value });
                                }}
                            />
                        )}

                        {this.state.isPaymentConfirmModalOpen && (
                            <PaymentConfirmModal
                                newPlan={this.state.newPlan}
                                isPaymentConfirmModalOpen={
                                    this.state.isPaymentConfirmModalOpen
                                }
                                handlePaymentConfirmModalOk={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                                handlePaymentConfirmModalCancel={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                            />
                        )}
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    minHeight: "48rem",
    "& h1": {
        textAlign: "center",
        margin: "4rem auto",
    },
    "& .app-content-control": {
        margin: "0 auto",
    },
    "& .remove-background-upload-image": {
        margin: "2rem auto 2rem auto",
        width: "64rem",
        textAlign: "center",

        "& .binary-mask-upload": {
            marginBottom: "4rem",
            "& svg": {
                width: "3rem",
                height: "3rem",
                fill: "$colors$primary",
            },
            "& span": {
                padding: "0",
                width: "100%",
                height: "16rem",
                display: "block",
                margin: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
        },
        "& .background-removed-image": {
            maxHeight: "14rem",
            margin: "auto 1rem",
        },
        "& h2": {
            marginBottom: "3rem",
        },
    },
    "& .remove-background-download-buttons": {
        margin: "5rem auto",
        display: "flex",
        justifyContent: "center",
        "& button": {
            margin: "0 0.5rem",
        },
    },
    "& .remove-background-content": {
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gap: "2rem",
        marginTop: "8rem",
        marginBottom: "4rem",
    },
    "& .binary-mask-sketch-buttons": {
        display: "flex",
        justifyContent: "end",
        //marginTop: "1rem",
    },
    "& .binary-mask-sketch-button": {
        width: "1.5rem",
        height: "1.5rem",
        marginRight: "1rem",
        border: "1px solid $colors$borderColor",
        borderRadius: "1rem",
        padding: "0.5rem",

        "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgba(109, 40, 217, 0.4)",
        },
    },
    "& .binary-mask-button": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    "& .binary-mask-sketch-canva": {
        border: "1px solid #9c9c9c",
    },
    "& .binary-mask-brush-title": {
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "2.5rem",
            height: "2.5rem",
            marginRight: "0.5rem",
        },
    },
    "& .binary-mask-brush-size": {
        textAlign: "left",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "1rem",
        "& h4": { marginLeft: "0.5rem" },
    },
    "& .binary-mask-result-image": {
        margin: "6rem auto",
        "& img": {
            width: "32rem",
            margin: "0 auto",
        },
    },
    "& .binary-mask-demo-images": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        "& img": {
            cursor: "pointer",
            height: "4rem",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        image: state.patternReducer?.removeObjects?.image,
        uuid: state.patternReducer?.removeObjects?.uuid,
    };
}

export default connect(mapStateToProps, {
    refetchUser,
    removeObjects,
    clearStoredObjectsRemovedImage,
})(withRouter(ObjectRemoverApp));
