import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    PH2,
    PLink,
    PText,
    PHighlightButton,
    PBox,
    PImg,
} from "../../theme/BaseTheme";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { Input, Button, Form, Checkbox } from "antd";
import { userSignUp } from "../../../actions/auth";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.normFile = this.normFile.bind(this);
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {}

    normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    onFinish = (values) => {
        console.log("Received values of form: ", values);

        this.props.userSignUp(values, this.props.history);
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        return (
            <AuthenticationLayout>
                <PBox css={styles}>
                    <PBox className="sign-up-title">
                        <PH2>Register an account</PH2>
                        <PText>
                            Sign up today to get <b>20 FREE credits</b>!
                        </PText>
                    </PBox>
                    <PBox className="sign-up">
                        <Form
                            name="basic"
                            labelCol={{
                                span: 0,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: "100%",
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="user_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your username.",
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Username"
                                    prefix={<UserOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message:
                                            "${label} is not a valid email.",
                                    },
                                    {
                                        required: true,
                                        message: "Please enter your email.",
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Email"
                                    prefix={<MailOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your password.",
                                    },
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    placeholder="Password"
                                    prefix={<LockOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirm_password"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please confirm your password.",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue("password") ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "The new password that you entered do not match."
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    placeholder="Confirm Password"
                                    prefix={<LockOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    span: 24,
                                }}
                            >
                                <PHighlightButton
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    className="sign-up-button"
                                >
                                    Create an Account
                                </PHighlightButton>
                            </Form.Item>
                        </Form>
                        <PText>- Or you can join with -</PText>
                        <PBox className="sign-up-button-group">
                            <a href="/api/auths/google">
                                <PBox className="sign-up-oauth-button">
                                    <PImg
                                        src="/images/google-logo.png"
                                        alt="patternful ai"
                                    />
                                    Continue with Google
                                </PBox>
                            </a>
                        </PBox>
                        <PBox className="agree-terms">
                            <PText>
                                By clicking Sign Up or continuing with Google,
                                you acknowledge you have read and agree to our{" "}
                                <a href="/terms" target="_blank">
                                    Terms and Conditions
                                </a>{" "}
                                and{" "}
                                <a href="/privacy" target="_blank">
                                    Privacy Policy
                                </a>
                                .
                            </PText>
                        </PBox>
                    </PBox>
                    <PBox className="sign-in-link">
                        <PText>
                            Already have an account?{" "}
                            <PLink href="/sign-in">Sign In</PLink>
                        </PText>
                    </PBox>
                </PBox>
            </AuthenticationLayout>
        );
    }
}

const styles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .sign-up": {
        width: "440px",
        marginTop: "2rem",
        backgroundColor: "#fff",
        padding: "3rem 2rem 2rem 2rem",
        borderRadius: "0.5rem",
        boxShadow:
            "0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    },

    "& h2": {
        color: "$colors$text",
        textAlign: "center",
        margin: "20px 0",
        fontWeight: "600",
    },
    "& p": {
        color: "$colors$text",
        textAlign: "center",
        lineHeight: "24px",
        margin: "4px 0",
        fontSize: "16px",
    },
    "& a": {
        color: "$colors$link",
        "&:hover": {
            color: "#000",
        },
    },
    "& .sign-up-button": {
        color: "#fff",
        backgroundColor: "$colors$primary700",
        width: "100%",
        borderColor: "$colors$primary700",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "$colors$primary500",
            borderColor: "$colors$primary500",
        },
    },
    "& .sign-up-title": {
        fontSize: "18px",
        marginTop: "2rem",
    },
    "& .sign-up-term-of-use": {
        //marginBottom: "20px",
    },
    "& .sign-up-button-group": {
        margin: "20px auto",
        width: "100%",
        "& a": {
            textDecoration: "none",
        },
    },
    "& .sign-up-oauth-button": {
        textAlign: "center",
        padding: "6px 0",
        borderRadius: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid $colors$borderColor",
        width: "100%",
        textDecoration: "none",
        color: "$colors$text",
        marginBottom: "10px",
        fontSize: "14px",
        "& img": {
            width: "24px",
            height: "24px",
            marginRight: "10px",
        },
        "& svg": {
            fill: "#e1665d",
        },
        "&:hover": {
            backgroundColor: "$colors$primary50",
            borderColor: "$colors$primary50",
            "& svg": {
                fill: "#fff",
            },
        },
    },
    "& .agree-terms": {
        fontSize: "13px",
        "& p": { lineHeight: "1.6" },
        "& a": { textDecoration: "none" },
    },
    "& .sign-in-link": {
        marginTop: "2.5rem",
        fontSize: "16px",
        "& a": {
            color: "$colors$primary700",
            textDecoration: "none",
            fontWeight: "600",
            "&:hover": {
                color: "$colors$primary500",
            },
        },
    },
};

export default connect(null, { userSignUp })(withRouter(SignUp));
