import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PH1, PH2, PH4 } from "../../theme/BaseTheme";
import { Input, Pagination } from "antd";
import PatternCard from "../../components/app/PatternCard";
import { fetchUserPatterns } from "../../../actions/pattern";

class Patterns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            searchKey: "",
            page: parseInt(this.props.match.params.id),
            pageSize: 20,
            urlParams: new URLSearchParams(window.location.search),
            resultKey: "",
        };
    }
    async componentDidMount() {
        await this.props.fetchUserPatterns(
            this.state.urlParams,
            (this.state.page - 1) * this.state.pageSize,
            this.state.pageSize
        );
        await this.setState({
            searchKey: this.state.urlParams.get("key"),
            resultKey: "user_patterns" + "_" + this.state.urlParams.toString(),
        });
        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>User Patterns</title>
                    <meta
                        name="description"
                        content="Creating beautiful patterns is now easy and quick. Patternful.ai's AI-powered seamless pattern generator make it easy for you to produce distinctive, attractive, and ready-to-use pattern images."
                    />
                    <meta
                        name="keywords"
                        content="Seamless Pattern Generator, Seamless Pattern AI, Seamless Pattern Maker, Seamless Pattern APP, Seamless Pattern Online Generator, Print On Demand, Pattern Design, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PH2>My Patterns</PH2>
                    <PBox className="patterns-search">
                        <Input.Search
                            placeholder="Search for patterns"
                            onSearch={(value) => {
                                window.location.href = `/patterns/1?key=${value}`;
                            }}
                        />
                    </PBox>
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchPatterns !== 0 && (
                        <PH4>Search results for {this.state.searchKey}</PH4>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchPatterns === 0 && (
                        <PH4>No Results Found.</PH4>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchPatterns !== 0 && (
                        <PBox className="patterns-container">
                            {this.props.searchResults[
                                this.state.resultKey
                            ]?.searchPatterns?.map((pattern, idx) => {
                                return (
                                    <PatternCard
                                        pattern_uuid={pattern.pattern_uuid}
                                        image_url={pattern.src}
                                        prompt={pattern.prompt}
                                    />
                                );
                            })}
                        </PBox>
                    )}
                    {this.props.searchResults[this.state.resultKey]
                        ?.totalSearchPatterns !== 0 && (
                        <PBox className="pattern-gallery-pagination">
                            <Pagination
                                defaultCurrent={this.state.page}
                                total={parseInt(
                                    this.props.searchResults[
                                        this.state.resultKey
                                    ]?.totalSearchPatterns
                                )}
                                pageSize={this.state.pageSize}
                                showSizeChanger={false}
                                showQuickJumper={true}
                                onChange={async (page) => {
                                    window.location.href = `/patterns/${page}?${this.state.urlParams.toString()}`;
                                }}
                            />
                        </PBox>
                    )}
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    padding: "0 1.5rem",
    maxWidth: "80rem",
    margin: "2rem auto",
    "@bp2": { marginTop: "4rem" },
    "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    "& h2": { marginBottom: "3rem" },
    "& .patterns-search": {
        marginBottom: "3rem",
    },
    "& .patterns-container": {
        margin: "0 auto 6rem auto",
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "@bp2": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
    "& .ant-card-meta-description": { color: "#1b1b1b" },
    "& .pattern-gallery-pagination": {
        display: "flex",
        justifyContent: "center",
        marginBottom: "6rem",
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth.userAccount,
        searchResults: state.patternReducer?.fetchUserPatterns,
    };
}

export default connect(mapStateToProps, { fetchUserPatterns })(
    withRouter(Patterns)
);
