import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropdown, Tag, Image, Card, message } from "antd";
import {
    CopyOutlined,
    EllipsisOutlined,
    DownloadOutlined,
    SettingOutlined,
    PinterestOutlined,
    ShareAltOutlined,
} from "@ant-design/icons";
import { PinterestShareButton } from "react-share";
import { PBox, PText } from "../../theme/BaseTheme";
import DownloadModal from "./DownloadModal";
import SVGModal from "./SVGModal";
import UpscaleModal from "./UpscaleModal";
import { generateVariations } from "../../../actions/pattern";
import { refetchUser } from "../../../actions/auth";
import SettingsModal from "./SettingsModal";
import ChangePlanModal from "./ChangePlanModal";
import PaymentConfirmModal from "./PaymentConfirmModal";

export const PatternCard = (props) => {
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [isSVGModalOpen, setIsSVGModalOpen] = useState(false);
    const [isUpscaleModalOpen, setIsUpscaleModalOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [isChangePlanModalOpen, setIsChangePlanModalOpen] = useState(false);
    const [isPaymentConfirmModalOpen, setIsPaymentConfirmModalOpen] =
        useState(false);
    const [newPlan, setNewPlan] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);

    const showDownloadModal = () => {
        setIsDownloadModalOpen(true);
    };
    const handleDownloadModalOk = () => {
        setIsDownloadModalOpen(false);
    };
    const handleDownloadModalCancel = () => {
        setIsDownloadModalOpen(false);
    };

    const showSVGModal = () => {
        setIsSVGModalOpen(true);
    };
    const handleSVGModalOk = () => {
        setIsSVGModalOpen(false);
    };
    const handleSVGModalCancel = () => {
        setIsSVGModalOpen(false);
    };

    const showUpscaleModal = () => {
        setIsUpscaleModalOpen(true);
    };
    const handleUpscaleModalOk = async () => {
        await props.refetchUser();
        await setIsUpscaleModalOpen(false);
    };
    const handleUpscaleModalCancel = () => {
        setIsUpscaleModalOpen(false);
    };

    const showSettingsModal = () => {
        setIsSettingsModalOpen(true);
    };
    const handleSettingsModalOk = () => {
        setIsSettingsModalOpen(false);
    };
    const handleSettingsModalCancel = () => {
        setIsSettingsModalOpen(false);
    };

    const showChangePlanModal = () => {
        setIsChangePlanModalOpen(true);
    };

    const handleChangePlanModalOk = () => {
        setIsChangePlanModalOpen(false);
    };

    const handleChangePlanModalCancel = () => {
        setIsChangePlanModalOpen(false);
    };

    const showPaymentConfirmModal = () => {
        setIsPaymentConfirmModalOpen(true);
    };
    const handlePaymentConfirmModalOk = () => {
        setIsPaymentConfirmModalOpen(false);
    };
    const handlePaymentConfirmModalCancel = () => {
        setIsPaymentConfirmModalOpen(false);
    };

    return (
        <PBox css={styles}>
            <PBox className="pattern-card">
                {/*<Image className="pattern-card-img" src={props.image_url} />*/}
                <Card
                    loading={isProcessing}
                    hoverable={true}
                    cover={
                        <Image
                            src={props.image_url}
                            crossOrigin={
                                process.env.NODE_ENV === "development"
                                    ? "anonymous"
                                    : ""
                            }
                        />
                    }
                    actions={[
                        <DownloadOutlined
                            key="download"
                            onClick={showDownloadModal}
                        />,
                        <CopyOutlined
                            key="copy"
                            onClick={async () => {
                                await navigator.clipboard.writeText(
                                    props.prompt
                                );
                                await message.success(
                                    "Prompt copied to clipboard."
                                );
                            }}
                        />,
                        <PinterestShareButton
                            url={String(window.location.origin)}
                            media={props.image_url}
                            description={props.prompt}
                        >
                            <PinterestOutlined />
                        </PinterestShareButton>,

                        <Dropdown
                            menu={{
                                items: [
                                    /*{
                                        label: (
                                            <PBox onClick={showSettingsModal}>
                                                Post in Product Gallery
                                            </PBox>
                                        ),
                                        key: "product",
                                    },*/
                                    {
                                        label: (
                                            <PBox
                                                onClick={async () => {
                                                    if (
                                                        props.userAccount
                                                            ?.credits > 0
                                                    ) {
                                                        await showUpscaleModal();
                                                    } else {
                                                        await showChangePlanModal();
                                                    }
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                Upscale Image{" "}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="#000000"
                                                    viewBox="0 0 256 256"
                                                    style={{
                                                        fill: "#d48806",
                                                        backgroundColor:
                                                            "#ffe58f",
                                                        padding:
                                                            "0.125rem 0.25rem",
                                                        borderRadius: "0.25rem",
                                                        width: "1rem",
                                                        height: "1rem",
                                                        marginLeft: "0.25rem",
                                                    }}
                                                >
                                                    <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                                </svg>
                                            </PBox>
                                        ),
                                        key: "upscale",
                                    },
                                    {
                                        label: (
                                            <PBox onClick={showSVGModal}>
                                                Convert to SVG
                                            </PBox>
                                        ),
                                        key: "svg",
                                    },
                                    {
                                        label: (
                                            <PBox
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                                onClick={async () => {
                                                    if (
                                                        props.userAccount
                                                            ?.credits > 0
                                                    ) {
                                                        await setIsProcessing(
                                                            true
                                                        );
                                                        message.open({
                                                            type: "loading",
                                                            content:
                                                                "Start generating similar patterns. This can take a while.",
                                                            duration: 0,
                                                        });
                                                        await props.generateVariations(
                                                            props.pattern_uuid
                                                        );
                                                        message.destroy();
                                                        await props.refetchUser();
                                                        await setIsProcessing(
                                                            false
                                                        );
                                                    } else {
                                                        await showChangePlanModal();
                                                    }
                                                }}
                                            >
                                                Generate Similar Images{" "}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="#000000"
                                                    viewBox="0 0 256 256"
                                                    style={{
                                                        fill: "#d48806",
                                                        backgroundColor:
                                                            "#ffe58f",
                                                        padding:
                                                            "0.125rem 0.25rem",
                                                        borderRadius: "0.25rem",
                                                        width: "1rem",
                                                        height: "1rem",
                                                        marginLeft: "0.25rem",
                                                    }}
                                                >
                                                    <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                                </svg>
                                            </PBox>
                                        ),
                                        key: "variation",
                                    },
                                    {
                                        label: (
                                            <PBox
                                                onClick={() => {
                                                    window.location.href = `/seamless-pattern/design/${props.pattern_uuid}`;
                                                }}
                                            >
                                                Edit and Mockup
                                            </PBox>
                                        ),
                                        key: "mockup",
                                    },
                                ],
                            }}
                            trigger={["click"]}
                        >
                            <EllipsisOutlined key="ellipsis" />
                        </Dropdown>,
                    ]}
                >
                    <Card.Meta description={`Prompt: ${props.prompt}`} />
                </Card>
                {isDownloadModalOpen && (
                    <DownloadModal
                        patternUUID={props.pattern_uuid}
                        isDownloadModalOpen={isDownloadModalOpen}
                        handleDownloadModalOk={handleDownloadModalOk}
                        handleDownloadModalCancel={handleDownloadModalCancel}
                    />
                )}
                {isSVGModalOpen && (
                    <SVGModal
                        patternUUID={props.pattern_uuid}
                        isSVGModalOpen={isSVGModalOpen}
                        handleSVGModalOk={handleSVGModalOk}
                        handleSVGModalCancel={handleSVGModalCancel}
                    />
                )}
                {isUpscaleModalOpen && (
                    <UpscaleModal
                        patternUUID={props.pattern_uuid}
                        isUpscaleModalOpen={isUpscaleModalOpen}
                        handleUpscaleModalOk={handleUpscaleModalOk}
                        handleUpscaleModalCancel={handleUpscaleModalCancel}
                    />
                )}
                {isSettingsModalOpen && (
                    <SettingsModal
                        patternUUID={props.pattern_uuid}
                        isSettingsModalOpen={isSettingsModalOpen}
                        handleSettingsModalOk={handleSettingsModalOk}
                        handleSettingsModalCancel={handleSettingsModalCancel}
                    />
                )}
                {isChangePlanModalOpen && (
                    <ChangePlanModal
                        isChangePlanModalOpen={isChangePlanModalOpen}
                        handleChangePlanModalOk={() => {
                            showPaymentConfirmModal();
                            handleChangePlanModalOk();
                        }}
                        handleChangePlanModalCancel={
                            handleChangePlanModalCancel
                        }
                        showPaymentConfirmModal={showPaymentConfirmModal}
                        setNewPlan={setNewPlan}
                    />
                )}
                {isPaymentConfirmModalOpen && (
                    <PaymentConfirmModal
                        newPlan={newPlan}
                        isPaymentConfirmModalOpen={isPaymentConfirmModalOpen}
                        handlePaymentConfirmModalOk={
                            handlePaymentConfirmModalOk
                        }
                        handlePaymentConfirmModalCancel={
                            handlePaymentConfirmModalCancel
                        }
                    />
                )}
            </PBox>
        </PBox>
    );
};

const styles = {
    "& .pattern-card": {
        position: "relative",
    },
    "& .ant-card-meta-description": { color: "#1b1b1b" },
    "& .ant-image-img": { padding: "0.5rem" },
    "& .ant-image-mask": { margin: "0.5rem" },
};

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth?.userAccount };
}

export default connect(mapStateToProps, { generateVariations, refetchUser })(
    withRouter(PatternCard)
);
