import { combineReducers } from "redux";

const fetchSubscription = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_SUBSCRIPTION_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                subscription: action.payload,
            };
        default:
            return state;
    }
};

const previewProration = (state = {}, action) => {
    switch (action.type) {
        case "PREVIEW_PRORATION_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                proration: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({
    fetchSubscription,
    previewProration,
});
