import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PatternDiscover } from "../../sections/pattern/PatternDiscover";
import { PatternHow } from "../../sections/pattern/PatternHow";
import { PatternWhy } from "../../sections/pattern/PatternWhy";
import { PatternFor } from "../../sections/pattern/PatternFor";
import { PatternWhyMore } from "../../sections/pattern/PatternWhyMore";
import { PBox } from "../../theme/BaseTheme";
import { SellFaq } from "../../sections/SellFaq";
import { PatternBanner } from "../../sections/pattern/PatternBanner";
import { CallToAction } from "../../sections/CallToAction";

const sp_faq_data = [
    {
        question: "What is a pattern generator?",
        answer: "A pattern generator is an online tool that you can use to make your own patterns. You don’t need any graphics software or design skills to do this, because you can create these repeating patterns based on text prompts.",
    },
    {
        question: "What are seamless patterns?",
        answer: "When a pattern is seamless the image can be repeated as many times as you want, without having any visual edges or seams. The pattern at top of the image matches up perfectly with that of the bottom, as do the left and right side of the image. These types of patterns are often used as prints on home decor, textiles and stationery and can add interest to any project or design.",
    },
    {
        question: "Can I use patterns for personal and commercial use?",
        answer: "Use the patterns you have created with the pattern maker however you like! They are great both for personal and commercial use. Print your patterns out at home for any of your paper crafts or use them on your print on demand designs.",
    },
    {
        question: "What can repetitive patterns be used for?",
        answer: "The options are endless! Seamless patterns can be used as a digital wallpaper for your phone, computer or website. They also make for great gifts when printed on notebooks, phone cases or tumblers. Another great way to use your patterns is to get them printed on textiles for home decor or clothing. Lastly we recommend using them in any of your crafting projects!",
    },
];

class PatternLanding extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Seamless Pattern Generator</title>
                    <meta
                        name="description"
                        content="Creating beautiful patterns is now easy and quick. Patternful.ai's AI-powered seamless pattern generator make it easy for you to produce distinctive, attractive, and ready-to-use pattern images."
                    />
                    <meta
                        name="keywords"
                        content="Seamless Pattern Generator, Seamless Pattern AI, Seamless Pattern Maker, Seamless Pattern APP, Seamless Pattern Online Generator, Print On Demand, Pattern Design, Patternful, Patternful AI"
                    />
                </Helmet>
                <PatternBanner user_uuid={this.props.userAccount?.uuid} />
                <PatternFor />
                <PatternHow />
                <PatternWhy />
                <PatternWhyMore />
                <CallToAction
                    title={
                        "Start Generating Your Stunning Patterns for Free Today"
                    }
                    subtitle={
                        "Ready to elevate your projects and business with Patternful.ai? Sign up and start generating your unique patterns."
                    }
                    link={"/seamless-pattern/app"}
                    cta={"Get Started"}
                />
                <SellFaq data={sp_faq_data} />
                <PatternDiscover />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return { userAccount: state.authReducer?.userAuth.userAccount };
}

export default connect(mapStateToProps, {})(withRouter(PatternLanding));
