import {
    PBox,
    PH1,
    PH2,
    PImg,
    PLi,
    POl,
    PText,
    PUl,
} from "../../../theme/BaseTheme";
import { BlogPostLayout } from "../BlogPostLayout";

export const EarnPassiveIncomeOnSociety6 = () => {
    return (
        <BlogPostLayout category="Tutorials">
            <PH1>Earning Passive Income on Society6 with Patternful.ai</PH1>
            <PBox className="blog-hero">
                <PText>Patternful Team | Mar 22, 2024 | 3 min read</PText>
            </PBox>

            <PBox className="blog-banner-img">
                <PImg
                    src={
                        "https://miro.medium.com/v2/resize:fit:1400/format:webp/0*y89zyjZxjk1eW849"
                    }
                />
            </PBox>
            <PH2>Print on Demand with Society6</PH2>
            <PText>
                Print on demand (POD) is a retail method where items are printed
                individually once an order is placed, removing the need for
                stockpiling inventory.
            </PText>
            <PText>
                Society6 operates within this POD framework, enabling sellers to
                offer their art on various products. Sellers upload their
                designs to Society6, choose products to adorn with their art,
                and upon sale, Society6 manages production, shipping, and
                customer support. Sellers receive a commission for each item
                sold, with the amount varying by product.
            </PText>
            <PText>
                Society6's large user base provides sellers with exposure to a
                worldwide audience, increasing the chances of sales. Sellers can
                upload their work and select products for sale without any
                upfront investment. Once artwork is uploaded and products are
                selected, sellers can earn money without additional effort,
                providing a passive income stream.
            </PText>
            <PH2>Is Selling AI-Generated Art on Society6 Allowed?</PH2>
            <PText>
                Society6 permits the sale of AI-generated artwork, provided it
                doesn't infringe on copyright or trademark laws and the artist
                owns the rights to the content.
            </PText>
            <PH2>Patternful.ai and Resale Policy</PH2>
            <PText>
                Patternful.ai is an AI-enhanced tool for creating distinctive,
                eye-catching, and inventive patterns. Patternful.ai not only
                generates beautiful seamless patterns, but also provides a
                variety of features to help you generate ready-to-use images
                from the patterns, for your branding, merchandise, or personal
                projects.
            </PText>
            <PText>
                According to Patternful.ai’s Resale Policy, you are allowed to
                sell any patterns and images you’ve personally created on
                Patternful.ai.
            </PText>
            <PH2>How to sell Patternful.ai Designs on Society6</PH2>
            <PText>
                <b>Step 1:</b> Craft a unique pattern using Patternful.ai.
            </PText>
            <PText>
                Just type in a prompt and click the 'Generate' button. Next,
                select your preferred pattern and proceed to 'Edit and
                Download'.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*MRsRBEA3DXBv2MANM8Do0Q.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                <b>Step 2:</b> Download your pattern, adhering to Society6's
                image requirements. Find Society6's Pixel Dimension Requirements
                and Templates Here.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*fZoAcF0KCAuOO1DJ4OfoZQ.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                Customize the image as you like, select the desired dimension,
                resolution, DPI, and format for download, then click 'download'.
                For example, I selected Throw Pillow, default resolution, 300
                DPI, and PNG format. Here is the resulting image.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*MWdhwRg7L8tVmA5BnJc01g.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                <b>Step 3:</b> Upload your design to Society6's Artist Studio.
                Enjoy!
            </PText>
            <PText>
                Navigate to Society6's Art Studio and click on 'Add New Work'.
                Upload the image you downloaded from Patternful.ai.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*LInDA3-YXywnJzZwjhRaEg.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                In the next page, you will see your pattern on some products.
                Some products are grey because the images size is not big
                enough. In this case, the Throw Pillow option is enabled.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:904/format:webp/1*VsoSpaQeeAUQFVBbxrTpiQ.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                Hit edit to adjust how you would like the pattern to show on the
                product.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*ASJC4pmLHcbEj46uBXOnGw.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>
                Hit 'Save & Enable', then the Product Throw Pillow is enabled
                for this design. After enabling all the products, click on
                'Publish Artwork' at the end of the left column.
            </PText>
            <PImg
                src={
                    "https://miro.medium.com/v2/resize:fit:1340/format:webp/1*mSJU3gaTtpKmtnmAGcalpg.png"
                }
                style={{
                    width: "400px",
                    display: "block",
                    margin: "0 auto",
                }}
            />
            <PText>That's it! It is just this simple. Enjoy!</PText>
        </BlogPostLayout>
    );
};
