import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, message, Slider, Upload } from "antd";
import download from "downloadjs";
import { Helmet } from "react-helmet";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PH4, PImg, PText, PH2, PH1 } from "../../theme/BaseTheme";
import { refetchUser } from "../../../actions/auth";
import ChangePlanModal from "../../components/app/ChangePlanModal";
import PaymentConfirmModal from "../../components/app/PaymentConfirmModal";
import { SellFaq } from "../../sections/SellFaq";
import {
    removeObjects,
    clearStoredObjectsRemovedImage,
} from "../../../actions/pattern";
import { object_remover_faq_data } from "../landing/ObjectRemoverLanding";
import RemoveObjectEditor from "../../components/app/RemoveObjectEditor";
import { resizeImageFile } from "../../components/app/utils";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

class ObjectRemoverWrapper extends Component {
    canvasRef = React.createRef();

    constructor(props) {
        super(props);
        this.highlightRef = React.createRef();
        this.resultRef = React.createRef();
        this.state = {
            isLoading: true,
            brushSize: 30,
            isGenerating: false,
            isDownloading: false,
            eraseMode: false,
            uploadedImage: "",
            uploadedImageWidth: 0,
            uploadedImageHeight: 0,
            backgroundImage: "",
            fileList: [],
            uploadedImageFile: "",
            isChangePlanModalOpen: false,
            isPaymentConfirmModalOpen: false,
            newPlan: "",
        };
        this.handleUpload = this.handleUpload.bind(this);
        this.handleUploadDemo = this.handleUploadDemo.bind(this);
        this.handleUndoClick = this.handleUndoClick.bind(this);
        this.handleRedoClick = this.handleRedoClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
    }
    handleUndoClick = () => {
        this.canvasRef.current?.undo();
    };

    handleRedoClick = () => {
        this.canvasRef.current?.redo();
    };
    handleResetClick = () => {
        this.canvasRef.current?.resetCanvas();
    };
    handleClearClick = () => {
        this.canvasRef.current?.clearCanvas();
    };

    handleUpload = async ({ file }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        var resizedFileObject = await resizeImageFile(
            file.originFileObj,
            1024 * 4
        );
        await this.setState({
            uploadedImage: file.url || file.preview,
            backgroundImage: file.url || file.preview,
            uploadedImageFile: resizedFileObject.file,
        });
        await this.props.clearStoredObjectsRemovedImage();
        await this.handleClearClick();
    };

    handleUploadDemo = async (demoURL, demoName, demoType) => {
        let blob = await fetch(demoURL)
            .then((r) => r.blob())
            .then(
                (blobFile) =>
                    new File([blobFile], demoName, {
                        type: demoType,
                    })
            );
        let filePreview = await getBase64(blob);
        var resizedFileObject = await resizeImageFile(blob, 1024 * 4);
        await this.setState({
            uploadedImage: filePreview,
            backgroundImage: filePreview,
            uploadedImageFile: resizedFileObject.file,
        });
        await this.props.clearStoredObjectsRemovedImage();
        await this.handleClearClick();
    };

    async componentDidMount() {
        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Object Remover App</title>
                    <meta
                        name="description"
                        content="Segment and generate binary masks. AI-powered segmentation and generation of binary object masks, for your online business, marketing, or just for fun!"
                    />
                    <meta
                        name="keywords"
                        content="Binary Mask Generator, Segmentation Mask Generator, Binary Mask Generator APP, Binary Mask Generator Online, Image Segmentation, AI Image Editing, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="app-content">
                        <PH1>AI Object Remover</PH1>
                        <PBox className="app-content-control">
                            <PBox className="remove-background-upload-image">
                                <PBox className="binary-mask-upload">
                                    <Upload.Dragger
                                        onChange={async (info) => {
                                            await this.handleUpload(info);
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                        showUploadList={false}
                                        listType="picture-card"
                                        maxCount={1}
                                        beforeUpload={async (file) => {
                                            const reader = new FileReader();
                                            await reader.readAsDataURL(file);
                                            await reader.addEventListener(
                                                "load",
                                                async (event) => {
                                                    const _loadedImageUrl =
                                                        event.target.result;
                                                    const image =
                                                        document.createElement(
                                                            "img"
                                                        );
                                                    image.src = _loadedImageUrl;
                                                    await image.addEventListener(
                                                        "load",
                                                        async () => {
                                                            const {
                                                                width,
                                                                height,
                                                            } = image;
                                                            // set image width and height to your state here
                                                            await this.setState(
                                                                {
                                                                    uploadedImageWidth:
                                                                        width,
                                                                    uploadedImageHeight:
                                                                        height,
                                                                }
                                                            );
                                                            await console.log(
                                                                "width: ",
                                                                width,
                                                                " height: ",
                                                                height
                                                            );
                                                        }
                                                    );
                                                }
                                            );
                                            const isJpgOrPng =
                                                file.type === "image/jpeg" ||
                                                file.type === "image/png";
                                            if (!isJpgOrPng) {
                                                message.error(
                                                    "You can only upload JPG/PNG file."
                                                );
                                            }
                                            const isLt8M =
                                                file.size / 1024 / 1024 < 8;
                                            if (!isLt8M) {
                                                message.error(
                                                    "Image must smaller than 8MB."
                                                );
                                            }
                                            return (
                                                (isJpgOrPng && isLt8M) ||
                                                Upload.LIST_IGNORE
                                            );
                                        }}
                                    >
                                        {this.state.uploadedImage !== "" && (
                                            <PImg
                                                className="background-removed-image"
                                                src={this.state.uploadedImage}
                                            />
                                        )}
                                        {this.state.uploadedImage === "" && (
                                            <PText>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="#000000"
                                                    viewBox="0 0 256 256"
                                                >
                                                    <path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0ZM93.66,85.66,120,59.31V152a8,8,0,0,0,16,0V59.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,85.66Z"></path>
                                                </svg>
                                                <br />
                                                Click to upload your image,
                                                <br />
                                                or drag and drop your image into
                                                this area.
                                            </PText>
                                        )}
                                    </Upload.Dragger>
                                </PBox>
                                <PBox className="binary-mask-demo-images">
                                    <PH4>Try demo images:</PH4>
                                    <PImg
                                        src={
                                            "/images/object-remover/demo-1.jpg"
                                        }
                                        onClick={async () => {
                                            await this.handleUploadDemo(
                                                "/images/object-remover/demo-1.jpg",
                                                "object-remover-demo-1.jpg",
                                                "image/jpeg"
                                            );
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                    />
                                    <PImg
                                        src={
                                            "/images/object-remover/demo-2.jpg"
                                        }
                                        onClick={async () => {
                                            await this.handleUploadDemo(
                                                "/images/object-remover/demo-2.jpg",
                                                "object-remover-demo-2.jpg",
                                                "image/jpeg"
                                            );
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                    />
                                    <PImg
                                        src={
                                            "/images/object-remover/demo-3.jpg"
                                        }
                                        onClick={async () => {
                                            await this.handleUploadDemo(
                                                "/images/object-remover/demo-3.jpg",
                                                "object-remover-demo-3.jpg",
                                                "image/jpeg"
                                            );
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                    />
                                    <PImg
                                        src={
                                            "/images/object-remover/demo-4.jpg"
                                        }
                                        onClick={async () => {
                                            await this.handleUploadDemo(
                                                "/images/object-remover/demo-4.jpg",
                                                "object-remover-demo-4.jpg",
                                                "image/jpeg"
                                            );
                                            await this.highlightRef.current.scrollIntoView(
                                                {
                                                    behavior: "smooth",
                                                    block: "start",
                                                }
                                            );
                                        }}
                                    />
                                </PBox>
                                {this.state.backgroundImage !== "" && (
                                    <PBox ref={this.highlightRef}>
                                        <RemoveObjectEditor
                                            file={this.state.uploadedImageFile}
                                        />
                                    </PBox>
                                )}
                                {this.props.image &&
                                    this.state.uploadedImage !== "" && (
                                        <PBox
                                            className="binary-mask-result-image"
                                            ref={this.resultRef}
                                        >
                                            <PH2>Result Image</PH2>
                                            <PImg src={this.props.image} />
                                        </PBox>
                                    )}
                            </PBox>

                            {this.props.image &&
                                this.state.uploadedImage !== "" && (
                                    <PBox className="remove-background-download-buttons">
                                        <Button
                                            type="primary"
                                            loading={this.state.isDownloading}
                                            onClick={async () => {
                                                download(
                                                    this.props.image,
                                                    `img.png`,
                                                    `image/png`
                                                );
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </PBox>
                                )}

                            <SellFaq data={object_remover_faq_data} />
                        </PBox>
                        {this.state.isChangePlanModalOpen && (
                            <ChangePlanModal
                                isChangePlanModalOpen={
                                    this.state.isChangePlanModalOpen
                                }
                                handleChangePlanModalOk={async () => {
                                    await this.setState({
                                        isPaymentConfirmModalOpen: true,
                                    });
                                    await this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                handleChangePlanModalCancel={() => {
                                    this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                setNewPlan={(value) => {
                                    this.setState({ newPlan: value });
                                }}
                            />
                        )}

                        {this.state.isPaymentConfirmModalOpen && (
                            <PaymentConfirmModal
                                newPlan={this.state.newPlan}
                                isPaymentConfirmModalOpen={
                                    this.state.isPaymentConfirmModalOpen
                                }
                                handlePaymentConfirmModalOk={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                                handlePaymentConfirmModalCancel={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                            />
                        )}
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    minHeight: "48rem",
    "& h1": {
        textAlign: "center",
        margin: "4rem auto",
    },
    "& .app-content-control": {
        margin: "0 auto",
    },
    "& .remove-background-upload-image": {
        margin: "2rem auto 2rem auto",
        width: "64rem",
        textAlign: "center",

        "& .binary-mask-upload": {
            marginBottom: "4rem",
            "& svg": {
                width: "3rem",
                height: "3rem",
                fill: "$colors$primary",
            },
            "& span": {
                padding: "0",
                width: "100%",
                height: "16rem",
                display: "block",
                margin: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
        },
        "& .background-removed-image": {
            maxHeight: "14rem",
            margin: "auto 1rem",
        },
        "& h2": {
            marginBottom: "3rem",
        },
    },
    "& .remove-background-download-buttons": {
        margin: "5rem auto",
        display: "flex",
        justifyContent: "center",
        "& button": {
            margin: "0 0.5rem",
        },
    },
    "& .remove-background-content": {
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gap: "2rem",
        marginTop: "8rem",
        marginBottom: "4rem",
    },
    "& .binary-mask-sketch-buttons": {
        display: "flex",
        justifyContent: "end",
        //marginTop: "1rem",
    },
    "& .binary-mask-sketch-button": {
        width: "1.5rem",
        height: "1.5rem",
        marginRight: "1rem",
        border: "1px solid $colors$borderColor",
        borderRadius: "1rem",
        padding: "0.5rem",

        "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgba(109, 40, 217, 0.4)",
        },
    },
    "& .binary-mask-button": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    "& .binary-mask-sketch-canva": {
        border: "1px solid #9c9c9c",
    },
    "& .binary-mask-brush-title": {
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "2.5rem",
            height: "2.5rem",
            marginRight: "0.5rem",
        },
    },
    "& .binary-mask-brush-size": {
        textAlign: "left",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "1rem",
        "& h4": { marginLeft: "0.5rem" },
    },
    "& .binary-mask-result-image": {
        margin: "6rem auto",
        "& img": {
            width: "32rem",
            margin: "0 auto",
        },
    },
    "& .binary-mask-demo-images": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        "& img": {
            cursor: "pointer",
            height: "4rem",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        image: state.patternReducer?.removeObjects?.image,
        uuid: state.patternReducer?.removeObjects?.uuid,
    };
}

export default connect(mapStateToProps, {
    refetchUser,
    removeObjects,
    clearStoredObjectsRemovedImage,
})(withRouter(ObjectRemoverWrapper));
